import React, {
  KeyboardEvent,
  ChangeEvent,
  useState,
  useRef,
  useEffect,
} from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES, FONTS } from '~/constants/typography';
import { stopEventEffects, getCaretPositions } from '~/utils';
import Gap from '~/components/Gap';

import { Word, Sentence } from '../../../models';
import { cleanCarets } from '../../../utils';
import { MARKS } from '../../../constants/language';
import { useContext } from '../providers/Content';
import Chunk from './Chunk';
import Caret from './Caret';
import Notice from './Notice';
import Autocomplete from './Autocomplete';
import Grouper from './Grouper';
import Mute from './Mute';
import Remove from './Remove';

interface LineProps {
  index: number;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  onDelete: (event: Event) => void;
}

function Line({ index, value, onChange, onKeyDown, onDelete }: LineProps) {
  const [wrongWord, setWrongWord] = useState<Word>();
  const [chunk, setChunk] = useState<string>();
  const { state } = useContext();
  const { sentences, disabled } = state;
  const fakeWord = { value: '' };
  const sentence = sentences[index];
  const isEmpty = value.length === 0 || value === MARKS.caret;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const displayRef = useRef<HTMLDivElement>(null);
  const getCaretKey = (idx) => `BeatbloxWriteScriptLineCaret${idx}`;
  const getChunkKey = (idx) => `BeatbloxWriteScriptLineChunk${idx}`;

  // Display
  const selectionStart = value.indexOf(MARKS.caret);
  const selectionEnd = value.indexOf(MARKS.caret, selectionStart + 1);
  const letters = sentence?.reduce(
    (acc, w) => [...acc, ...w.value.split('').map((l) => ({ ...w, chunk: l }))],
    [] as Sentence
  );

  // Styling
  const wrapperStyleReset = {
    display: 'inline-block',
    position: 'relative' as const,
    padding: '0.5em',
    borderWidth: 'thin',
    borderStyle: 'solid',
    borderRadius: '0.4em',
    borderColor: COLORS.platinum,
    fontSize: FONT_SIZES.text,
    fontFamily: FONTS.typewriter,
    verticalAlign: 'middle',
    cursor: disabled ? 'default' : 'text',
  };
  const inputStyle = {
    position: 'absolute' as const,
    opacity: 0,
    zIndex: -1,
  };
  const color = COLORS.black;
  const width = '25em';
  const displayStyle = {
    width,
    outline: 'none',
    textAlign: 'left' as const,
    wordWrap: 'break-word' as const,
    caretColor: color,
  };
  const emptyStyleReset = {
    position: 'absolute' as const,
    top: 0,
    left: 0,
    padding: '0.5em',
    color: COLORS.silver,
    zIndex: -1,
  };

  // Rendering
  const [wrapperStyle, setWrapperStyle] = useState(wrapperStyleReset);
  const [emptyStyle, setEmptyStyle] = useState(emptyStyleReset);
  const placeholder = disabled ? 'Please wait...' : 'Enter a pattern';

  // Handlers
  const isCaret = (l) => l.chunk === MARKS.caret;
  const getHandleChunk = (start?) => () => {
    const sIdx = start ?? letters?.findIndex(isCaret);
    const eIdx = letters?.slice(sIdx + 1).findIndex(isCaret);

    if (sIdx && sIdx > 0 && eIdx === -1) setChunk(letters?.[sIdx - 1].value);
    else if (sIdx === 0 || eIdx !== -1) setChunk(undefined);
  };
  const handleFocus = (event) => {
    stopEventEffects(event);
    setWrapperStyle({ ...wrapperStyleReset, borderColor: COLORS.doveGray });
    setEmptyStyle({ ...emptyStyleReset, color: COLORS.gallery });
  };
  const handlePoke = (event) => {
    const { start, end, direction } = getCaretPositions(displayRef.current);
    const smartStart = event.detail === 2 ? 0 : start;

    stopEventEffects(event);
    if (start !== end || end === value.length) setWrongWord(undefined);
    inputRef.current?.focus();
    if (start > -1 && end > -1) {
      inputRef.current?.setSelectionRange(smartStart, end, direction);
      if (start === end) getHandleChunk(start)();
      else if (chunk) setChunk(undefined);
    }
  };
  const handleBlur = (event?) => {
    if (!event || !wrapperRef.current?.contains(event.relatedTarget)) {
      setWrapperStyle(wrapperStyleReset);
      setEmptyStyle(emptyStyleReset);
      if (chunk) setChunk(undefined);
    }
  };
  const handleKeyDown = (event) => {
    if (!event.repeat) setWrongWord(undefined);
    onKeyDown(event);
  };

  useEffect(() => {
    if (disabled) handleBlur();
    else setWrapperStyle(wrapperStyleReset);
  }, [disabled]);

  return (
    <div>
      <Grouper index={index} />
      <Mute index={index} />
      <div
        role="button"
        tabIndex={index}
        onFocus={disabled ? undefined : handleFocus}
        onMouseUp={disabled ? undefined : handlePoke}
        onTouchEnd={disabled ? undefined : handlePoke}
        style={wrapperStyle}
        ref={wrapperRef}
      >
        <Notice wrongWord={wrongWord} setWrongWord={setWrongWord} />
        <Autocomplete chunk={chunk} setChunk={setChunk} width={width} />
        <input
          value={cleanCarets(value)}
          disabled={disabled}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          onKeyUp={getHandleChunk()}
          onBlur={handleBlur}
          style={inputStyle}
          ref={inputRef}
        />
        {isEmpty && <span style={emptyStyle}>{placeholder}</span>}
        <div
          role="textbox"
          tabIndex={-1}
          contentEditable={!disabled}
          suppressContentEditableWarning
          onMouseUp={disabled ? undefined : handlePoke}
          onTouchEnd={disabled ? undefined : handlePoke}
          style={displayStyle}
          ref={displayRef}
        >
          {disabled && (
            <Chunk word={fakeWord} setWrongWord={setWrongWord} color={color} />
          )}
          {letters?.map((l, idx) =>
            l.chunk === MARKS.caret ? (
              <Caret
                key={getCaretKey(idx)}
                shift={!isEmpty}
                color={color}
                highlight={selectionEnd > -1}
              />
            ) : (
              <Chunk
                key={getChunkKey(idx)}
                word={l}
                setWrongWord={setWrongWord}
                color={color}
                highlight={idx >= selectionStart && idx <= selectionEnd}
              />
            )
          )}
        </div>
      </div>
      <Remove onDelete={onDelete} />
      {index < sentences.length - 1 && <Gap />}
    </div>
  );
}

export default Line;
