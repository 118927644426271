import React, { ReactElement, CSSProperties } from 'react';
import { GroupBase, Props } from 'react-select';

import COLORS from '~/constants/colors';
import { FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';
import Select from '~/components/Select';
import { useThemeContext } from '~/providers/ThemeProvider';

interface OptionSelectProps<
  Option = any,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends Props<Option, IsMulti, Group> {
  placeholder?: string;
  disabled?: boolean;
  style?: CSSProperties;
}

function OptionSelect({
  placeholder,
  disabled,
  style,
  ...props
}: OptionSelectProps): ReactElement {
  const { theme } = useThemeContext().state;
  const baseState = disabled
    ? theme.disabled.default
    : {
        color: COLORS.white,
        backgroundColor: COLORS.cornflower,
        borderColor: COLORS.cornflower,
      };
  const optionSelectStyles = {
    control: () => ({
      fontSize: FONT_SIZES.action,
      fontWeight: FONT_WEIGHTS.medium,
      backgroundColor: style?.backgroundColor ?? baseState.backgroundColor,
      borderColor: style?.borderColor ?? baseState.borderColor,
      boxShadow: `-0.13em 0.13em ${COLORS.black25}`,
      '&:hover': undefined,
      ...style,
    }),
    placeholder: () => ({
      color: style?.color ?? baseState.color,
      whiteSpace: 'nowrap' as const,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }),
    singleValue: () => ({
      color: style?.color ?? baseState.color,
    }),
    indicatorSeparator: () => ({
      backgroundColor: style?.color ?? baseState.color,
    }),
    dropdownIndicator: () => ({
      color: style?.color ?? baseState.color,
      ':hover': undefined,
    }),
  };

  return (
    <Select
      width="min(20em, 70vw)"
      placeholder={placeholder}
      disabled={disabled}
      styles={optionSelectStyles}
      {...props}
    />
  );
}

export default OptionSelect;
