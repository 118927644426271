import React, { ReactElement } from 'react';

import Header from '~/components/Header';
import Subheader from '~/components/Subheader';
import Divider from '~/components/Divider';
import Link from '~/components/Link';
import socialUrls from '~/urls/social';
import COLORS from '~/constants/colors';
import Break from '~/components/Break';
import { FONT_WEIGHTS } from '~/constants/typography';

import Feature from './components/Feature';
import communicate from './assets/communicate.png';
import conceptualize from './assets/conceptualize.png';
import compose from './assets/compose.png';

function About(): ReactElement {
  // Styling
  const aboutStyle = {
    display: 'inline-block',
    width: 'min(30em, 82.5vw)',
    color: COLORS.tundora,
  };
  const headerStyle = {
    margin: 0,
    fontWeight: FONT_WEIGHTS.thin,
  };
  const topicStyle = {
    color: COLORS.platinum,
  };
  const wrapperStyle = {
    textAlign: 'center' as const,
  };
  const containerStyle = {
    display: 'inline-block',
    textAlign: 'left' as const,
  };

  return (
    <div style={aboutStyle}>
      <Header style={headerStyle}>
        Building Blocks <span style={topicStyle}>for</span> Beatbox
      </Header>
      <Divider />
      <Subheader>
        Beatblox aims to develop beatbox ideas into beatbox understanding as
        effectively as possible.
      </Subheader>
      <Break />
      <div style={wrapperStyle}>
        <div style={containerStyle}>
          <Feature
            src={communicate}
            name="Communicate"
            caption="with clarity"
            text="See and hear the patterns you write"
          />
          <Break />
          <Feature
            src={conceptualize}
            name="Conceptualize"
            caption="with confidence"
            text="Learn precisely without studying theory"
          />
          <Break />
          <Feature
            src={compose}
            name="Compose"
            caption="with complexity"
            text="Express beats at the highest level"
          />
        </div>
      </div>
      <Break />
      <Subheader>
        Launched in October of 2023 by{' '}
        <Link href={socialUrls.justinInstagram}>Justin</Link>,{' '}
        <Link href={socialUrls.kalapuikkoInstagram}>Kalapuikko</Link>,{' '}
        <Link href={socialUrls.kindredYouTube}>Kindred</Link>, and{' '}
        <Link href={socialUrls.trickyTelegram}>Tricky</Link> to help beatboxers
        along their journey of turning sounds they&apos;ve learned into music
        they enjoy.
      </Subheader>
    </div>
  );
}

export default About;
