import { chainMiddlewares } from '~/utils';
import { Reducer } from '~/models';

import { RecordState } from './models';
import { ACTIONS } from './actions';

const INITIAL_STATE: RecordState = {};

const reducer: Reducer<RecordState> = (state = INITIAL_STATE, action) => {
  if (action.type === ACTIONS.addRecord && state.records)
    return { ...state, records: [action.payload].concat(state.records) };
  else if (action.type === ACTIONS.listRecords)
    return { ...state, records: action.payload };
  else if (action.type === ACTIONS.editRecord && state.records)
    return {
      ...state,
      records: [action.payload].concat(
        state.records.filter((r) => r.id !== action.payload.id)
      ),
    };
  else if (action.type === ACTIONS.deleteRecord && state.records)
    return {
      ...state,
      records: state.records.filter((r) => r.id !== action.payload.id),
    };
  else if (action.type === ACTIONS.clearRecords)
    return { ...state, records: undefined };

  return state;
};

export default chainMiddlewares(reducer);
export { INITIAL_STATE };
