import React from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import Button from '~/components/Button';
import Gap from '~/components/Gap';

import { useContext } from '../providers/Content';

interface AddProps {
  onCreate: (event: Event) => void;
}

function Add({ onCreate }: AddProps) {
  const { state } = useContext();
  const { disabled } = state;

  // Styling
  const addStyle = {
    padding: '0.3em',
    backgroundColor: COLORS.doveGray,
    borderColor: COLORS.doveGray,
    borderRadius: '0.3em',
    fontSize: FONT_SIZES.text,
    boxShadow: `-0.07em 0.07em ${COLORS.black25}`,
    verticalAlign: 'middle',
  };

  return disabled ? (
    <></>
  ) : (
    <>
      <Gap />
      <Button label="Add Layer" callback={onCreate} style={addStyle} />
    </>
  );
}

export default Add;
