import React, {
  CSSProperties,
  DetailedHTMLProps,
  HTMLAttributes,
  ReactElement,
  PropsWithChildren,
  Children,
} from 'react';
import { SwipeableHandlers } from 'react-swipeable';

import COLORS from '~/constants/colors';
import { useThemeContext } from '~/providers/ThemeProvider';
import { mod } from '~/utils';

import { DEFAULT_SLIDE_SIZE, getExtendCount } from './useCarousel';
import { SlideSize } from './models';

interface CarouselProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  activeIdx: number;
  setActiveIdx: (number) => void;
  handlers: SwipeableHandlers;
  containerStyle: CSSProperties;
  slideSize?: SlideSize;
  disabled?: boolean;
  style?: CSSProperties;
}

function Carousel({
  activeIdx,
  setActiveIdx,
  handlers,
  containerStyle,
  slideSize = DEFAULT_SLIDE_SIZE,
  disabled = false,
  style,
  children,
  ...props
}: PropsWithChildren<CarouselProps>): ReactElement {
  const slides = Children.map(children, (c) => c) ?? [];
  const len = Children.count(children);
  const extendCount = getExtendCount(len);
  const stopExtraEffects = (event) => event.preventDefault();
  const handleTap = (idx) => (event) => {
    event.preventDefault();
    if (!disabled) setActiveIdx(idx);
  };
  const getKey = (idx) => `CarouselSlide${idx}`;

  // Organize extended slides
  const baseSlidePairs = slides.map((s, idx) => [s, idx]);
  const preSlidePairs = slides
    .slice(-extendCount)
    .map((s, idx) => [s, idx - extendCount]);
  const postSlidePairs = slides
    .slice(0, extendCount)
    .map((s, idx) => [s, idx + len]);
  const slidePairs = preSlidePairs.concat(baseSlidePairs, postSlidePairs);

  // Carousel styling
  const { theme } = useThemeContext().state;
  const carouselStyle = {
    display: 'inline-block',
    width: `min(100vw, calc(${len} * ${slideSize.total}))`,
    paddingBottom: '0.13em',
    overflow: 'hidden',
    ...style,
  };
  const adjustContainerStyle = {
    marginLeft: `min(0px, calc((100vw - ${len} * ${slideSize.total}) / 2))`,
  };
  const slideStyle = {
    display: 'inline-block',
    width: slideSize.width,
    height: slideSize.height,
    verticalAlign: 'top',
    margin: `0 ${slideSize.margin}`,
    color: disabled
      ? theme.disabled.default.color
      : theme.primary.default.color,
    backgroundColor: COLORS.black25,
    borderColor: disabled
      ? theme.disabled.default.color
      : theme.primary.default.color,
    borderStyle: 'solid',
    borderWidth: slideSize.border,
    overflow: 'hidden',
    cursor: 'pointer',
    // Prevent highlighting issues with pad modes on mobile
    WebkitTouchCallout: 'none' as const,
    WebkitUserSelect: 'none' as const,
    KhtmlUserSelect: 'none' as const,
    MozUserSelect: 'none' as const,
    MsUserSelect: 'none',
    userSelect: 'none' as const,
  };
  const activeSlideStyle = {
    ...slideStyle,
    color: disabled
      ? theme.disabled.default.color
      : theme.warning.default.color,
    backgroundColor: disabled
      ? theme.disabled.default.backgroundColor
      : theme.warning.default.backgroundColor,
    borderColor: disabled
      ? theme.disabled.default.backgroundColor
      : theme.warning.default.borderColor,
    boxShadow: `-0.13em 0.13em ${COLORS.black25}`,
  };
  const slideTableStyle = {
    display: 'table',
    width: '100%',
    height: '100%',
  };
  const slideCellStyle = {
    display: 'table-cell',
    verticalAlign: 'middle',
  };
  const getSlideStyle = (idx) =>
    activeIdx === mod(idx, len) ? activeSlideStyle : slideStyle;

  return (
    <div
      role="none"
      onMouseDown={stopExtraEffects}
      style={carouselStyle}
      {...props}
    >
      <div style={adjustContainerStyle}>
        <div {...(!disabled && handlers)} style={containerStyle}>
          {slidePairs.map((sp) => (
            <div
              role="none"
              key={getKey(sp[1])}
              onMouseUp={handleTap(sp[1])}
              onTouchEnd={handleTap(sp[1])}
              style={getSlideStyle(sp[1])}
            >
              <div style={slideTableStyle}>
                <div style={slideCellStyle}>{sp[0]}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Carousel;
