import { chainMiddlewares } from '~/utils';
import { Reducer } from '~/models';

import { AuthState } from './models';
import { startAuthed, fakeAuthState } from './config';
import { ACTIONS } from './actions';

const INITIAL_STATE: AuthState = startAuthed
  ? fakeAuthState
  : { isAuthed: false };

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.logIn:
      return { ...state, isAuthed: true, user: action.payload };
    case ACTIONS.logOut:
      return { isAuthed: false };
    case ACTIONS.updateSettings:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export default chainMiddlewares(reducer);
export { INITIAL_STATE };
