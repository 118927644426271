import React, { ReactElement } from 'react';
import { motion } from 'framer-motion';

import COLORS from '~/constants/colors';
import { FONT_WEIGHTS } from '~/constants/typography';

import { FeedbackPredicate } from '../predicates/models';
import { RendererProps } from './models';

function Feedback({
  predicate,
}: RendererProps<FeedbackPredicate>): ReactElement {
  const pos = predicate.getPos();
  const stat = predicate.stat > 999 ? 'MAX' : predicate.stat;

  // Feedback styling
  const getPx = (v) => `${v}px`;
  const feedbackStyle = {
    position: 'absolute' as const,
    width: getPx(predicate.dims.w),
    height: getPx(predicate.dims.h),
    left: getPx(pos.x - predicate.dims.w / 2),
    top: getPx(pos.y - predicate.dims.h / 2),
    textAlign: 'center' as const,
    textShadow: `0 0 ${getPx(predicate.dims.w / 18)} ${COLORS.black25}`,
    fontWeight: FONT_WEIGHTS.heavy,
    opacity: 0,
    ...predicate.style,
  };
  const statStyling = {
    color: predicate.statColor,
    fontSize: getPx(predicate.dims.h / 1.5),
  };
  const ratingStyling = {
    color: predicate.ratingColor,
    fontSize: getPx(predicate.dims.h / 3),
  };

  // Animation
  const variants = {
    reset: {
      opacity: 1,
    },
    show: {
      opacity: [0, 1, 0],
      transition: {
        duration: 0.45,
        ease: ['easeOut', 'linear'],
        times: [0, 0.33, 1],
      },
    },
  };

  return (
    <motion.div
      style={feedbackStyle}
      animate={predicate.animate}
      variants={variants}
    >
      <div style={statStyling}>{stat}</div>
      <div style={ratingStyling}>{predicate.rating}</div>
    </motion.div>
  );
}

export default Feedback;
