import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';

import { FONT_SIZES } from '~/constants/typography';
import { CONDITIONS } from '~/providers/ThemeProvider';
import Button from '~/components/Button';
import Modal from '~/components/Modal';
import Code from '~/components/Code';
import Break from '~/components/Break';

import { deleteClip } from '../../../services';
import { getRedirectUri } from '../../../utils';
import { Show } from '../../../models';

interface DeleteModalProps {
  code: string;
  setErrorText: Dispatch<SetStateAction<string>>;
  show?: Show;
}

function DeleteModal({
  code,
  setErrorText,
  show,
}: DeleteModalProps): ReactElement {
  const [showDelete, setShowDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Handlers
  const handleShowDelete = () => setShowDelete(true);
  const handleHideDelete = () => setShowDelete(false);
  const handleDelete = async () => {
    if (code) {
      try {
        setIsLoading(true);
        await deleteClip(code);
        setShowDelete(false);
        navigate(getRedirectUri(show));
      } catch {
        setIsLoading(false);
        setShowDelete(false);
        setErrorText('Deletion failed, please try again later');
      }
    }
  };

  // Delete modal styling
  const actionStyle = {
    width: '6.3em',
    margin: '0 0.5em',
    fontSize: FONT_SIZES.text,
  };
  const deleteContainerStyle = {
    maxWidth: 'min(30em, 82.5vw)',
    padding: '0em 0.5em',
  };
  const modalButtonsStyle = {
    float: 'right' as const,
    marginTop: '-0.5em',
  };
  const deleteStyle = {
    width: '5em',
    margin: '0.5em 0 0',
  };
  const cancelStyle = {
    ...deleteStyle,
    margin: '0 0.5em 0 0',
  };

  return (
    <>
      <Button
        condition={CONDITIONS.secondary}
        callback={handleShowDelete}
        label="Delete"
        style={actionStyle}
      />
      <Modal
        visible={showDelete}
        header="Are you sure?"
        callback={handleHideDelete}
      >
        <div style={deleteContainerStyle}>
          Deleting recording <Code>{code}</Code> is permanent. Please check
          before proceeding.
        </div>
        <Break />
        <div style={modalButtonsStyle}>
          <Button
            label="Cancel"
            condition={CONDITIONS.secondary}
            callback={handleHideDelete}
            style={cancelStyle}
          />
          <Button
            label="Delete"
            loading={isLoading}
            condition={CONDITIONS.fail}
            callback={handleDelete}
            style={deleteStyle}
          />
        </div>
      </Modal>
    </>
  );
}

export default DeleteModal;
