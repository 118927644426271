import { MutableRefObject } from 'react';
import GameEngine from 'react-game-engine';

import { Dim, InputPadPredicate } from './models';
import { EVENTS } from '../constants/events';
import { INPUT_MODES, PANEL_DIMS } from '../constants/layout';

const inputPadPredicate = (
  windowDims: Dim,
  inputMode: string,
  whichPadLabels: string,
  targetLabel: string,
  guidance: boolean,
  gameEngineRef: MutableRefObject<GameEngine>,
  phase: string
): InputPadPredicate => {
  const jogging = inputMode === INPUT_MODES.jog;
  const panelDims =
    PANEL_DIMS(windowDims)[jogging ? INPUT_MODES.jog : INPUT_MODES.gym];
  const panelS = 2 * panelDims.b + 2 * panelDims.m;
  const spacedWidth = panelDims.w + panelS;
  const spacedHeight = panelDims.h + panelS;
  const padWidth = (jogging ? 1 : 3) * spacedWidth;
  const padHeight = (jogging ? 1 : 4) * spacedHeight;
  const keyModeCallback = (label) =>
    gameEngineRef.current?.dispatch(EVENTS.onKeyDown(label.key));
  const padModeCallback = (label) =>
    gameEngineRef.current?.dispatch(EVENTS.onInputPadSend(label));
  const callback =
    inputMode === INPUT_MODES.key ? keyModeCallback : padModeCallback;

  // Input pad styling
  const inputPadStyle = {
    zIndex: 10,
  };

  return {
    dims: { w: padWidth, h: padHeight },
    getPos: () => ({
      x: windowDims.w / 2,
      y: windowDims.h - spacedHeight - padHeight / 2,
    }),
    panelDims,
    inputMode,
    whichPadLabels,
    targetLabel,
    guidance,
    callback,
    phase,
    style: inputPadStyle,
  };
};

export default inputPadPredicate;
