import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
  CSSProperties,
} from 'react';

import COLORS from '~/constants/colors';

interface FramedViewProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  accent?: boolean;
  colors?: [string, string];
  style?: CSSProperties;
}

function FramedView({
  accent = false,
  colors = [COLORS.waikawaGray, COLORS.waikawaGray],
  style,
  children,
  ...props
}: PropsWithChildren<FramedViewProps>): ReactElement {
  const accentStyle = {
    borderLeft: `0.75em solid ${colors[0]}`,
    borderTop: `0.75em solid ${colors[1]}`,
  };
  const framedViewStyle = {
    padding: '0.7em',
    color: COLORS.mineShaft,
    backgroundColor: COLORS.white,
    boxShadow: `-0.13em 0.13em ${COLORS.black25}`,
    borderRadius: '0.75em',
    textAlign: 'left' as const,
    ...(accent && accentStyle),
    ...style,
  };

  return (
    <div style={framedViewStyle} {...props}>
      {children}
    </div>
  );
}

export default FramedView;
