import React, { ReactElement, CSSProperties } from 'react';

import Header from '~/components/Header';
import Subtext from '~/components/Subtext';
import Break from '~/components/Break';

import OptionInput from './OptionInput';
import { Option } from '../models';

interface EffectOptionsProps {
  header: string;
  subtext: string;
  options: Option[];
  reset: boolean;
  style?: CSSProperties;
}

function EffectOptions({
  header,
  subtext,
  options,
  reset,
  style,
}: EffectOptionsProps): ReactElement {
  const getKey = (idx) =>
    `BeatboxShareActionPanelEffectOptionsOptionInput${idx}`;

  return (
    <>
      <Header>{header}</Header>
      <Subtext>{subtext}</Subtext>
      <Break />
      {options.map((o, idx, { length }) => (
        <OptionInput
          key={getKey(idx)}
          title={o.title}
          option={o.option}
          reset={reset}
          min={o.min}
          max={o.max}
          step={o.step}
          units={o.units}
          {...(idx < length - 1 && { style })}
        />
      ))}
    </>
  );
}

export default EffectOptions;
