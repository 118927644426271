import { SettingsInfo, PasswordInfo } from '~/providers/AuthProvider';

export const DEFAULT_SETTINGS_INFO: SettingsInfo = {
  email: '',
  handle: '',
};

export const DEFAULT_PASSWORD_INFO: PasswordInfo = {
  currentPassword: '',
  password: '',
};
