import React, { ReactElement, useState } from 'react';

import Announcement from '~/components/Announcement';
import Gap from '~/components/Gap';

interface AudioPlayerProps {
  url: string;
}

function AudioPlayer({ url }: AudioPlayerProps): ReactElement {
  const [errorText, setErrorText] = useState('');
  const controlsList = 'nodownload nofullscreen noremoteplayback';

  // Handlers
  const onError = () => {
    setErrorText('Could not load audio, please try again later');
  };

  // Audio player styling
  const audioStyle = {
    width: '98%',
  };

  return (
    <div>
      {errorText && (
        <>
          <Announcement>{errorText}</Announcement>
          <Gap />
        </>
      )}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        src={url}
        controls
        controlsList={controlsList}
        onError={onError}
        style={audioStyle}
      />
    </div>
  );
}

export default AudioPlayer;
