import React, { Dispatch, SetStateAction, useState } from 'react';

import { stopEventEffects } from '~/utils';
import COLORS from '~/constants/colors';
import Select from '~/components/Select';
import { FONTS, FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';
import Break from '~/components/Break';
import Gap from '~/components/Gap';

import { Beat } from '../../../models';
import { BEAT } from '../../../constants/defaults';
import { BEATS, LINKS } from '../beats';

interface FinishProps {
  // beat: Beat;
  setBeat: Dispatch<SetStateAction<Beat>>;
  disabled?: boolean;
  setExample?: Dispatch<SetStateAction<Beat>>; // TODO: remove this in future versions
}

function Finish({
  setBeat,
  disabled,
  setExample, // TODO: remove this in future versions
}: FinishProps) {
  // Handlers
  // const handleTitle = (event) => {
  //   stopEventEffects(event);
  //   setBeat({ ...beat, title: event.target.value });
  // };

  // Styling
  const comingSoonStyle = {
    color: COLORS.tundora,
  };
  const selectStyles = {
    control: () => ({
      fontFamily: FONTS.typewriter,
      fontSize: FONT_SIZES.text,
      fontWeight: FONT_WEIGHTS.normal,
      borderColor: COLORS.platinum,
      boxShadow: `-0.07em 0.07em ${COLORS.black25}`,
      '&:hover': undefined,
    }),
    placeholder: () => ({
      whiteSpace: 'nowrap' as const,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }),
    dropdownIndicator: () => ({
      color: COLORS.doveGray,
      ':hover': undefined,
    }),
    container: () => ({
      zIndex: 6,
    }),
  };
  const clearStyle = {
    color: COLORS.silver,
    fontWeight: FONT_WEIGHTS.bold,
    fontSize: FONT_SIZES.subtext,
    cursor: 'pointer',
  };

  // Example
  // TODO: remove this and relevant components rendered below in future versions
  const [optionIdx, setOptionIdx] = useState<number>();
  const options = Object.keys(BEATS).map((k) => ({
    value: Number(k),
    label: BEATS[k].title,
  }));
  const handleExamples = (option) => {
    setExample?.(BEATS[option.value]);
    setOptionIdx(option.value);
  };
  const clearExample = (event) => {
    stopEventEffects(event);
    setExample?.(BEAT);
    setOptionIdx(undefined);
  };

  return (
    <>
      <span style={comingSoonStyle}>Publishing and sharing coming soon!</span>
      <Break />
      <Break />
      <Select
        options={options}
        disabled={disabled}
        menuPlacement="auto"
        onChange={handleExamples}
        value={optionIdx !== undefined && options[optionIdx]}
        placeholder="Load an example"
        styles={selectStyles}
      />
      <Gap />
      <div
        role="button"
        tabIndex={-1}
        onMouseUp={clearExample}
        onTouchEnd={clearExample}
        style={clearStyle}
      >
        Clear Example
      </div>
      <Break />
      {optionIdx !== undefined && LINKS[optionIdx] && (
        <iframe
          title="BeatbloxWriteFinishIframe"
          width={480}
          height={270}
          src={LINKS[optionIdx]}
          frameBorder="0"
          allow="fullscreen; encrypted-media"
        />
      )}
      {/* <input
        type="text"
        value={beat.title}
        placeholder="Title"
        onChange={handleTitle}
      /> */}
    </>
  );
}

export default Finish;
