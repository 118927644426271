import React, {
  ReactElement,
  useEffect,
  useImperativeHandle,
  ForwardedRef,
  forwardRef,
  Dispatch,
  SetStateAction,
  memo,
} from 'react';
import axios from 'axios';

import { getArrayBuffer } from '~/utils';

import { useReactMediaRecorder } from './ReactMediaRecorder';
import { getAudioBufferFromArrayBuffer, filterConstraints } from '../utils';
import { DeviceState } from '../models';
import {
  MIME_TYPES,
  CHANNEL_COUNT,
  SAMPLE_RATE,
  BIT_RATE_THOUSANDS,
  VIDEO_SIZE_LIMIT,
} from '../constants/media';

interface WebcamRef {
  start: () => void;
  pause: () => void;
  resume: () => void;
  stop: () => void;
  reset: () => void;
  preview?: MediaStream;
}

interface WebcamConfig extends Record<string, any> {
  setState: Dispatch<SetStateAction<DeviceState>>;
  setError: Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  audio: Record<string, boolean>;
  cookedCallback: (blob: Blob) => void;
  rawCallback: (buffer: AudioBuffer) => void;
}

interface WebcamProps {
  state: DeviceState;
  config: WebcamConfig;
  ref: ForwardedRef<WebcamRef>;
}

function Webcam({ state, config, ref }: WebcamProps): ReactElement {
  const hasMediaRecorder = 'MediaRecorder' in window;

  // Figure out MIME type
  let mimeType;

  if (window.MediaRecorder.isTypeSupported(MIME_TYPES.mp4))
    mimeType = MIME_TYPES.mp4;
  else if (window.MediaRecorder.isTypeSupported(MIME_TYPES.webm))
    mimeType = MIME_TYPES.webm;
  else if (window.MediaRecorder.isTypeSupported(MIME_TYPES.mkv))
    mimeType = MIME_TYPES.mkv;

  // Set up recorder
  const finalConfig = {
    audio: filterConstraints({
      channelCount: CHANNEL_COUNT,
      sampleRate: SAMPLE_RATE,
      ...config.audio,
    }),
    video: filterConstraints({
      width: { ideal: 720, max: 720 },
      height: { ideal: 1280, max: 1280 },
      facingMode: 'user',
      frameRate: { ideal: 30, max: 30 },
    }),
    ...(mimeType && { blobPropertyBag: { type: mimeType } }),
    mediaRecorderOptions: {
      ...(mimeType && { mimeType }),
      audioBitsPerSecond: BIT_RATE_THOUSANDS,
      videoBitsPerSecond: 2500000,
      bitsPerSecond: 2628000,
    },
  };
  const {
    error,
    status,
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    clearBlobUrl,
    clean,
    previewStream,
    mediaBlobUrl,
  } = useReactMediaRecorder(finalConfig);

  // Set interface
  const reset = () => {
    clean();
    config.setState('closed');
    config.setError('');
  };

  useImperativeHandle(
    ref,
    () => ({
      start: startRecording,
      pause: pauseRecording,
      resume: resumeRecording,
      stop: stopRecording,
      reset,
      preview: previewStream ?? undefined,
    }),
    [previewStream]
  );

  // Update error
  useEffect(() => {
    if (error === 'media_aborted')
      config.setError('Device aborted, please refresh and try again');
    else if (error === 'permission_denied')
      config.setError('Please allow access to your webcam');
    else if (error === 'no_specified_media_found')
      config.setError('Could not find suitable device for capturing video');
    else if (error === 'media_in_use')
      config.setError('Webcam in use by another process');
    else if (error === 'recorder_error')
      config.setError('Recording failed after device setup');
    else if (error) config.setError('Please refresh the page and try again');
    else if (!hasMediaRecorder)
      config.setError('Filming is not supported by your browser');
    else config.setError('');

    // Turn off loading on error
    if (error) config.setLoading(false);
  }, [error]);

  // Check status
  useEffect(() => {
    if (status === 'paused') config.setState('suspended');
    else if (status === 'recording') config.setState('running');
    else if (status === 'stopped') config.setState('done');
    else if (state !== 'done') config.setState('closed');
  }, [status]);

  // Handle results
  useEffect(() => {
    (async () => {
      if (!mediaBlobUrl) return;

      // Get raw video blob
      const response = await axios.get(mediaBlobUrl, { responseType: 'blob' });
      const rawVideoBlob = response.data;

      clearBlobUrl(); // Needed since `react-media-recorder` doesn't expose blob so prevents having duplicates in memory

      // Get raw audio buffer
      const arrayBuffer = await getArrayBuffer(rawVideoBlob);
      const audioBuffer = await getAudioBufferFromArrayBuffer(arrayBuffer);

      // Check size
      if (rawVideoBlob.size > VIDEO_SIZE_LIMIT) {
        config.setError('Please film a smaller video');
        throw new Error('Please film a smaller video');
      }

      // Set captured content
      config.cookedCallback(rawVideoBlob);
      config.rawCallback(audioBuffer);
    })();
  }, [mediaBlobUrl]);

  // Unmount
  useEffect(() => () => reset(), []);

  return <></>;
}

export default memo(
  forwardRef((props: WebcamProps, ref: ForwardedRef<WebcamRef>) =>
    Webcam({ ...props, ref })
  )
);
export type { WebcamRef };
