import React, { ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';

import Gap from '~/components/Gap';
import Header from '~/components/Header';
import Paginator, { getPageSlice } from '~/components/Paginator';

import CONTENT from '../constants/content';
import { DEFAULT_LABEL_PREFIX } from '../constants/defaults';
import { useBeatboxContext } from '../providers/BeatboxProvider';
import uris from '../uris';
import ContentItem from './ContentItem';
import NoContentItems from './NoContentItems';
import { capitalizeFirstLetter, getSingular } from '../utils';

interface CurrentListProps {
  plural: string;
}

function CurrentList({ plural }: CurrentListProps): ReactElement {
  const { state: beatboxState } = useBeatboxContext();
  const singular = getSingular(plural);
  const cappedPlural = capitalizeFirstLetter(plural);
  const contentItemUriKey = `manage${capitalizeFirstLetter(singular)}`;
  const paginatorUriKey = `manage${cappedPlural}`;
  const defaultLabel = `${DEFAULT_LABEL_PREFIX} ${singular}`;
  const getLabel = (c) => {
    switch (plural) {
      case CONTENT.categories:
        return c.label;
      case CONTENT.techniques:
        return c.name;
      case CONTENT.demos:
      case CONTENT.tutorials:
        return c.techniques?.map((t) => t.name).join(', ') || defaultLabel;
      default:
        return defaultLabel;
    }
  };
  const getKey = (idx) => `BeatboxDbCurrentListContentItem${idx}`;

  // Processing
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) || 1;
  const source = beatboxState[plural];
  const data = source?.data || getPageSlice(source, page);
  const itemTotal = source?.meta?.pagination.total || source?.length;

  return (
    <>
      <Header>Current {cappedPlural}</Header>
      Click a {singular} below to edit it
      <Gap />
      {(data?.length || 0) > 0 ? (
        <>
          {data?.map((c, idx) => (
            <ContentItem
              key={getKey(idx)}
              id={c.id}
              label={getLabel(c)}
              link={uris[contentItemUriKey](c.id)}
            />
          ))}
          <Paginator link={uris[paginatorUriKey]} itemTotal={itemTotal} />
        </>
      ) : (
        <NoContentItems plural={plural} />
      )}
    </>
  );
}

export default CurrentList;
