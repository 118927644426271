import React, {
  ReactElement,
  Dispatch,
  SetStateAction,
  MutableRefObject,
} from 'react';
import { GameEngine } from 'react-game-engine';
import Matter from 'matter-js';

import { Input, Settings } from '../../models';
import { getGameDims } from './utils';
import Receptor from './entities/Receptor';
import Keyboard from './entities/Keyboard';
import tempo from './systems/tempo';
import score from './systems/score';
import input from './systems/input';

interface GameProps {
  running: boolean;
  setRunning: Dispatch<SetStateAction<boolean>>;
  settings: Settings;
  setInputs: Dispatch<SetStateAction<Input[]>>;
  gameEngineRef: MutableRefObject<GameEngine>;
}

function Game({
  running,
  setRunning,
  settings,
  setInputs,
  gameEngineRef,
}: GameProps): ReactElement {
  const gameDims = getGameDims();

  // Entities
  const getEntities = () => ({
    game: {
      gameDims,
      setRunning,
      engine: Matter.Engine.create(),
      settings,
      setInputs,
      state: {
        startTime: -1,
        renderedBarIdx: -1,
        renderedNoteIdx: -1,
        inputs: [] as Input[],
      },
    },

    // Static
    receptor: Receptor(gameDims, 500),
    keyboard: Keyboard(gameDims, gameEngineRef, settings.chart),

    // Dynamic
    bars: [],
    notes: [],
  });

  // Systems
  const systems = [tempo, score, input];

  // Game styling
  const gameStyle = {
    position: 'absolute' as const,
    top: 0,
    left: '50%',
    marginLeft: -gameDims.w / 2,
    width: gameDims.w,
    height: gameDims.h,
    overflow: 'hidden', // Needed to hide gutter where things load
  };

  // Renderer
  const recursiveRenderer = (entities, window) => {
    if (!entities || !window) return [];

    // Apply default renderer recursively
    const defaultRenderer = (entity, key) => {
      if (typeof entity.render === 'object')
        return (
          <entity.render.type
            key={key}
            window={window}
            entity={entity}
            gameDims={gameDims}
          />
        );
      else if (typeof entity.render === 'function')
        return (
          <entity.render
            key={key}
            window={window}
            entity={entity}
            gameDims={gameDims}
          />
        );

      return undefined;
    };
    const recurser = (list, key = '0') => {
      const acc: ReactElement[] = [];

      if (list.length === 0) return [];
      list.forEach((element, idx) => {
        if (element && element.render) {
          const rendered = defaultRenderer(element, `entities_${key}_${idx}`);

          if (rendered) acc.push(rendered);
        } else if (Array.isArray(element))
          acc.push(...recurser(element, `${key}_${idx}`));
      });

      return acc;
    };

    return recurser(Object.values(entities));
  };

  return running ? (
    <div style={gameStyle}>
      <GameEngine
        renderer={recursiveRenderer}
        entities={getEntities()}
        systems={systems}
        running={running}
        ref={gameEngineRef}
      />
    </div>
  ) : (
    <></>
  );
}

export default Game;
