import { ActionDispatch, ActionCreator } from '~/models';

import { SwitchTheme } from './models';

export const ACTIONS = {
  switchTheme: 'theme/switchTheme',
};

// Creators
const switchThemeCreator: ActionCreator<string> = (key) => ({
  type: ACTIONS.switchTheme,
  payload: key,
});

// Thunks
export const switchThemeThunk =
  (dispatch: ActionDispatch): SwitchTheme =>
  (key) => {
    const action = switchThemeCreator(key);

    dispatch(action);
  };
