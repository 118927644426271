import React, {
  RefObject,
  Dispatch,
  SetStateAction,
  ReactElement,
} from 'react';
import { AnimationControls } from 'framer-motion';

import Triangle from '~/components/Triangle';

import Choice from './Choice';
import Target from './Target';
import Lives from './Lives';
import Score from './Score';
import Stopwatch from './Stopwatch';
import Flip from './Flip';
import { StopwatchRef } from '../models';

interface TilesProps {
  lives: number;
  score: [number, boolean];
  maxPoints: number;
  target: string;
  choices: string[];
  targetControls: AnimationControls;
  choicesControls: AnimationControls[];
  setPlaytime: Dispatch<SetStateAction<string>>;
  stopwatchRef: RefObject<StopwatchRef>;
  flip: number;
  setFlip: Dispatch<SetStateAction<number>>;
}

function Tiles({
  lives,
  score,
  maxPoints,
  target,
  choices,
  targetControls,
  choicesControls,
  setPlaytime,
  stopwatchRef,
  flip,
  setFlip,
}: TilesProps): ReactElement {
  const triBase = '20px';
  const triH2W = 3 / 4;

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>
              <Lives lives={lives} />
              <Stopwatch setPlaytime={setPlaytime} ref={stopwatchRef} />
            </td>
            <td />
            <td>
              <Choice
                label={choices[1]}
                index={1}
                choiceControls={choicesControls[1]}
              />
            </td>
            <td />
            <td>
              <Flip flip={flip} setFlip={setFlip} />
              <Score points={score[0]} maxPoints={maxPoints} />
            </td>
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <Triangle base={triBase} ratio={triH2W} direction="up" />
            </td>
            <td />
            <td />
          </tr>
          <tr>
            <td>
              <Choice
                label={choices[0]}
                index={0}
                choiceControls={choicesControls[0]}
              />
            </td>
            <td>
              <Triangle base={triBase} ratio={triH2W} direction="left" />
            </td>
            <td>
              <Target label={target} targetControls={targetControls} />
            </td>
            <td>
              <Triangle base={triBase} ratio={triH2W} direction="right" />
            </td>
            <td>
              <Choice
                label={choices[3]}
                index={3}
                choiceControls={choicesControls[3]}
              />
            </td>
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <Triangle base={triBase} ratio={triH2W} direction="down" />
            </td>
            <td />
            <td />
          </tr>
          <tr>
            <td />
            <td />
            <td>
              <Choice
                label={choices[2]}
                index={2}
                choiceControls={choicesControls[2]}
              />
            </td>
            <td />
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Tiles;
