import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import SwitchBoard, { TextSwitch } from '~/components/SwitchBoard';

import uris from '../../../uris';
import { capitalizeFirstLetter } from '../../../utils';
import { MODES } from '../constants';

interface ModeSwitchBoardProps {
  mode: string;
  fields: string[];
  currentField: string;
}

function ModeSwitchBoard({
  mode,
  fields,
  currentField,
}: ModeSwitchBoardProps): ReactElement {
  const navigate = useNavigate();
  const labels =
    mode === MODES.list ? fields : fields.map((f) => capitalizeFirstLetter(f));
  const choices = fields.map((f) => f === currentField);
  const callbacks = fields.map(
    (f) => () => navigate(uris.exploreModeField(mode, f))
  );

  // Mode switch board styling
  const modeSwitchBoardStyle = {
    textAlign: 'center' as const,
  };

  return (
    <div style={modeSwitchBoardStyle}>
      <SwitchBoard
        Switch={TextSwitch}
        labels={labels}
        choices={choices}
        callbacks={callbacks}
      />
    </div>
  );
}

export default ModeSwitchBoard;
