import React from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import Button from '~/components/Button';

import { useContext } from '../providers/Content';

interface RemoveProps {
  onDelete: (event: Event) => void;
}

function Remove({ onDelete }: RemoveProps) {
  const { state } = useContext();
  const { patterns, disabled } = state;
  const finalDisabled = disabled || patterns.length < 2;

  // Handlers
  const handleDelete = (event) => {
    if (!finalDisabled) onDelete(event);
  };

  // Styling
  const removeStyle = {
    display: 'inline-block',
    width: '2.2em',
    marginLeft: '0.5em',
  };
  const buttonStyle = {
    width: '2em',
    height: '2em',
    padding: '0.2em',
    fontSize: FONT_SIZES.text,
    backgroundColor: COLORS.white,
    borderColor: COLORS.doveGray,
    borderWidth: '1.5px',
    borderRadius: '0.4em',
    boxShadow: `-0.07em 0.07em ${COLORS.black50}`,
    verticalAlign: 'middle',
  };

  return (
    <div style={removeStyle}>
      {!finalDisabled && (
        <Button label="🗑" callback={handleDelete} style={buttonStyle} />
      )}
    </div>
  );
}

export default Remove;
