import {
  createContext,
  useContext,
  useLayoutEffect,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
} from 'react';
import axios from 'axios';
import { FieldError } from 'react-hook-form';
import { useLocation, matchRoutes } from 'react-router-dom';

import { BadRequest, SafeContext, Reducer, StringLiteral } from '~/models';
import { STATUS_CODES } from '~/constants/api';
import DOMAINS, { HOST } from '~/constants/domains';

export const stopEventEffects = (event: Event | SyntheticEvent): void => {
  event.preventDefault();
  event.stopPropagation();
};

export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const mod = (a: number, q: number): number => ((a % q) + q) % q;

export const getModDistance = (a: number, b: number, q: number): number => {
  const average = (a + b) / 2;
  const standard = Math.abs(a - b);
  const reverse = Math.abs(mod(a - average, q) - mod(b - average, q));

  return Math.min(standard, reverse);
};

export const shuffleArray = (array: any[]): any[] =>
  array
    .map((value) => ({ value, compare: Math.random() }))
    .sort((a, b) => a.compare - b.compare)
    .map(({ value }) => value);

export const isObject = (value: any): boolean =>
  typeof value === 'object' && !Array.isArray(value) && value !== null;

export const isEmptyObject = (value: any): boolean => {
  if (isObject(value)) {
    for (const k in value) // eslint-disable-line no-restricted-syntax
      if (Object.prototype.hasOwnProperty.call(value, k)) return false;

    return true;
  }

  return false;
};

export const useTitle = (title: string): void => {
  useLayoutEffect(() => {
    const prevTitle = document.title;

    document.title = title;

    return () => {
      document.title = prevTitle;
    };
  });
};

export const getArrayBuffer = async (
  file: File | Blob
): Promise<ArrayBuffer> => {
  let arrayBuffer;

  if (file.arrayBuffer) arrayBuffer = await file.arrayBuffer();
  else {
    const fileReader = new FileReader();
    const bufferPromise = new Promise((resolve) => {
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.readAsArrayBuffer(file);
    });

    arrayBuffer = await bufferPromise;
  }

  return arrayBuffer;
};

export const getCaretPositions = (
  element: HTMLElement | null
): { start: number; end: number; direction?: 'forward' | 'backward' } => {
  const selection = window.getSelection();

  if (!selection || !element) return { start: -1, end: -1 };

  const offsets = [selection.anchorOffset, selection.focusOffset];
  const targets = [selection.anchorNode, selection.focusNode];
  const lastIdx = element?.textContent ? element.textContent.length : 0;

  if (!element.contains(targets[0]) || !element.contains(targets[1]))
    return { start: -1, end: -1 };
  else if (targets[0] === element) return { start: lastIdx, end: lastIdx };
  else if (targets[1] === element) return { start: 0, end: lastIdx };

  let hits = [false, false];
  let ps = [0, 0];
  const walk = (e) => {
    let found = false;

    hits = targets.map((t, idx) => hits[idx] || e === t);
    if (hits[0] && hits[1]) found = true;
    else if (e.textContent && !e.firstChild)
      ps = ps.map((p, idx) => (!hits[idx] ? p + e.textContent.length : p));
    for (let c = e.firstChild; c && !found; c = c.nextSibling) found = walk(c);

    return found;
  };

  walk(element);
  ps = ps.map((p, idx) => p + offsets[idx]);

  const start = Math.min(...ps);
  const end = Math.max(...ps);
  const direction = ps[0] < ps[1] ? 'forward' : 'backward';

  return { start, end, ...(ps[0] !== ps[1] && { direction }) };
};

// Routing
export const addStar = (path: string): string => `${path}/*`;

export const addParams = (
  url: string,
  params: Record<string, any> = {}
): string => {
  try {
    const newUrl = new URL(url);

    newUrl.searchParams.forEach((v, k) => {
      if (!v) newUrl.searchParams.delete(k);
    });
    Object.keys(params).forEach((k) => {
      if (params[k] !== undefined && params[k] !== null) {
        if (newUrl.searchParams.has(k)) newUrl.searchParams.set(k, params[k]);
        else newUrl.searchParams.append(k, params[k]);
      }
    });

    return newUrl.toString();
  } catch {
    const safeUrl = new URL(url, window.location.origin).toString();

    return addParams(safeUrl, params).slice(window.location.origin.length);
  }
};

export const getRelativeUrl = (url: string = window.location.href): string => {
  const splitUrl = url.split('//')[1] || '/';
  const startIdx = splitUrl.indexOf('/');

  return startIdx >= 0 ? splitUrl.substring(startIdx) : '/';
};

export const getHostUrl = (path: string, domain = DOMAINS.ponder) =>
  HOST === domain ? path : `${window.location.protocol}//${domain}${path}`;

export const getCurrentPathGetter =
  (paths: Record<string, string>, base: string, fallback: string) =>
  (): string => {
    const routes = Object.values(paths).map((p) => ({
      path: addStar(`${base}/${p}`),
    }));
    const location = useLocation();
    const matches = matchRoutes(routes, location);
    const match = matches?.[0].pathname.slice(base.length + 1);

    return match || fallback;
  };

// Error handling
export const getErrorHandler =
  (task: string) =>
  (error: Error): Promise<never> => {
    const hasMessage = Object.prototype.hasOwnProperty.call(error, 'message');
    let errorMessage = hasMessage ? error.message : error;

    if (axios.isAxiosError(error)) {
      if (error.response) errorMessage = error.response.data;
      else if (error.request) errorMessage = error.request;
    }
    console.log(`Failed ${task}:`);
    console.log(errorMessage);

    return Promise.reject(error);
  };

export const isBadRequest = (error: unknown): error is BadRequest =>
  axios.isAxiosError(error) &&
  error.response?.status === STATUS_CODES.badRequest;

// Journal
export const getJournalFormErrorHandler =
  <T>(
    defaultInfo: Record<string, any>,
    setError: (name: StringLiteral<T>, error: FieldError) => void,
    setFail: Dispatch<SetStateAction<string | undefined>>,
    resolve?: () => void
  ) =>
  (error: Error): void => {
    if (isBadRequest(error)) {
      const errorDetails = error.response.data.error.details;
      const formKeys = Object.keys(defaultInfo);
      const errorKeys = Object.keys(errorDetails);

      if (errorKeys.length > 0)
        errorKeys.forEach((k) => {
          if (formKeys.includes(k))
            setError(k as StringLiteral<T>, {
              type: 'custom',
              message: errorDetails[k],
            });
        });
      else setFail(error.response?.data.error.message);
    } else if (axios.isAxiosError(error))
      setFail(error.response?.data.message?.[0]?.messages[0]?.message);
    resolve?.();
  };

export const flattenJournalData = <T>(data: Record<string, any>): T => {
  let flattened = data?.data || data;

  if (isObject(flattened)) {
    if (flattened.id) flattened = { id: flattened.id, ...flattened.attributes };
    flattened = Object.keys(flattened).reduce(
      (acc, k) => ({
        ...acc,
        [k]: flattenJournalData(flattened[k]),
      }),
      {} as Record<string, any>
    );
  } else if (Array.isArray(flattened))
    flattened = flattened.map((v) => flattenJournalData(v));

  return flattened;
};

// State
export const createSafeContext = <T>(): SafeContext<T> => {
  const context = createContext<T | undefined>(undefined);
  const useSafeContext = () => {
    const value = useContext(context);

    if (!value)
      throw new Error('useContext must be inside a Provider with a value!');

    return value;
  };

  return [useSafeContext, context.Provider] as const;
};

export const chainMiddlewares = <S>(reducer: Reducer<S>): Reducer<S> => {
  // Middlewares
  const logAction = (state, action) => {
    console.log(`Dispatching:`);
    console.log(action);

    return state;
  };
  const logPreviousState = (state) => {
    console.log(`Previous state:`);
    console.log(state);

    return state;
  };
  const logNextState = (state) => {
    console.log(`Next state:`);
    console.log(state);

    return state;
  };

  // Coordinate chaining
  const chain = [logAction, logPreviousState, reducer, logNextState];
  const newReducer = (state, action) =>
    chain.reduce((acc, middleware) => middleware(acc, action), state);

  return newReducer;
};
