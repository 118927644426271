import React, { ReactElement, Dispatch, SetStateAction } from 'react';

import SwitchBoard from '~/components/SwitchBoard';
import COLORS from '~/constants/colors';

import PHASES from '../constants/phases';

interface NavSwitchBoardProps {
  phase: string;
  setPhase: Dispatch<SetStateAction<string>>;
}

function NavSwitchBoard({
  phase,
  setPhase,
}: NavSwitchBoardProps): ReactElement {
  const phases = [
    PHASES.menu,
    PHASES.studio,
    PHASES.classroom,
    PHASES.tutorial,
  ];
  const labels = ['▶', '♪', '✎', '?'];
  const choices = phases.map((p) => p === phase);
  const callbacks = phases.map((c) => () => setPhase(c));

  // Navigation switch board styling
  const navSwitchBoardStyle = {
    marginBottom: '2em',
  };
  const switchStyle = {
    color: COLORS.alabaster,
    backgroundColor: COLORS.waikawaGray,
  };
  const switchActiveStyle = {
    color: COLORS.white,
    backgroundColor: COLORS.cornflower,
  };

  return (
    <SwitchBoard
      labels={labels}
      choices={choices}
      callbacks={callbacks}
      switchStyle={switchStyle}
      switchActiveStyle={switchActiveStyle}
      style={navSwitchBoardStyle}
    />
  );
}

export default NavSwitchBoard;
