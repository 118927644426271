import React, { ReactElement, Dispatch, SetStateAction } from 'react';

import Flipper from '~/components/Flipper';
import COLORS from '~/constants/colors';

import { INPUT_MODES } from '../constants/layout';

interface InputModeFlipperProps {
  mode: string;
  setMode: Dispatch<SetStateAction<string>>;
  answersIncluded: boolean;
}

function InputModeFlipper({
  mode,
  setMode,
  answersIncluded,
}: InputModeFlipperProps): ReactElement {
  const indexMap = {
    [INPUT_MODES.key]: 0,
    [INPUT_MODES.jog]: 1,
    [INPUT_MODES.gym]: 2,
  };
  const idx = indexMap[mode];
  const labels = ['⌨️  Mode', '🏃‍♀️ Mode', '🏋️‍♂️ Mode'];
  const handleFlip = () => {
    const modes = Object.keys(indexMap);

    setMode(modes[(idx + 1) % modes.length]);
  };

  // Input mode flipper styling
  const inputModeFlipperStyle = {
    padding: '0.2em',
    cursor: 'pointer',
  };
  const caseStyles = [
    { backgroundColor: COLORS.white },
    { backgroundColor: COLORS.tropicalBlue },
    { backgroundColor: COLORS.fallGreen },
  ];

  return (
    <Flipper
      labels={labels}
      idx={idx}
      callback={handleFlip}
      disabled={!answersIncluded}
      style={inputModeFlipperStyle}
      caseStyles={caseStyles}
    />
  );
}

export default InputModeFlipper;
