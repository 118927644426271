import React from 'react';

import DayGround from '~/components/DayGround';
import Brand from '~/components/Brand';
import { FONT_SIZES } from '~/constants/typography';

import SettingsForm from './components/SettingsForm';

function Settings() {
  const settingsStyle = {
    width: '100%',
    fontSize: FONT_SIZES.text,
    textAlign: 'center' as const,
  };
  const containerStyle = {
    display: 'flex',
    minWidth: 350,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  };

  return (
    <div style={settingsStyle}>
      <DayGround>
        <div style={containerStyle}>
          <Brand topic="Settings" />
          <SettingsForm />
        </div>
      </DayGround>
    </div>
  );
}

export default Settings;
