import {
  JOURNAL_OFFSETS,
  JOURNAL_REGIONS,
  JOURNAL_URL,
  JOURNAL_SUBDOMAIN,
} from '~/constants/api';
import { getModDistance } from '~/utils';

const zones = Object.keys(JOURNAL_OFFSETS).reduce(
  (acc, k) => ({ ...acc, [k]: JOURNAL_OFFSETS[k] + 12 * 60 }),
  {} as Record<string, number>
);
const getGap = (o) => getModDistance(o, zones.device, 25 * 60);
const distances = Object.keys(JOURNAL_REGIONS).reduce(
  (acc, k) => ({ ...acc, [k]: getGap(zones[k]) }),
  {} as Record<string, number>
);
const nearest = Object.keys(distances).reduce(
  (acc, k) => (distances[acc] < distances[k] ? acc : k),
  JOURNAL_REGIONS.ohio
);
const base =
  process.env.NODE_ENV === 'development'
    ? JOURNAL_URL
    : `${window.location.protocol}//${JOURNAL_SUBDOMAIN}${JOURNAL_REGIONS[nearest]}.${window.location.host}`;

export default {
  base,
  api: `${base}/api`,
  auth: `${base}/api/auth`,
};
