/* eslint-disable */ // TODO: remove this and fix the excessively deep type instantiation issue
import React, {
  ReactElement,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import { CONDITIONS } from '~/providers/ThemeProvider';
import { useAuthContext } from '~/providers/AuthProvider';
import COLORS from '~/constants/colors';
import TextInput from '~/components/TextInput';
import Subtext from '~/components/Subtext';
import Button from '~/components/Button';
import Gap from '~/components/Gap';
import { getJournalFormErrorHandler } from '~/utils';
import Announcement from '~/components/Announcement';
import Header from '~/components/Header';
import Divider from '~/components/Divider';
import Link from '~/components/Link';
import Code from '~/components/Code';
import Modal from '~/components/Modal';
import { FONT_SIZES } from '~/constants/typography';

import { DEFAULT_DEMO_INFO } from '../constants/defaults';
import CONTENT from '../constants/content';
import { useBeatboxContext } from '../providers/BeatboxProvider';
import uris from '../uris';
import {
  hasPermission,
  infoFromContent,
  getRelationOptions,
  isYouTubeLink,
  isInstagramLink,
} from '../utils';
import OptionSelect from './OptionSelect';
import CurrentList from './CurrentList';

function DemoForm(): ReactElement {
  const [fail, setFail] = useState<string>();
  const [showDeleter, setShowDeleter] = useState(false);
  const { state: authState } = useAuthContext();
  const {
    state: beatboxState,
    listTechniques,
    addDemo,
    setDemo,
    listDemos,
    editDemo,
    deleteDemo,
  } = useBeatboxContext();
  const demoId = Number(useParams().demoId);
  const [searchParams] = useSearchParams();
  const techniqueId = Number(searchParams.get('techniqueId'));
  const page = Number(searchParams.get('page')) || 1;

  // Form
  const defaultValues =
    (demoId && infoFromContent(beatboxState.demo, DEFAULT_DEMO_INFO)) ||
    DEFAULT_DEMO_INFO;
  const {
    register,
    control,
    handleSubmit,
    setError,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues });
  const youtubeOpts = {
    validate: (value) =>
      isYouTubeLink(value) ||
      (value === '' && isInstagramLink(getValues('instagram'))),
  };
  const instagramOpts = {
    validate: (value) =>
      isInstagramLink(value) ||
      (value === '' && isYouTubeLink(getValues('youtube'))),
  };
  const relationRules = {
    validate: (list) =>
      Array.isArray(list) &&
      list.map((e) => Number.isInteger(e)).reduce((acc, e) => acc && e, true),
  };

  if (techniqueId && !defaultValues.techniques.includes(techniqueId))
    defaultValues.techniques = [...defaultValues.techniques, techniqueId];

  // Rendering
  const primaryButtonText = demoId ? 'Update' : 'Submit';
  const primaryButtonLoadingText = demoId ? 'Changing...' : 'Creating...';
  const deleteButtonText = 'Delete';
  const deleteButtonLoadingText = 'Removing...';
  const resetButtonText = demoId ? 'Reset' : 'Clear';

  // Handlers
  const navigate = useNavigate();
  const onSubmit = (demoInfo) => {
    return new Promise<void>((resolve) => {
      setFail(undefined);
      (demoId ? editDemo(demoId, demoInfo) : addDemo(demoInfo))
        .then(async (res) => {
          navigate(uris.exploreDemo(demoId || res));
        })
        .catch(
          getJournalFormErrorHandler(
            DEFAULT_DEMO_INFO,
            setError,
            setFail,
            resolve
          )
        );
    });
  };
  const onDelete = () => {
    return new Promise<void>(() => {
      setFail(undefined);
      deleteDemo(demoId).then(() => {
        setShowDeleter(false);
        navigate(uris.manageDemos);
      });
    });
  };
  const handleReset = () => reset();
  const handleShowDeleter = () => setShowDeleter(true);

  // Demo form styling
  const textInputStyle = {
    width: 'min(320px, 75vw)',
  };
  const selectStyle = {
    width: 'min(335px, 80vw)',
  };
  const primaryButtonStyle = {
    width: '9em',
    margin: '0.13em 0.5em 0.13em 0',
  };
  const resetButtonStyle = {
    ...primaryButtonStyle,
    margin: '0.13em 0 0.13em 0',
  };
  const deleteButtonStyle = {
    marginLeft: '2.5%',
  };
  const showDeleteStyle = {
    color: COLORS.mandy,
    cursor: 'pointer',
  };
  const deleterContainerStyle = {
    padding: '0em 0.5em',
  };
  const subheaderStyle = {
    fontSize: FONT_SIZES.action,
  };

  // Set demo for edit
  useLayoutEffect(() => {
    (async () => {
      if (
        hasPermission(authState, CONTENT.demos) &&
        demoId &&
        demoId !== beatboxState.demo?.id
      )
        await setDemo(demoId);
    })();
  }, [demoId]);
  useEffect(() => reset(defaultValues), [beatboxState.demo, demoId]);

  // List demos
  useEffect(() => {
    (async () => {
      if (
        hasPermission(authState, CONTENT.demos) &&
        (!beatboxState.demos ||
          !beatboxState.demo ||
          page !== beatboxState.demos?.meta.pagination.page)
      )
        await listDemos(page);
    })();
  }, [beatboxState.subject, beatboxState.demo, page]);

  // List techniques
  useEffect(() => {
    (async () => {
      if (hasPermission(authState, CONTENT.demos) && !beatboxState.techniques)
        await listTechniques();
    })();
  }, [beatboxState.subject]);

  return (
    <>
      {!!demoId && (
        <>
          Go back to <Link href={uris.manageDemos}>Add Demo</Link> or view in{' '}
          <Link href={uris.exploreDemo(demoId)}>Explore</Link>
        </>
      )}
      <Header>
        {demoId ? 'Edit' : 'Add'} Demo
        {!!demoId && (
          <>
            {' '}
            <Code>{demoId}</Code>
          </>
        )}
      </Header>
      {fail && (
        <>
          <Announcement>{fail}</Announcement>
          <Gap />
        </>
      )}
      <form>
        <div>
          <TextInput
            {...register('youtube', youtubeOpts)}
            placeholder="YouTube link"
            style={textInputStyle}
          />
          {errors.youtube && (
            <Subtext color={COLORS.mandy}>
              {errors.youtube.message || 'Please provide a valid source'}
            </Subtext>
          )}
        </div>
        <Gap />
        <div>
          <TextInput
            {...register('instagram', instagramOpts)}
            placeholder="Instagram link"
            style={textInputStyle}
          />
          {errors.instagram && (
            <Subtext color={COLORS.mandy}>
              {errors.instagram.message || 'Please provide a valid source'}
            </Subtext>
          )}
        </div>
        <Gap />
        <Gap />
        <span style={subheaderStyle}>Which techniques are being shown?</span>
        <Gap />
        <div>
          <OptionSelect
            name="techniques"
            control={control}
            defaultValue={defaultValues.techniques}
            rules={relationRules}
            placeholder="Demonstrated techniques"
            options={getRelationOptions(beatboxState.techniques, 'name')}
            style={selectStyle}
          />
          {errors.techniques && (
            <Subtext color={COLORS.mandy}>
              {errors.techniques.message ||
                'Only existing techniques from selection allowed'}
            </Subtext>
          )}
        </div>
        <Gap />
        <Button
          label={primaryButtonText}
          loadingLabel={primaryButtonLoadingText}
          loading={isSubmitting}
          onMouseUp={handleSubmit(onSubmit)}
          onTouchEnd={handleSubmit(onSubmit)}
          style={primaryButtonStyle}
        />
        <Button
          label={resetButtonText}
          condition={CONDITIONS.secondary}
          onMouseUp={handleReset}
          onTouchEnd={handleReset}
          style={resetButtonStyle}
        />
        {!!demoId && (
          <>
            <Gap />
            <Gap />
            Actually, I&apos;d like to{' '}
            <b
              role="none"
              onMouseUp={handleShowDeleter}
              onTouchEnd={handleShowDeleter}
              style={showDeleteStyle}
            >
              delete
            </b>{' '}
            this demo
          </>
        )}
      </form>
      {!!demoId && (
        <Modal
          visible={showDeleter}
          header="Are you sure?"
          callback={() => setShowDeleter(false)}
        >
          <div style={deleterContainerStyle}>
            Deleting demo <Code>{demoId}</Code> is permanent. Please check
            before proceeding.
          </div>
          <br />
          <Button
            label="Cancel"
            callback={() => setShowDeleter(false)}
            width="48.75%"
            condition={CONDITIONS.secondary}
          />
          <Button
            label={deleteButtonText}
            loadingLabel={deleteButtonLoadingText}
            loading={isSubmitting}
            onMouseUp={handleSubmit(onDelete)}
            onTouchEnd={handleSubmit(onDelete)}
            width="48.75%"
            condition={CONDITIONS.fail}
            style={deleteButtonStyle}
          />
        </Modal>
      )}
      <Divider />
      <CurrentList plural={CONTENT.demos} />
    </>
  );
}

export default DemoForm;
