import React, { ReactElement } from 'react';

import Header from '~/components/Header';
import Subheader from '~/components/Subheader';
import Divider from '~/components/Divider';
import Link from '~/components/Link';
import socialUrls from '~/urls/social';
import COLORS from '~/constants/colors';
import Break from '~/components/Break';

import Container from '../../components/Container';
import Feature from './components/Feature';
import imgur from './assets/imgur.png';
import voiceMemos from './assets/voice-memos.png';
import vocaroo from './assets/vocaroo.png';

function About(): ReactElement {
  // About styling
  const headerStyle = {
    margin: 0,
  };
  const topicStyle = {
    color: COLORS.cornflower,
  };
  const wrapperStyle = {
    textAlign: 'center' as const,
  };
  const containerStyle = {
    display: 'inline-block',
    textAlign: 'left' as const,
  };

  return (
    <Container>
      <Header style={headerStyle}>
        What&apos;s all this really <b style={topicStyle}>about</b>?
      </Header>
      <Divider />
      <Subheader>
        Beatbox Share is a platform for conveniently recording and sharing
        beatbox with the best quality possible.
      </Subheader>
      <Break />
      <div style={wrapperStyle}>
        <div style={containerStyle}>
          <Feature
            src={imgur}
            name="Imgur"
            caption="but for beatbox"
            text="Record or upload clips from anywhere"
          />
          <Break />
          <Feature
            src={vocaroo}
            name="Vocaroo"
            caption="but better sound quality"
            text="Apply mastering tuned for beatboxing"
          />
          <Break />
          <Feature
            src={voiceMemos}
            name="Voice Memos"
            caption="but shareable"
            text="Easily share beats with a custom link"
          />
        </div>
      </div>
      <Break />
      <Subheader>
        Launched in June of 2023 by{' '}
        <Link href={socialUrls.kindredYouTube}>Kindred</Link> and{' '}
        <Link href={socialUrls.kalapuikkoInstagram}>Kalapuikko</Link> to make
        audio production free and accessible to everyone.
      </Subheader>
    </Container>
  );
}

export default About;
