import React, {
  ReactElement,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  Dispatch,
  SetStateAction,
} from 'react';

import COLORS from '~/constants/colors';
import Announcement from '~/components/Announcement';
import Header from '~/components/Header';
import Subheader from '~/components/Subheader';
import Divider from '~/components/Divider';
import TextButton from '~/components/TextButton';
import Gap from '~/components/Gap';
import Break from '~/components/Break';

import Settings from '../components/Settings';
import Microphone from '../devices/Microphone';
import Container from '../components/Container';
import AudioPlayer from '../components/AudioPlayer';
import MediaControls from '../components/MediaControls';
import ActionPanel from '../components/ActionPanel';
import TimerBar from '../components/TimerBar';
import { DeviceState, ListenControls } from '../models';
import { getAudioConstraints } from '../utils';
import { AUDIO_TIME_LIMIT } from '../constants/media';

interface RecordProps {
  setListenControls: Dispatch<SetStateAction<ListenControls>>;
  automaticStart: boolean;
  setAutomaticStart: Dispatch<SetStateAction<boolean>>;
}

function Record({
  setListenControls,
  automaticStart,
  setAutomaticStart,
}: RecordProps): ReactElement {
  const [microphoneState, setMicrophoneState] = useState<DeviceState>('closed');
  const [showSettings, setShowSettings] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [config, setConfig] = useState<Record<string, any>>({
    audio: getAudioConstraints(),
  });
  const [cookedUrl, setCookedUrl] = useState('');
  const [cookedAudio, setCookedAudio] = useState<Blob>();
  const [rawAudioBuffer, setRawAudioBuffer] = useState<AudioBuffer>();
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(false);
  const microphoneRef = useRef<Microphone>();

  // Handlers
  const handleShowSettings = () => setShowSettings(true);
  const cookedCallback = (blob) => {
    setCookedUrl(URL.createObjectURL(blob));
    setCookedAudio(blob);
  };
  const rawCallback = (buffer) => setRawAudioBuffer(buffer);

  // Controls
  const stopRecording = async () => {
    if (
      microphoneRef.current?.state === 'running' ||
      microphoneRef.current?.state === 'suspended'
    ) {
      await microphoneRef.current.stop();
      setMicrophoneState(microphoneRef.current.state);
    }
  };
  const startRecording = async () => {
    setLoading(true);

    // Initialize microphone and get permission
    if (!microphoneRef.current) {
      const microphone = new Microphone({
        cookedCallback,
        rawCallback,
        durationCallback: setDuration,
        maxDuration: AUDIO_TIME_LIMIT,
        maxDurationCallback: stopRecording,
        ...config,
      });

      microphoneRef.current = microphone;
    }

    // Begin capturing audio
    setShowSettings(false);
    setErrorText('');
    setDuration(0);
    try {
      await microphoneRef.current.start();
      setLoading(false);
      setMicrophoneState(microphoneRef.current.state);

      // Clear old recordings after start to prevent showing normal start button after clicking redo
      setCookedUrl('');
      setCookedAudio(undefined);
      setRawAudioBuffer(undefined);
    } catch (error) {
      setErrorText((error as Error).message);
      setLoading(false);
    }
  };
  const pauseRecording = async () => {
    if (microphoneRef.current?.state === 'running') {
      await microphoneRef.current.pause();
      setMicrophoneState(microphoneRef.current.state);
    }
  };
  const resumeRecording = async () => {
    if (microphoneRef.current?.state === 'suspended') {
      await microphoneRef.current.resume();
      setMicrophoneState(microphoneRef.current.state);
    }
  };

  // Record styling
  const headerStyle = {
    margin: 0,
  };
  const timeLimitStyle = {
    color: COLORS.yellowGreen,
  };

  useEffect(() => microphoneRef.current?.updateConfig(config), [config]);
  useEffect(() => {
    if (cookedAudio) setCookedUrl(URL.createObjectURL(cookedAudio));
  }, [cookedAudio]);
  useLayoutEffect(() => {
    (async () => {
      if (automaticStart) {
        setAutomaticStart(false);
        await startRecording();
      }
    })();
  }, [automaticStart]);

  // Unmount
  useEffect(
    () => () => {
      (async () => {
        await microphoneRef.current?.reset();
      })();
    },
    []
  );

  return (
    <>
      <Container>
        {errorText && (
          <>
            <Announcement>{errorText}</Announcement>
            <Break />
          </>
        )}
        <Header style={headerStyle}>
          You&apos;ve got up to <b style={timeLimitStyle}>3 minutes</b>!
        </Header>
        <Divider />
        {(microphoneState === 'running' || microphoneState === 'suspended') && (
          <TimerBar duration={duration} maxDuration={AUDIO_TIME_LIMIT} />
        )}
        {(microphoneState === 'closed' || microphoneState === 'done') && (
          <Subheader>
            Click <TextButton callback={handleShowSettings}>here</TextButton> to
            customize your audio settings
          </Subheader>
        )}
      </Container>
      <Break />
      <Break />
      {cookedUrl && cookedAudio && rawAudioBuffer && (
        <div>
          <AudioPlayer url={cookedUrl} />
          <Gap />
          <ActionPanel
            cookedUrl={cookedUrl}
            cookedMedia={cookedAudio}
            setCookedMedia={setCookedAudio}
            setErrorText={setErrorText}
            config={config}
            rawAudioBuffer={rawAudioBuffer}
            show="audio"
            setListenControls={setListenControls}
          />
          <Break />
        </div>
      )}
      <MediaControls
        deviceState={microphoneState}
        onStop={stopRecording}
        onStart={startRecording}
        onPause={pauseRecording}
        onResume={resumeRecording}
        loading={loading}
      />
      <Settings
        visible={showSettings}
        setVisible={setShowSettings}
        config={config}
        setConfig={setConfig}
      />
    </>
  );
}

export default Record;
