import React, { ReactElement, Dispatch, SetStateAction } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import FramedView from '~/components/FramedView';
import Announcement from '~/components/Announcement';
import Button from '~/components/Button';
import Title from '~/components/Title';
import { CONDITIONS } from '~/providers/ThemeProvider';

interface NoLoadProps {
  setPhase: Dispatch<SetStateAction<string>>;
  prevPhase: string;
}

function NoLoad({ setPhase, prevPhase }: NoLoadProps): ReactElement {
  const handleGoBack = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setPhase(prevPhase);
  };

  // No load styling
  const noLoadStyle = {
    width: 'min(45em, 82.5vw)',
  };
  const frameColors: [string, string] = [
    COLORS.waikawaGray,
    COLORS.waikawaGray,
  ];
  const buttonStyle = {
    width: '9em',
    margin: '0 0.25em 0.38em',
    boxShadow: `-0.13em 0.13em ${COLORS.black25}`,
    borderStyle: 'none',
    fontSize: FONT_SIZES.text,
  };

  return (
    <FramedView accent colors={frameColors} style={noLoadStyle}>
      <Title>Frozen Yogurt</Title>
      <br />
      Oof, looks like your connection is too weak, this song isn&apos;t
      available on your device, or the uploader has blocked you and the game got
      stuck trying to load it. Please use the preview button to check if you
      have access and can even view your selection properly.
      <br />
      <br />
      <Announcement header="Note" condition={CONDITIONS.warning}>
        It&apos;s possible the issue is caused by a slow browser and waiting a
        bit before starting the game can give enough time for the code to run.
        While not common, I&apos;ve seen this sometimes when the device is too
        old with recent versions of Safari that behave less stably.
      </Announcement>
      <br />
      <Button
        label="Go Back"
        onClick={handleGoBack}
        onTouchEnd={handleGoBack}
        style={buttonStyle}
      />
    </FramedView>
  );
}

export default NoLoad;
