import axios from 'axios';

import authUrls from '~/urls/auth';
import { getStorageItem, setStorageItem, removeStorageItem } from '~/services';

import {
  User,
  LoginInfo,
  SignupInfo,
  SettingsInfo,
  PasswordInfo,
  FreshAuth,
} from './models';
import { STORAGE_KEYS } from './constants';

const config = { withCredentials: true };

export const revokeRefresh = async (): Promise<void> => {
  const refreshKey = getStorageItem(STORAGE_KEYS.refreshKey);

  if (refreshKey) {
    await axios.post(authUrls.revoke, { key: refreshKey }, config);
    removeStorageItem(STORAGE_KEYS.refreshKey);
  }
};

export const refreshAuth = async (): Promise<FreshAuth> => {
  const refreshKey = getStorageItem(STORAGE_KEYS.refreshKey);
  let response;

  if (refreshKey) {
    response = await axios.post(authUrls.refresh, { key: refreshKey }, config);
    setStorageItem(STORAGE_KEYS.refreshKey, response.data.refreshKey); // Should also synchronize refresh
  }

  return response?.data;
};

export const logIn = async (loginInfo: LoginInfo): Promise<FreshAuth> => {
  const response = await axios.post(authUrls.logIn, loginInfo, config);

  setStorageItem(STORAGE_KEYS.refreshKey, response.data.refreshKey); // Should also synchronize refresh

  return response.data;
};

export const changePassword = async (
  passwordInfo: PasswordInfo
): Promise<void> => {
  await axios.post(
    authUrls.changePassword,
    {
      ...passwordInfo,
      passwordConfirmation: passwordInfo.password,
    },
    config
  );
};

export const createUser = async (signupInfo: SignupInfo): Promise<void> => {
  await axios.post(authUrls.register, signupInfo);
};

export const updateMe = async (settingsInfo: SettingsInfo): Promise<User> => {
  const response = await axios.put(authUrls.me, settingsInfo, config);

  return response.data.user;
};

export const deleteMe = async (): Promise<void> => {
  await axios.delete(authUrls.me, config);
};
