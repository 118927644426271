import React, { ReactElement, CSSProperties } from 'react';

import COLORS from '~/constants/colors';

interface ProgressBarProps {
  points: number;
  total: number;
  width?: string;
  height?: string;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  loadedStyle?: CSSProperties;
  pointsStyle?: CSSProperties;
}

function ProgressBar({
  points,
  total,
  width = '85%',
  height = '1.5em',
  style,
  containerStyle,
  loadedStyle,
  pointsStyle,
}: ProgressBarProps): ReactElement {
  const loadedWidth = `${Number((100 * points) / total).toFixed(2)}%`;
  const borderRadius = '0.3em';

  // Progress bar styling
  const progressBarStyle = {
    display: 'flex',
    justifyContent: 'center',
    ...style,
  };
  const finalContainerStyle = {
    width,
    height,
    lineHeight: height,
    backgroundColor: COLORS.black50,
    borderRadius,
    overflow: 'hidden',
    textAlign: 'right' as const,
    ...containerStyle,
  };
  const finalLoadedStyle = {
    ...finalContainerStyle,
    width: loadedWidth,
    backgroundColor: COLORS.salem,
    borderRadius: 0,
    ...loadedStyle,
  };
  const finalPointsStyle = {
    margin: `-${height} ${borderRadius} 0 0`,
    color: COLORS.pastelGreen,
    ...pointsStyle,
  };

  return (
    <div style={progressBarStyle}>
      <div style={finalContainerStyle}>
        <div style={finalLoadedStyle} />
        <p style={finalPointsStyle}>
          <b>{points}</b>
        </p>
      </div>
    </div>
  );
}

export default ProgressBar;
