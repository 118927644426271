import React, { useState, useEffect } from 'react';

import { stopEventEffects } from '~/utils';
import Modal from '~/components/Modal';
import TextInput from '~/components/TextInput';
import COLORS from '~/constants/colors';
import { FONTS, FONT_WEIGHTS } from '~/constants/typography';
import Break from '~/components/Break';
import Gap from '~/components/Gap';

import { Nodes } from '../../../models';
import { MARKS, ERRORS } from '../../../constants/language';
import { MISSING } from '../../../constants/defaults';
import { getPlaySound } from '../../../utils';

interface SearchProps {
  nodes?: Nodes;
}

function Search({ nodes }: SearchProps) {
  const [visible, setVisible] = useState(false);
  const [matches, setMatches] = useState<Nodes>();
  const getResultKey = (idx) => `BeatbloxWriteSearchResult${idx}`;

  useEffect(() => {
    if (nodes) setMatches(nodes);
  }, [nodes]);

  // Handlers
  const showModal = (event) => {
    stopEventEffects(event);
    setVisible(true);
  };
  const hideModal = () => setVisible(false);
  const searchNodes = (event) => {
    const query = event.target.value;
    let newMatches;

    stopEventEffects(event);
    if (!query) newMatches = nodes;
    else {
      newMatches = nodes
        ?.map((n) => {
          let score = 0;

          // TODO: improve match ranking method
          if (n.notation.includes(query))
            score += (4 * query.length) / n.notation.length;
          else if (n.notation.toLowerCase().includes(query.toLowerCase()))
            score += (3 * query.length) / n.notation.length;
          else if (n.name.includes(query))
            score += (2 * query.length) / n.name.length;
          else if (n.name.toLowerCase().includes(query.toLowerCase()))
            score += query.length / n.name.length;

          return [n, score];
        })
        .filter((p) => p[1])
        .sort((a, b) => Number(b[1]) - Number(a[1]))
        .map((p) => p[0]);
    }
    if (newMatches) setMatches(newMatches);
  };

  // Styling
  const modalStyle = {
    width: 'min(95vw, 30em)',
  };
  const inputStyle = {
    width: 'calc(100% - 1em)',
  };
  const loadingStyle = {
    marginBottom: '1em',
  };
  const resultStyle = {
    display: 'inline-block',
    margin: '0 0.1em 0.1em 0',
    padding: '0.3em',
    border: `thin solid ${COLORS.gray}`,
    borderRadius: '0.4em',
    color: COLORS.tundora,
    fontFamily: FONTS.typewriter,
    cursor: 'pointer',
  };
  const buttonStyle = {
    ...resultStyle,
    margin: '0.05em',
  };
  const errorStyle = {
    ...resultStyle,
    color: COLORS.white,
    border: `thin solid ${COLORS.mandy}`,
    backgroundColor: COLORS.mandy,
  };
  const notationStyle = {
    padding: '0 0.2em',
    color: COLORS.tundora,
    backgroundColor: COLORS.alto,
    borderRadius: '0.2em',
    fontWeight: FONT_WEIGHTS.bold,
  };
  const missingStyle = {
    ...notationStyle,
    backgroundColor: COLORS.cinderella,
  };

  return (
    <>
      <div
        role="button"
        tabIndex={-1}
        onMouseUp={showModal}
        onTouchEnd={showModal}
        style={buttonStyle}
      >
        <span style={notationStyle}>B</span> sounds
      </div>
      <Modal
        visible={visible}
        header="Current Sounds"
        callback={hideModal}
        style={modalStyle}
      >
        <TextInput
          disabled={!nodes}
          placeholder="Search"
          onChange={searchNodes}
          style={inputStyle}
        />
        <Break />
        <div>
          {!matches && (
            <>
              <span style={loadingStyle}>Loading, please wait...</span>
              <Gap />
            </>
          )}
          {matches &&
            matches.length > 0 &&
            matches.map((n, idx) => (
              <div
                key={getResultKey(idx)}
                role="button"
                tabIndex={-1}
                onMouseUp={getPlaySound(n.sample.recording.url)}
                onTouchEnd={getPlaySound(n.sample.recording.url)}
                style={resultStyle}
              >
                <span style={notationStyle}>{n.notation}</span> {n.name}
              </div>
            ))}
          {matches && matches.length === 0 && (
            <div
              role="button"
              tabIndex={-1}
              onMouseUp={getPlaySound(MISSING.sample.recording.url)}
              onTouchEnd={getPlaySound(MISSING.sample.recording.url)}
              style={errorStyle}
            >
              <span style={missingStyle}>{MARKS.missing}</span>{' '}
              {ERRORS.notation}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default Search;
