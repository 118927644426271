import React, { ReactElement, PropsWithChildren, CSSProperties } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';
import { useThemeContext, CONDITIONS } from '~/providers/ThemeProvider';

interface AnnouncementProps {
  condition?: string;
  color?: string;
  header?: string;
  style?: CSSProperties;
  headerStyle?: CSSProperties;
}

function Announcement({
  condition = CONDITIONS.fail,
  color = COLORS.mandy,
  header,
  style,
  headerStyle,
  children,
}: PropsWithChildren<AnnouncementProps>): ReactElement {
  const { theme } = useThemeContext().state;
  const finalColor = theme[condition]?.default.backgroundColor || color;

  // Announcement styling
  const announcementStyle = {
    padding: '1em',
    color: COLORS.black,
    backgroundColor: `${finalColor}88`,
    borderColor: finalColor,
    borderWidth: '0 0 0 0.5em',
    borderStyle: 'solid',
    fontSize: FONT_SIZES.text,
    fontWeight: FONT_WEIGHTS.normal,
    textAlign: 'left' as const,
    ...style,
  };
  const totalHeaderStyle = {
    margin: '0 0 0.5em',
    color: COLORS.black,
    fontSize: FONT_SIZES.action,
    fontWeight: FONT_WEIGHTS.bold,
    ...headerStyle,
  };

  return (
    <div style={announcementStyle}>
      {header && <h2 style={totalHeaderStyle}>{header}</h2>}
      {children}
    </div>
  );
}

export default Announcement;
