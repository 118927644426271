import React, {
  Dispatch,
  SetStateAction,
  MutableRefObject,
  ReactElement,
  useState,
  useEffect,
} from 'react';
import YouTube from 'react-youtube';
import type YouTubePlayer from 'youtube-player';
import GameEngine from 'react-game-engine';

import PHASES from '../constants/phases';
import { EVENTS } from '../constants/events';
import { getWindowDims } from '../utils';

interface VideoBackgroundProps {
  phase: string;
  setPhase: Dispatch<SetStateAction<string>>;
  prevPhase: string;
  setPrevPhase: Dispatch<SetStateAction<string>>;
  setOpacity: Dispatch<SetStateAction<number>>;
  setVideoPlayer: Dispatch<SetStateAction<YouTubePlayer>>;
  setPlayerState: Dispatch<SetStateAction<number>>;
  gameEngineRef: MutableRefObject<GameEngine>;
}

function VideoBackground({
  phase,
  setPhase,
  prevPhase,
  setPrevPhase,
  setOpacity,
  setVideoPlayer,
  setPlayerState,
  gameEngineRef,
}: VideoBackgroundProps): ReactElement {
  const [youTubePlayer, setYouTubePlayer] = useState<YouTubePlayer>();
  const windowDims = getWindowDims();

  // YouTube parameters and styling
  const opts = {
    width: String(windowDims.w),
    height: String(windowDims.h),
    frameBorder: 0,
    playerVars: {
      controls: 0 as const,
      disablekb: 1 as const,
      modestbranding: 1 as const,
      playsinline: 1 as const,
      iv_load_policy: 3 as const,
    },
  };
  const videoBackgroundStyle = {
    position: 'absolute' as const,
    top: 0,
    left: '50%',
    marginLeft: -windowDims.w / 2,
    width: windowDims.w,
    height: windowDims.h,
    overflow: 'hidden', // Needed to remove white strip at bottom
    pointerEvents: 'none' as const,
  };

  // Control callbacks
  const onReady = (event) => {
    setYouTubePlayer(event.target);
    setVideoPlayer(event.target);
  };
  const onPlay = () => setOpacity(0);
  const onEnd = () => {
    if (phase === PHASES.play) setPhase(PHASES.ending);
    else if (phase === PHASES.record) setPhase(PHASES.production);
    else if (phase === PHASES.review) {
      setPrevPhase(PHASES.review);
      setPhase(PHASES.studio);
    }
  };
  const onStateChange = (event) => {
    gameEngineRef.current?.dispatch(EVENTS.yTPSC(event.data));
    setPlayerState(event.data);
  };

  useEffect(() => {
    if (
      phase.slice(0, 6) === PHASES.ending ||
      phase === PHASES.noLoad ||
      phase === PHASES.production ||
      (phase === PHASES.studio && prevPhase === PHASES.review)
    ) {
      youTubePlayer?.pauseVideo();
      youTubePlayer?.seekTo(0, true);
      setOpacity(1);
    }
  }, [phase]);

  return (
    <div style={videoBackgroundStyle}>
      <YouTube
        title="Video Background"
        opts={opts}
        onReady={onReady}
        onPlay={onPlay}
        onEnd={onEnd}
        onStateChange={onStateChange}
      />
    </div>
  );
}

export default VideoBackground;
