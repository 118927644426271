import React, { ReactElement, forwardRef, ForwardedRef } from 'react';
import ReactSelect, { GroupBase, Props, StylesConfig } from 'react-select';

import { FONT_SIZES } from '~/constants/typography';
import { useThemeContext } from '~/providers/ThemeProvider';

interface SelectProps<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends Props<Option, IsMulti, Group> {
  width?: string;
  blurInputOnSelect?: boolean;
  controlShouldRenderValue?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  styles?: StylesConfig<Option, IsMulti, Group>;
  ref: ForwardedRef<any>;
}

function Select({
  width = '15em',
  blurInputOnSelect = true,
  controlShouldRenderValue = true,
  isClearable = false,
  disabled = false,
  styles,
  ref,
  ...props
}: SelectProps): ReactElement {
  const { theme } = useThemeContext().state;
  const baseState = disabled ? theme.disabled.default : theme.neutral.default;
  const selectStyles = {
    ...styles,
    container: (provided, state) => ({
      ...provided,
      display: 'inline-flex',
      verticalAlign: 'top',
      ...styles?.container?.(provided, state),
    }),
    control: (provided, state) => ({
      ...provided,
      width,
      height: '2em',
      minHeight: 'fit-content',
      fontSize: FONT_SIZES.text,
      borderRadius: '0.3em',
      borderStyle: 'solid',
      borderWidth: 'thin',
      padding: '0.2em 0 0.2em 0.4em',
      outline: 'none',
      backgroundClip: 'border-box',
      boxShadow: 0,

      // From theme
      color: baseState.color,
      backgroundColor: baseState.backgroundColor,
      borderColor:
        disabled || !state.isFocused
          ? baseState.borderColor
          : state.isFocused && theme.neutral.active?.borderColor,
      '&:hover': !state.isFocused && {
        borderColor: disabled
          ? baseState.borderColor
          : theme.neutral.hover?.borderColor,
      },
      ...styles?.control?.(provided, state),
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      minHeight: '1.7em',
      padding: 0,
      textAlign: 'left' as const,
      ...styles?.valueContainer?.(provided, state),
    }),
    placeholder: (provided, state) => ({
      ...provided,
      whiteSpace: 'nowrap' as const,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      ...styles?.placeholder?.(provided, state),
    }),
    input: (provided, state) => ({
      ...provided,
      margin: 0,
      ...styles?.input?.(provided, state),
    }),
    singleValue: (provided, state) => ({
      ...provided,
      ...styles?.singleValue?.(provided, state),
    }),
    multiValue: (provided, state) => ({
      ...provided,
      margin: '0.1em 0.2em 0.1em 0',
      ...styles?.multiValue?.(provided, state),
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '1.7em',
      ...styles?.indicatorsContainer?.(provided, state),
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      width: '1.8em',
      padding: '0 0.4em',
      ...styles?.clearIndicator?.(provided, state),
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      margin: '0.2em 0',
      ...styles?.indicatorSeparator?.(provided, state),
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      width: '2em',
      margin: '0 -0.1em',
      ...styles?.dropdownIndicator?.(provided, state),
    }),
  };

  return (
    <ReactSelect<unknown, boolean, GroupBase<unknown>>
      blurInputOnSelect={blurInputOnSelect}
      controlShouldRenderValue={controlShouldRenderValue}
      isClearable={isClearable}
      isDisabled={disabled}
      styles={selectStyles}
      ref={ref}
      {...props}
    />
  );
}

export default forwardRef((props: SelectProps, ref: ForwardedRef<any>) =>
  Select({ ...props, ref })
);
