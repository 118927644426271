import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import SwitchBoard, { BlockSwitch } from '~/components/SwitchBoard';
import COLORS from '~/constants/colors';
import { FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';
import { getCurrentPathGetter } from '~/utils';

import PATHS, { BEATBLOX_PATH } from '../constants/paths';

function BrandNav(): ReactElement {
  const navigate = useNavigate();
  const currentPath = getCurrentPathGetter(PATHS, BEATBLOX_PATH, PATHS.write)();
  const paths = [PATHS.write, PATHS.about];
  const labels = ['✎', '?'];
  const choices = paths.map((p) => currentPath.includes(p));
  const callbacks = paths.map((c) => () => navigate(c));

  // Styling
  const brandNavStyle = {
    margin: '2em 0 1em',
    lineHeight: '1.35em',
    fontSize: FONT_SIZES.header,
  };
  const logoStyle = {
    marginRight: '0.3em',
    color: COLORS.tundora,
    fontSize: FONT_SIZES.single,
    fontWeight: FONT_WEIGHTS.bold,
    verticalAlign: 'middle',
    textDecoration: 'none',
  };
  const switchStyle = {
    color: COLORS.doveGray,
    backgroundColor: COLORS.alto,
  };
  const switchActiveStyle = {
    color: COLORS.white,
    backgroundColor: COLORS.doveGray,
  };

  return (
    <div style={brandNavStyle}>
      <a href={BEATBLOX_PATH} style={logoStyle}>
        beatblox
      </a>
      <SwitchBoard
        Switch={BlockSwitch}
        labels={labels}
        choices={choices}
        callbacks={callbacks}
        switchStyle={switchStyle}
        switchActiveStyle={switchActiveStyle}
      />
    </div>
  );
}

export default BrandNav;
