import journalUrls from '~/urls/journal';

export default {
  beatboxer: (handle) => `${journalUrls.api}/beatbox/profiles/${handle}`,
  subjects: `${journalUrls.api}/subjects`,
  subject: (subjectId) => `${journalUrls.api}/subjects/${subjectId}`,
  categories: `${journalUrls.api}/categories`,
  category: (categoryId) => `${journalUrls.api}/categories/${categoryId}`,
  techniques: `${journalUrls.api}/techniques`,
  technique: (techniqueId) => `${journalUrls.api}/techniques/${techniqueId}`,
  demos: `${journalUrls.api}/demos`,
  demo: (demoId) => `${journalUrls.api}/demos/${demoId}`,
  tutorials: `${journalUrls.api}/tutorials`,
  tutorial: (tutorialId) => `${journalUrls.api}/tutorials/${tutorialId}`,
  records: `${journalUrls.api}/records`,
  record: (recordId) => `${journalUrls.api}/records/${recordId}`,
  clips: `${journalUrls.api}/beatbox-clips`,
  clip: (code) => `${journalUrls.api}/beatbox-clips/${code}`,
  clipRecording: (url) =>
    url.startsWith('/') ? `${journalUrls.base}/${url.slice(1)}` : url,
  mux: `${journalUrls.api}/beatbox-clips/mux`,

  // External
  amCharts: 'https://www.amcharts.com',
  bbxInt: 'https://beatboxinternational.com',
  breathe: 'https://youtu.be/2wTDG1EggRg',
};
