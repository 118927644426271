import React, { ReactElement } from 'react';
import axios from 'axios';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import PATHS from '~/constants/paths';
import DOMAINS, { HOST } from '~/constants/domains';
import { STATUS_CODES, GA_MEASUREMENT_ID } from '~/constants/api';
import AuthProvider, { useAuthContext } from '~/providers/AuthProvider';
import ThemeProvider from '~/providers/ThemeProvider';
import { getErrorHandler, addStar } from '~/utils';
import Navigation from '~/components/Navigation';
import ROLES from '~/constants/roles';
import RestrictAccess from '~/components/RestrictAccess';
import Ponder from '~/pages/Ponder';
import KanaMatch from '~/pages/KanaMatch';
import FlowGo from '~/pages/FlowGo';
import BeatboxShare from '~/pages/BeatboxShare';
import BeatboxDb from '~/pages/BeatboxDb';
import Beatblox from '~/pages/Beatblox';
import Register from '~/pages/Register';
import Login from '~/pages/Login';
import Settings from '~/pages/Settings';

// Initialize Google Analytics
if (GA_MEASUREMENT_ID) ReactGA.initialize(GA_MEASUREMENT_ID);

function App(): ReactElement {
  // Send Google Analytics current URL
  ReactGA.pageview();

  // Set Axios interceptors
  // TODO: do these interceptors even work without `await` on auth functions? With `await` it can freeze pages like FlowGo, which uses Axios but doesn't need auth.
  const { loadAuth, logOut, getAccessToken } = useAuthContext();
  const reqHandler = (config) => {
    const accessToken = getAccessToken()?.substring(7);
    const exp = accessToken && jwtDecode<JwtPayload>(accessToken).exp;

    if (exp && Date.now() >= exp * 1000) loadAuth();

    return config;
  };
  const resErrorHandler = (error) => {
    if (
      error.response?.status === STATUS_CODES.forbidden ||
      error.response?.status === STATUS_CODES.unauthorized
    )
      logOut();

    return getErrorHandler('Axios response')(error);
  };

  axios.interceptors.request.use(reqHandler, getErrorHandler('Axios request'));
  axios.interceptors.response.use(undefined, resErrorHandler);

  // Handle domains
  const Root = () =>
    ({
      [DOMAINS.ponder]: <Ponder />,
      [DOMAINS.beatboxShare]: <BeatboxShare />,
      [DOMAINS.beatblox]: <Beatblox />,
      [DOMAINS.beatboxDb]: <BeatboxDb />,
    }[HOST]);

  return (
    <BrowserRouter>
      <Navigation />
      <Routes>
        <Route path="*" element={<Root />} />

        {/* Universal */}
        <Route
          path={PATHS.register}
          element={
            <RestrictAccess role={ROLES.public} redirect={-1}>
              <Register />
            </RestrictAccess>
          }
        />
        <Route
          path={PATHS.login}
          element={
            <RestrictAccess role={ROLES.public} redirect={-1}>
              <Login />
            </RestrictAccess>
          }
        />
        <Route
          path={PATHS.settings}
          element={
            <RestrictAccess>
              <Settings />
            </RestrictAccess>
          }
        />

        {/* Ponder */}
        {HOST === DOMAINS.ponder && (
          <>
            <Route path={PATHS.kanaMatch} element={<KanaMatch />} />
            <Route path={PATHS.flowGo} element={<FlowGo />} />
            <Route
              path={addStar(PATHS.beatboxShare)}
              element={<BeatboxShare />}
            />
            <Route path={addStar(PATHS.beatblox)} element={<Beatblox />} />
            <Route path={addStar(PATHS.beatboxDb)} element={<BeatboxDb />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default () => (
  <AuthProvider>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </AuthProvider>
);
