import COLORS from '~/constants/colors';

import { Theme } from './models';

const light: Theme = {
  neutral: {
    default: {
      color: COLORS.black,
      backgroundColor: COLORS.white,
      borderColor: COLORS.alto,
    },
    hover: {
      color: COLORS.chetwodeBlue,
      borderColor: COLORS.chetwodeBlue,
    },
    active: {
      color: COLORS.indigo,
      borderColor: COLORS.dodgerBlue,
    },
  },
  primary: {
    default: {
      color: COLORS.white,
      backgroundColor: COLORS.dodgerBlue,
      borderColor: COLORS.dodgerBlue,
    },
    active: {
      color: COLORS.alabaster,
      backgroundColor: COLORS.waikawaGray,
      borderColor: COLORS.waikawaGray,
    },
    loading: {
      color: COLORS.gallery,
      backgroundColor: COLORS.waikawaGray,
      borderColor: COLORS.waikawaGray,
    },
  },
  secondary: {
    default: {
      color: COLORS.cornflower,
      backgroundColor: COLORS.white25,
      borderColor: COLORS.cornflower,
    },
  },
  warning: {
    default: {
      color: COLORS.black,
      backgroundColor: COLORS.kournikova,
      borderColor: COLORS.kournikova,
    },
  },
  fail: {
    default: {
      color: COLORS.white,
      backgroundColor: COLORS.mandy,
      borderColor: COLORS.mandy,
    },
  },
  success: {
    default: {
      color: COLORS.white,
      backgroundColor: COLORS.wildWillow,
      borderColor: COLORS.wildWillow,
    },
  },
  disabled: {
    default: {
      color: COLORS.silverChalice,
      backgroundColor: COLORS.alabaster,
      borderColor: COLORS.alto,
    },
  },
};

export const CONDITIONS = {
  neutral: 'neutral',
  primary: 'primary',
  secondary: 'secondary',
  warning: 'warning',
  fail: 'fail',
  success: 'success',
  disabled: 'disabled',
};

export const THEMES = {
  light,
};
