import React, {
  useReducer,
  useMemo,
  PropsWithChildren,
  ReactElement,
  useLayoutEffect,
} from 'react';

import { createSafeContext } from '~/utils';

import { AuthContext, AuthState } from './models';
import {
  loadAuthThunk,
  logInThunk,
  signUpThunk,
  logOutThunk,
  updateSettingsThunk,
  deleteAccountThunk,
} from './actions';
import { changePassword } from './services';
import reducer, { INITIAL_STATE } from './reducer';
import { getAxiosAuthHeader, isPreviouslyAuthed } from './utils';

const [useAuthContext, AuthContextProvider] = createSafeContext<AuthContext>();

type AuthProviderProps = Record<never, never>;

function AuthProvider({
  children,
}: PropsWithChildren<AuthProviderProps>): ReactElement {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  // Connect thunks
  const logOut = logOutThunk(dispatch);
  const loadAuth = loadAuthThunk(dispatch);
  const logIn = logInThunk(dispatch);
  const signUp = signUpThunk(dispatch);
  const getAccessToken = getAxiosAuthHeader;
  const updateSettings = updateSettingsThunk(dispatch);
  const deleteAccount = deleteAccountThunk(dispatch);

  // Set up value
  const value = useMemo(() => {
    return {
      state: state as AuthState,
      logOut,
      loadAuth,
      logIn,
      signUp,
      getAccessToken,
      updateSettings,
      changePassword,
      deleteAccount,
    };
  }, [state]);

  // Refresh access if previously authed
  useLayoutEffect(() => {
    if (isPreviouslyAuthed()) loadAuth();
  });

  // TODO: add `useEffect` with `addEventListener` for log out, log in, and refresh sync. Check https://hasura.io/blog/best-practices-of-using-jwt-with-graphql/ for an example of how they did this.

  return <AuthContextProvider value={value}>{children}</AuthContextProvider>;
}

export default AuthProvider;
export { useAuthContext };
