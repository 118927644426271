import React from 'react';

import NightSky from '~/components/NightSky';
import Brand from '~/components/Brand';
import { FONT_SIZES } from '~/constants/typography';

import LoginForm from './components/LoginForm';

function Login() {
  const loginStyle = {
    width: '100%',
    fontSize: FONT_SIZES.text,
    textAlign: 'center' as const,
  };
  const containerStyle = {
    display: 'flex',
    minWidth: 350,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  };

  return (
    <div style={loginStyle}>
      <NightSky>
        <div style={containerStyle}>
          <Brand topic="Login" />
          <LoginForm />
        </div>
      </NightSky>
    </div>
  );
}

export default Login;
