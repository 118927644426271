import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import SwitchBoard, { TextSwitch } from '~/components/SwitchBoard';
import { AuthState } from '~/providers/AuthProvider';

import {
  getCurrentPath,
  capitalizeFirstLetter,
  getPermitted,
  hasRoleAccess,
} from '../utils';
import PATHS from '../constants/paths';

interface PhaseSwitchBoardProps {
  authState: AuthState;
  pagePath: string;
  phasePathsDict: Record<string, string>;
}

function PhaseSwitchBoard({
  authState,
  pagePath,
  phasePathsDict,
}: PhaseSwitchBoardProps): ReactElement {
  const navigate = useNavigate();
  const currentPath = getCurrentPath();
  const relPhasePathsDict = Object.keys(phasePathsDict).reduce(
    (acc, p) => ({ ...acc, [p]: phasePathsDict[p].slice(1) }),
    {} as Record<string, string>
  );
  const phases = Object.keys(relPhasePathsDict);
  const paths = Object.values(relPhasePathsDict);
  const labels = phases.map((p) => capitalizeFirstLetter(p));
  const choices = paths.map((p) => currentPath.includes(p));
  const callbacks = paths.map((p) => () => navigate(p));

  // Handle page path
  if (pagePath === PATHS.manage) {
    const permitted = getPermitted(authState).filter((c) => phasePathsDict[c]);
    const permittedIdx = phases.indexOf(permitted[0]);

    if (permittedIdx >= 0)
      choices[permittedIdx] = choices[permittedIdx] || currentPath === pagePath;
  } else if (
    (pagePath === PATHS.bounties && hasRoleAccess(authState)) ||
    pagePath === PATHS.records
  )
    choices[0] = choices[0] || currentPath === pagePath;

  // Phase switch board styling
  const phaseSwitchBoardStyle = {
    textAlign: 'center' as const,
  };

  return (
    <div style={phaseSwitchBoardStyle}>
      <SwitchBoard
        Switch={TextSwitch}
        labels={labels}
        choices={choices}
        callbacks={callbacks}
      />
    </div>
  );
}

export default PhaseSwitchBoard;
