import React, {
  DetailedHTMLProps,
  TextareaHTMLAttributes,
  CSSProperties,
  ReactElement,
  useState,
  useEffect,
  forwardRef,
  ForwardedRef,
  FocusEvent,
  MouseEvent,
} from 'react';

import { FONT_SIZES } from '~/constants/typography';
import { useThemeContext, CONDITIONS } from '~/providers/ThemeProvider';

interface TextAreaProps
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  condition?: string;
  onMouseOver?: (event: MouseEvent) => void;
  onFocus?: (event: FocusEvent) => void;
  onBlur?: (event: FocusEvent) => void;
  disabled?: boolean;
  style?: CSSProperties;
}

function TextArea({
  condition = CONDITIONS.neutral,
  onMouseOver,
  onFocus,
  onBlur,
  disabled = false,
  style,
  ...props
}: TextAreaProps): ReactElement {
  const { theme } = useThemeContext().state;
  const textAreaState = disabled
    ? theme.disabled.default
    : theme[condition].default;
  const disabledProps = disabled && {
    disabled,
    placeholder: props.placeholder ?? 'Input disabled',
  };
  const baseState = disabled ? theme.disabled.default : theme.neutral.default;
  const getStyle = (state) => ({
    display: 'inline-block',
    fontSize: FONT_SIZES.text,
    borderRadius: '0.3em',
    borderStyle: 'solid',
    borderWidth: 'thin',
    padding: '0.4em 0.4em',
    outline: 'none',
    backgroundClip: 'padding-box',
    // From theme
    color: baseState.color,
    backgroundColor: baseState.backgroundColor,
    borderColor: state.borderColor ?? baseState.borderColor,
    ...style,
  });
  const [textAreaStyle, setTextAreaStyle] = useState(getStyle(textAreaState));

  // Event handlers
  const [focused, setFocused] = useState(false);
  const handleMouseOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!disabled) {
      onMouseOver?.(event);
      if (!focused && theme[condition].hover)
        setTextAreaStyle(getStyle(theme[condition].hover));
    }
  };
  const handleMouseLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!disabled && !focused)
      setTextAreaStyle(getStyle(theme[condition].default));
  };
  const handleFocus = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!disabled) {
      setFocused(true);
      onFocus?.(event);
      if (theme[condition].active)
        setTextAreaStyle(getStyle(theme[condition].active));
    }
  };
  const handleBlur = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!disabled) {
      setFocused(false);
      onBlur?.(event);
      setTextAreaStyle(getStyle(theme[condition].default));
    }
  };

  useEffect(
    () => setTextAreaStyle(getStyle(textAreaState)),
    [theme, condition, disabled]
  );

  return (
    <textarea
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={textAreaStyle}
      {...disabledProps}
      {...props}
    />
  );
}

export default forwardRef(
  (props: TextAreaProps, ref: ForwardedRef<HTMLTextAreaElement>) =>
    TextArea({ ...props, ref })
);
