import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';

interface NoContentItemsProps {
  plural?: string;
}

function NoContentItems({
  plural = 'entries',
}: NoContentItemsProps): ReactElement {
  // No content items styling
  const noContentItemsStyle = {
    borderTop: '1px dashed',
    borderColor: COLORS.gallery,
    textAlign: 'center' as const,
  };
  const messageStyle = {
    color: COLORS.platinum,
    fontSize: FONT_SIZES.action,
    fontWeight: FONT_WEIGHTS.bold,
    margin: '1.25em 0 0.75em 0',
  };

  return (
    <div style={noContentItemsStyle}>
      <p style={messageStyle}>No {plural} found</p>
    </div>
  );
}

export default NoContentItems;
