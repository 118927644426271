import { ActionDispatch, ActionCreator } from '~/models';
import { setStorageItem } from '~/services';

import {
  User,
  LoadAuth,
  LogIn,
  SignUp,
  LogOut,
  UpdateSettings,
  DeleteAccount,
  LoginInfo,
  SignupInfo,
  SettingsInfo,
} from './models';
import { setAxiosAuthHeader } from './utils';
import { STORAGE_KEYS } from './constants';
import {
  revokeRefresh,
  refreshAuth,
  logIn,
  createUser,
  updateMe,
  deleteMe,
} from './services';

export const ACTIONS = {
  logIn: 'auth/logIn',
  logOut: 'auth/logOut',
  updateSettings: 'auth/updateSettings',
};

// Creators
const logInCreator: ActionCreator<User> = (user) => ({
  type: ACTIONS.logIn,
  payload: user,
});
const logOutCreator: ActionCreator<string> = () => ({
  type: ACTIONS.logOut,
});
const updateSettingsCreator: ActionCreator<User> = (user) => ({
  type: ACTIONS.updateSettings,
  payload: user,
});

// Thunks
export const logOutThunk =
  (dispatch: ActionDispatch): LogOut =>
  async () => {
    setAxiosAuthHeader();
    setStorageItem(STORAGE_KEYS.logoutTime, Date.now()); // For synchronizing logouts
    await revokeRefresh();
    dispatch(logOutCreator());
  };

export const loadAuthThunk =
  (dispatch: ActionDispatch): LoadAuth =>
  async () => {
    const { accessToken, user } = (await refreshAuth()) || {};

    if (accessToken && user) {
      setAxiosAuthHeader(accessToken);
      dispatch(logInCreator(user));
    } else await logOutThunk(dispatch)();
  };

export const logInThunk =
  (dispatch: ActionDispatch): LogIn =>
  async (loginInfo: LoginInfo) => {
    const { accessToken, user } = await logIn(loginInfo);

    setAxiosAuthHeader(accessToken);
    dispatch(logInCreator(user));
  };

export const signUpThunk =
  (dispatch: ActionDispatch): SignUp =>
  async (signupInfo: SignupInfo) => {
    const loginInfo = {
      email: signupInfo.email,
      password: signupInfo.password,
    };

    await createUser(signupInfo);
    await logInThunk(dispatch)(loginInfo);
  };

export const updateSettingsThunk =
  (dispatch: ActionDispatch): UpdateSettings =>
  async (settingsInfo: SettingsInfo) => {
    const user = await updateMe(settingsInfo);

    dispatch(updateSettingsCreator(user));
  };

export const deleteAccountThunk =
  (dispatch: ActionDispatch): DeleteAccount =>
  async () => {
    await deleteMe();
    await logOutThunk(dispatch)();
  };
