import React, {
  ReactElement,
  PropsWithChildren,
  useRef,
  useEffect,
  useLayoutEffect,
  CSSProperties,
} from 'react';
import { isMobile } from 'react-device-detect';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import COLORS from '~/constants/colors';
import Divider from '~/components/Divider';

interface ModalProps {
  visible: boolean;
  header?: string;
  callback?: (event: Event) => void;
  shadeBackgroundColor?: string;
  style?: CSSProperties;
}

function Modal({
  visible = false,
  header,
  callback,
  shadeBackgroundColor = COLORS.black25,
  style,
  children,
}: PropsWithChildren<ModalProps>): ReactElement {
  const modalRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleHideModal = (event) => {
    const insideModal = modalRef.current?.contains(event.target);
    const outsideContainer = !containerRef.current?.contains(event.target);
    const isDisplayed = modalRef.current?.style.display !== 'none';

    if (insideModal && outsideContainer && isDisplayed && modalRef.current) {
      callback?.(event);
      modalRef.current.style.display = 'none';
    }
  };

  // Modal styling
  const modalStyle = {
    display: visible ? 'flex' : 'none',
    // TODO: this doesn't fix scrolling with 2 fingers on mobile but does prevent bottom bar from pushing background around
    position: 'fixed' as const, // TODO: isMobile ? ('absolute' as const) : ('fixed' as const),
    top: 0, // TODO: isMobile ? `${window.scrollY - 1}px` : 0,
    left: 0,
    width: 'max(100vw, 100%)',
    height: '100vh', // TODO: 'calc(100vh + 2px)',
    backgroundColor: shadeBackgroundColor,
    zIndex: 10,
  };
  const containerStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -${isMobile ? 58 : 50}%)`,
    minWidth: 'min(90vw, 30em)',
    maxWidth: 'min(95vw, 35em)',
    maxHeight: '75vh',
    padding: '0.7em',
    color: COLORS.mineShaft,
    backgroundColor: COLORS.white,
    borderRadius: '1em',
    boxShadow: `-0.13em 0.13em ${COLORS.black25}`,
    overflowY: 'scroll' as const,
    zIndex: 100,
    ...style,
  };
  const headerStyle = {
    // margin: '0.35em 0 0.5em',
    // paddingBottom: '0.35em',
    // borderBottom: `1px solid ${COLORS.alto}`,
    margin: '0.35em 0 0', // Switch with commented if want to remove `Divider` dependency
    textAlign: 'center' as const,
  };
  const dividerStyle = {
    margin: '0.7em 0 1em',
  };
  const contentStyle = {
    textAlign: 'left' as const,
  };

  useLayoutEffect(() => {
    if (visible) {
      // TODO: remove this hack when `body-scroll-lock` updates
      const storedRequestAnimationFrame = window.requestAnimationFrame;

      window.requestAnimationFrame = () => 42;
      disableBodyScroll(containerRef.current);
      window.requestAnimationFrame = storedRequestAnimationFrame;
    } else enableBodyScroll(containerRef.current);
  }, [visible]);

  useEffect(() => {
    document.addEventListener('click', handleHideModal);

    return () => document.removeEventListener('click', handleHideModal);
  });

  return (
    <div style={modalStyle} ref={modalRef}>
      <div style={containerStyle} ref={containerRef}>
        {header && (
          <>
            <h1 style={headerStyle}>{header}</h1>
            <Divider style={dividerStyle} />
          </>
        )}
        <div style={contentStyle}>{children}</div>
      </div>
    </div>
  );
}

export default Modal;
