import React, { ReactElement, CSSProperties } from 'react';
import Switch, { ReactSwitchProps } from 'react-switch';

import { useThemeContext, CONDITIONS } from '~/providers/ThemeProvider';
import COLORS from '~/constants/colors';

interface ToggleSwitchProps extends ReactSwitchProps {
  condition?: string;
  style?: CSSProperties;
}

function ToggleSwitch({
  checked,
  onChange,
  condition = CONDITIONS.primary,
  checkedIcon = false,
  uncheckedIcon = false,
  width = 34,
  height = 15,
  handleDiameter = 20,
  onColor,
  boxShadow = `0 0 0.2em ${COLORS.black50}`,
  activeBoxShadow = `0 0 0.2em ${COLORS.black50}`,
  style,
  ...props
}: ToggleSwitchProps): ReactElement {
  const { theme } = useThemeContext().state;
  const toggleSwitchStyle = {
    display: 'inline-block',
    height: `calc(${Math.max(height, handleDiameter)} + 0.2em)`,
    verticalAlign: 'middle',
    ...style,
  };

  return (
    <div style={toggleSwitchStyle}>
      <Switch
        checked={checked}
        onChange={onChange}
        checkedIcon={checkedIcon}
        uncheckedIcon={uncheckedIcon}
        height={height}
        width={width}
        handleDiameter={handleDiameter}
        onColor={onColor || theme[condition].default.backgroundColor}
        boxShadow={boxShadow}
        activeBoxShadow={activeBoxShadow}
        {...props}
      />
    </div>
  );
}

export default ToggleSwitch;
