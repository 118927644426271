import React, { ReactElement, CSSProperties } from 'react';

import COLORS from '~/constants/colors';

import shuffleArrows from '../assets/shuffle-arrows.svg';

interface ShuffleProps {
  checked?: boolean;
  callback?: (checked: boolean) => void;
  style?: CSSProperties;
  checkedStyle?: CSSProperties;
}

function Shuffle({
  checked = false,
  callback,
  style,
  checkedStyle,
}: ShuffleProps): ReactElement {
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    callback?.(!checked);
  };
  const stopExtraEffects = (event) => event.preventDefault();

  // Shuffle styling
  const dims = { w: '2em', h: '2em' };
  const shuffleStyle = {
    display: 'inline-block',
    width: dims.w,
    height: dims.h,
    margin: '0 0.25em',
    paddingRight: '0.15em',
    verticalAlign: 'middle',
    backgroundColor: COLORS.black25,
    backgroundImage: `url(${shuffleArrows})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0.1em',
    borderColor: COLORS.black50,
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderRadius: '0.5em',
    outline: 'none',
    overflow: 'hidden',
    cursor: 'pointer',
    ...style,
  };
  const shuffleCheckedStyle = {
    ...shuffleStyle,
    backgroundColor: COLORS.dodgerBlue,
    borderColor: COLORS.black25,
    ...checkedStyle,
  };

  return (
    <div
      role="none"
      onMouseDown={handleClick}
      onMouseUp={stopExtraEffects}
      onTouchStart={handleClick}
      onTouchEnd={stopExtraEffects}
      style={checked ? shuffleCheckedStyle : shuffleStyle}
    />
  );
}

export default Shuffle;
