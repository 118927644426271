import React, {
  ReactElement,
  CSSProperties,
  Dispatch,
  SetStateAction,
} from 'react';

import COLORS from '~/constants/colors';
import { FONT_WEIGHTS } from '~/constants/typography';

import { INPUT_MODES } from '../constants/layout';

interface GuidanceSwitchProps {
  label: string;
  guidance: boolean;
  setGuidance: Dispatch<SetStateAction<boolean>>;
  inputMode: string;
  style?: CSSProperties;
  checkedStyle?: CSSProperties;
}

function GuidanceSwitch({
  label,
  guidance = false,
  setGuidance,
  inputMode,
  style,
  checkedStyle,
}: GuidanceSwitchProps): ReactElement {
  const disabled = inputMode === INPUT_MODES.key;
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!disabled) setGuidance(!guidance);
  };
  const stopExtraEffects = (event) => event.preventDefault();

  // GuidanceSwitch styling
  const disabledStyle = {
    color: COLORS.platinum,
    backgroundColor: COLORS.black25,
    borderColor: COLORS.black50,
    cursor: 'default',
  };
  const guidanceSwitchStyle = {
    display: 'inline-block',
    width: '4.5em',
    padding: '0.32em 0',
    marginLeft: '0.5em',
    verticalAlign: 'top',
    color: COLORS.white,
    backgroundColor: COLORS.black25,
    borderColor: COLORS.black50,
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderRadius: '0.3em',
    fontWeight: FONT_WEIGHTS.bold,
    textAlign: 'center' as const,
    outline: 'none',
    overflow: 'hidden',
    cursor: 'pointer',
    ...style,
    ...(disabled && disabledStyle),
  };
  const guidanceSwitchCheckedStyle = {
    ...guidanceSwitchStyle,
    backgroundColor: COLORS.dodgerBlue,
    borderColor: COLORS.black25,
    ...checkedStyle,
  };
  const getStyle = (checked) =>
    checked ? guidanceSwitchCheckedStyle : guidanceSwitchStyle;

  return (
    <div
      role="none"
      onMouseDown={handleClick}
      onMouseUp={stopExtraEffects}
      onTouchStart={handleClick}
      onTouchEnd={stopExtraEffects}
      style={getStyle(guidance && !disabled)}
    >
      {label}
    </div>
  );
}

export default GuidanceSwitch;
