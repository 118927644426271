import React, { Dispatch, SetStateAction, ReactElement } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import FramedView from '~/components/FramedView';
import Announcement from '~/components/Announcement';
import Button from '~/components/Button';
import { CONDITIONS } from '~/providers/ThemeProvider';
import TextArea from '~/components/TextArea';
import Divider from '~/components/Divider';
import Title from '~/components/Title';
import Subtext from '~/components/Subtext';

import { Rhythm, StudioSettings, RecordResults } from '../models';
import PHASES from '../constants/phases';

interface ProductionProps {
  setPhase: Dispatch<SetStateAction<string>>;
  newRhythm: Rhythm;
  setNewRhythm: Dispatch<SetStateAction<Rhythm>>;
  settings: StudioSettings;
  results: RecordResults;
}

function Production({
  setPhase,
  newRhythm,
  setNewRhythm,
  settings,
  results,
}: ProductionProps): ReactElement {
  const mergeTimings = (startTime, endTime, oldTimings, newTimings) => {
    const keep = (timing) => timing < startTime || timing > endTime;
    const compare = (a, b) => a - b;

    return oldTimings.filter(keep).concat(newTimings).sort(compare);
  };
  const mergedTimings = mergeTimings(
    settings.videoStartTime,
    settings.videoEndTime,
    newRhythm.timings,
    results.timings
  );

  // Handle production interactions
  const stopExtraEffects = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const handleAccept = (event) => {
    stopExtraEffects(event);
    setNewRhythm({
      ...newRhythm,
      timings: mergedTimings,
    });
    setPhase(PHASES.studio);
  };
  const handleDiscard = (event) => {
    stopExtraEffects(event);
    setPhase(PHASES.studio);
  };

  // Production styling
  const productionStyle = {
    width: 'min(45em, 82.5vw)',
  };
  const frameColors: [string, string] = [
    COLORS.waikawaGray,
    COLORS.waikawaGray,
  ];
  const buttonStyle = {
    width: '9em',
    margin: '0 0.25em 0.38em',
    boxShadow: `-0.13em 0.13em ${COLORS.black25}`,
    borderStyle: 'none',
    fontSize: FONT_SIZES.text,
  };
  const textAreaStyle = {
    width: 'min(320px, 75vw)',
    height: '5em',
  };
  const levelStyle = {
    display: 'block',
    marginTop: '1em',
  };
  const levelRowSpaceStyle = {
    height: '0.25em',
  };
  const betweenStyle = {
    margin: '0.1em 0 0 0.25em',
  };

  return (
    <FramedView accent colors={frameColors} style={productionStyle}>
      <Title>Production</Title>
      <br />
      Please review the results of your recording. Accepting will overwrite the
      old timings between your start and end times with the new timings you just
      made. The others will remain untouched.
      <Divider />
      <div style={levelStyle}>
        Here are your old timings
        <div style={levelRowSpaceStyle} />
        <TextArea
          readOnly
          value={JSON.stringify(newRhythm.timings)}
          style={textAreaStyle}
        />
      </div>
      <div style={levelStyle}>
        Here are your new timings
        <div style={levelRowSpaceStyle} />
        <TextArea
          readOnly
          value={JSON.stringify(results.timings)}
          style={textAreaStyle}
        />
        <Subtext style={betweenStyle}>
          Between{' '}
          <b style={{ color: COLORS.dodgerBlue }}>
            {Math.floor(settings.videoStartTime / 1000)}
          </b>{' '}
          and{' '}
          <b style={{ color: COLORS.dodgerBlue }}>
            {Math.floor(settings.videoEndTime / 1000)}
          </b>{' '}
          seconds
        </Subtext>
      </div>
      <div style={levelStyle}>
        What the merged timings will be
        <div style={levelRowSpaceStyle} />
        <TextArea
          readOnly
          value={JSON.stringify(mergedTimings)}
          style={textAreaStyle}
        />
      </div>
      <br />
      <Announcement header="Note" condition={CONDITIONS.warning}>
        This mutates your work! If you&apos;re worried, please copy down your
        old timings somewhere.
      </Announcement>
      <br />
      <Button
        label="Accept"
        onClick={handleAccept}
        onTouchEnd={handleAccept}
        style={buttonStyle}
      />
      <Button
        label="Discard"
        condition={CONDITIONS.fail}
        onClick={handleDiscard}
        onTouchEnd={handleDiscard}
        style={buttonStyle}
      />
    </FramedView>
  );
}

export default Production;
