import journalUrls from '~/urls/journal';

export default {
  logIn: `${journalUrls.auth}/local/log-in`,
  register: `${journalUrls.auth}/local/register`,
  refresh: `${journalUrls.auth}/refresh`,
  revoke: `${journalUrls.auth}/revoke`,
  me: `${journalUrls.auth}/me`,
  changePassword: `${journalUrls.auth}/change-password`,
};
