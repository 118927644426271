import React, { ReactElement } from 'react';

import { useAuthContext } from '~/providers/AuthProvider';
import Link from '~/components/Link';
import COLORS from '~/constants/colors';
import Subtext from '~/components/Subtext';

import { hasPermission } from '../../../utils';

interface EditLinkProps {
  content: string;
  uri: string;
}

function EditLink({ content, uri }: EditLinkProps): ReactElement {
  const { state: authState } = useAuthContext();

  // Edit link styling
  const subtextStyle = {
    position: 'absolute' as const,
    right: '0.7em',
    width: '5.2em',
    marginTop: '0.6em',
    textAlign: 'right' as const,
    backgroundColor: COLORS.white,
  };

  return (
    <>
      {hasPermission(authState, content) && (
        <Subtext style={subtextStyle}>
          <Link href={uri}>
            <b>Edit Card</b>
          </Link>
        </Subtext>
      )}
    </>
  );
}

export default EditLink;
