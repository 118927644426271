import React, { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';

import COLORS from '~/constants/colors';
import FramedView from '~/components/FramedView';
import Title from '~/components/Title';
import Divider from '~/components/Divider';
import { useAuthContext } from '~/providers/AuthProvider';

import PhaseSwitchBoard from '../components/PhaseSwitchBoard';
import NotFound from './NotFound';
import Unauthed from './Unauthed';
import CategoryForm from '../components/CategoryForm';
import TechniqueForm from '../components/TechniqueForm';
import DemoForm from '../components/DemoForm';
import TutorialForm from '../components/TutorialForm';
import { hasPermission, getPermitted } from '../utils';
import CONTENT from '../constants/content';
import PATHS, { MANAGE_PATHS } from '../constants/paths';

function Manage(): ReactElement {
  const { state: authState } = useAuthContext();
  const phasePathsDict = Object.keys(MANAGE_PATHS)
    .filter((k) => CONTENT[k])
    .reduce((acc, k) => ({ ...acc, [k]: MANAGE_PATHS[k] }), {});

  // Manage styling
  const manageStyle = {
    width: 'min(45em, 82.5vw)',
  };
  const frameColors: [string, string] = [
    COLORS.waikawaGray,
    COLORS.waikawaGray,
  ];

  // Reduce repetition
  const RoleUnauthed = () => <Unauthed nested roleRequired />;
  const indexKey = getPermitted(authState)[0] || PATHS.unauthed;
  const indexElements = {
    categories: <CategoryForm />,
    techniques: <TechniqueForm />,
    demos: <DemoForm />,
    tutorials: <TutorialForm />,
    unauthed: <RoleUnauthed />,
  };

  return (
    <FramedView accent colors={frameColors} style={manageStyle}>
      <Title>Manage</Title>
      <br />
      <PhaseSwitchBoard
        authState={authState}
        pagePath={PATHS.manage}
        phasePathsDict={phasePathsDict}
      />
      <Divider />
      <Routes>
        <Route path="*" element={<NotFound nested />} />
        <Route index element={indexElements[indexKey]} />

        {/* Categories */}
        {hasPermission(authState, CONTENT.categories) ? (
          <Route path={MANAGE_PATHS.categories}>
            <Route index element={<CategoryForm />} />
            <Route path={MANAGE_PATHS.category} element={<CategoryForm />} />
          </Route>
        ) : (
          <Route path={MANAGE_PATHS.categories} element={<RoleUnauthed />} />
        )}

        {/* Techniques */}
        {hasPermission(authState, CONTENT.techniques) ? (
          <Route path={MANAGE_PATHS.techniques}>
            <Route index element={<TechniqueForm />} />
            <Route path={MANAGE_PATHS.technique} element={<TechniqueForm />} />
          </Route>
        ) : (
          <Route path={MANAGE_PATHS.techniques} element={<RoleUnauthed />} />
        )}

        {/* Demos */}
        {hasPermission(authState, CONTENT.demos) ? (
          <Route path={MANAGE_PATHS.demos}>
            <Route index element={<DemoForm />} />
            <Route path={MANAGE_PATHS.demo} element={<DemoForm />} />
          </Route>
        ) : (
          <Route path={MANAGE_PATHS.demos} element={<RoleUnauthed />} />
        )}

        {/* Tutorials */}
        {hasPermission(authState, CONTENT.tutorials) ? (
          <Route path={MANAGE_PATHS.tutorials}>
            <Route index element={<TutorialForm />} />
            <Route path={MANAGE_PATHS.tutorial} element={<TutorialForm />} />
          </Route>
        ) : (
          <Route path={MANAGE_PATHS.tutorials} element={<RoleUnauthed />} />
        )}
      </Routes>
    </FramedView>
  );
}

export default Manage;
