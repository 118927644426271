import React, { ReactElement, PropsWithChildren } from 'react';

import COLORS from '~/constants/colors';
import Code from '~/components/Code';
import Link from '~/components/Link';
import { stopEventEffects } from '~/utils';

interface ContentItemProps {
  id: number;
  label: string;
  gutterWidth?: string;
  link?: string;
  callback?: (event: Event) => void;
}

function ContentItem({
  id,
  label,
  gutterWidth = '0px',
  link,
  callback,
  children,
}: PropsWithChildren<ContentItemProps>): ReactElement {
  const handleCallback = (event) => {
    stopEventEffects(event);
    callback?.(event);
  };

  // Content item styling
  const contentItemStyle = {
    padding: '0.67em 0.5em 0.67em 0.15em',
    borderTop: '1px dashed',
    borderColor: COLORS.gallery,
  };
  const liStyle = {
    width: '100%',
    marginLeft: '0.25em',
    paddingLeft: '0.25em',
  };
  const wrapperStyle = {
    display: 'inline-block',
    width: `calc(100% - ${gutterWidth})`,
    verticalAlign: 'middle',
    overflow: 'hidden',
    whiteSpace: 'nowrap' as const,
  };
  const idStyle = {
    display: 'inline-block',
    margin: '0 1em 0 0.25em',
  };
  const labelStyle = {
    display: 'inline-block',
  };
  const callbackLabelStyle = {
    ...labelStyle,
    color: COLORS.chetwodeBlue,
    cursor: 'pointer',
  };

  return (
    <div style={contentItemStyle}>
      <li style={liStyle}>
        <div style={wrapperStyle}>
          <Code style={idStyle}>{id}</Code>
          {link ? (
            <Link href={link} style={labelStyle}>
              {label}
            </Link>
          ) : (
            <span
              {...(callback && {
                role: 'none',
                onMouseUp: handleCallback,
                onTouchEnd: handleCallback,
              })}
              style={callback ? callbackLabelStyle : labelStyle}
            >
              {label}
            </span>
          )}
        </div>
        {children}
      </li>
    </div>
  );
}

export default ContentItem;
