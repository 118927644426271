import React, { ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';

import PATHS from '~/constants/paths';
import DOMAINS, { HOST } from '~/constants/domains';
import COLORS from '~/constants/colors';
import { useAuthContext } from '~/providers/AuthProvider';
import Link from '~/components/Link';
import Modal from '~/components/Modal';
import Gap from '~/components/Gap';
import Divider from '~/components/Divider';
import { stopEventEffects, getRelativeUrl, getHostUrl } from '~/utils';

function Navigation(): ReactElement {
  const location = useLocation();
  const navState =
    location.pathname !== PATHS.login && location.pathname !== PATHS.register
      ? { from: getRelativeUrl() }
      : location.state;
  const getNavUrl = (path, domain?) => {
    if (!domain) return getHostUrl(path);

    return HOST === domain ? PATHS.root : getHostUrl(path);
  };

  // Menu interaction
  // TODO: use mobile-friendly dropdown instead of modal
  const [showModal, setShowModal] = useState(false);
  const { state, logOut } = useAuthContext();
  const showMenu = (event) => {
    stopEventEffects(event);
    setShowModal(true);
  };
  const hideMenu = () => setShowModal(false);
  const logOutCallback = async () => {
    await logOut();
    hideMenu();
  };

  // Navigation styling
  const navHeight = '1.75em';
  const navigationStyle = {
    position: 'fixed' as const,
    height: navHeight,
    backgroundColor: COLORS.black50,
    border: `solid ${COLORS.black25}`,
    borderWidth: '0 0.1em 0.1em 0',
    borderRadius: '0 0 0.5em 0',
    zIndex: 1,
  };
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    height: navHeight,
    padding: '0 1em',
    cursor: 'pointer',
  };
  const showMenuStyle = {
    color: COLORS.alabaster,
  };
  const modalStyle = {
    top: `calc(0.5em + ${navHeight})`,
    left: '0.5em',
    transform: 'none',
    minWidth: 'auto',
    borderRadius: '0 0.5em 0.5em',
  };
  const ulStyle = {
    margin: '0.25em 0 0 0',
    padding: '0 0 0 1.75em',
  };

  // Reduce repetition
  // For some reason `onClick` is needed instead of `onMouseUp` and `onTouchEnd` for redirect to work
  const NavLink = ({ callback = hideMenu, children, ...props }) => (
    <Link onClick={callback} {...props}>
      {children}
    </Link>
  );

  return (
    <nav style={navigationStyle}>
      <div
        role="button"
        tabIndex={0}
        onMouseUp={showMenu}
        onTouchEnd={showMenu}
        style={containerStyle}
      >
        <b style={showMenuStyle}>Menu</b>
      </div>
      <Modal visible={showModal} callback={hideMenu} style={modalStyle}>
        <NavLink href={getNavUrl(PATHS.root)}>
          <b>Ponder</b>
        </NavLink>
        <Divider />
        <b>Japanese</b>
        <ul style={ulStyle}>
          <li>
            <NavLink href={getNavUrl(PATHS.kanaMatch)}>Kana Match</NavLink>
          </li>
          <li>
            <NavLink href={getNavUrl(PATHS.flowGo)}>Flow GO!</NavLink>
          </li>
        </ul>
        <Gap />
        <b>Music</b>
        <ul style={ulStyle}>
          <li>
            <NavLink href={getNavUrl(PATHS.beatboxShare, DOMAINS.beatboxShare)}>
              Beatbox Share
            </NavLink>
          </li>
          <li>
            <NavLink href={getNavUrl(PATHS.beatblox, DOMAINS.beatblox)}>
              Beatblox
            </NavLink>
          </li>
          <li>
            <NavLink href={getNavUrl(PATHS.beatboxDb, DOMAINS.beatboxDb)}>
              Beatbox Database
            </NavLink>
          </li>
        </ul>
        <Divider />
        {state.isAuthed && (
          <>
            <NavLink to={PATHS.settings}>
              <b>Settings</b>
            </NavLink>
            <Gap />
            <NavLink to={location.pathname} callback={logOutCallback}>
              Log out
            </NavLink>
          </>
        )}
        {!state.isAuthed && (
          <>
            <NavLink to={PATHS.register} state={navState}>
              <b>Register</b>
            </NavLink>
            <Gap />
            <NavLink to={PATHS.login} state={navState}>
              Log in
            </NavLink>
          </>
        )}
      </Modal>
    </nav>
  );
}

export default Navigation;
