import React, { ReactElement, Dispatch, SetStateAction } from 'react';

import Flipper from '~/components/Flipper';
import COLORS from '~/constants/colors';

import { INPUT_MODES } from '../constants/layout';

interface PadLabelsFlipperProps {
  which: string;
  setWhich: Dispatch<SetStateAction<string>>;
  inputMode: string;
}

function PadLabelsFlipper({
  which,
  setWhich,
  inputMode,
}: PadLabelsFlipperProps): ReactElement {
  const disabled = inputMode === INPUT_MODES.key;
  const indexMap = { hiragana: 0, katakana: 1 };
  const idx = disabled ? 2 : indexMap[which];
  const labels = ['ひらがな', 'カタカナ', 'None'];
  const handleFlip = () => {
    const labelSets = Object.keys(indexMap);

    setWhich(labelSets[(idx + 1) % labelSets.length]);
  };

  // Input mode flipper styling
  const padLabelsFlipperStyle = {
    width: '5em',
    marginLeft: '0.5em',
    padding: '0.2em 0',
    textAlign: 'center' as const,
    cursor: 'pointer',
  };
  const caseStyles = [
    { backgroundColor: COLORS.tropicalBlue },
    { backgroundColor: COLORS.fallGreen },
  ];

  return (
    <Flipper
      labels={labels}
      idx={idx}
      callback={handleFlip}
      disabled={disabled}
      style={padLabelsFlipperStyle}
      caseStyles={caseStyles}
    />
  );
}

export default PadLabelsFlipper;
