import React, {
  ReactElement,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Announcement from '~/components/Announcement';
import Header from '~/components/Header';
import Subheader from '~/components/Subheader';
import Divider from '~/components/Divider';
import Gap from '~/components/Gap';
import Link from '~/components/Link';
import Break from '~/components/Break';

import { readClip, readRecording } from '../services';
import NotFound from './NotFound';
import Container from '../components/Container';
import MediaControls from '../components/MediaControls';
import FileControls from '../components/FileControls';
import AudioPlayer from '../components/AudioPlayer';
import VideoPlayer from '../components/VideoPlayer';
import ActionPanel from '../components/ActionPanel';
import uris from '../uris';
import { ListenControls, Show } from '../models';
import { getRedirectUri } from '../utils';

interface ListenProps {
  listenControls: ListenControls;
  setListenControls: Dispatch<SetStateAction<ListenControls>>;
  setAutomaticStart: Dispatch<SetStateAction<boolean>>;
}

function Listen({
  listenControls,
  setListenControls,
  setAutomaticStart,
}: ListenProps): ReactElement {
  const [errorText, setErrorText] = useState('');
  const [notFound, setNotFound] = useState<ReactElement>();
  const [cookedUrl, setCookedUrl] = useState('');
  const [cookedMedia, setCookedMedia] = useState<Blob>();
  const [show, setShow] = useState<Show>();
  const { code } = useParams();
  const navigate = useNavigate();

  // Handlers
  const onStart = () => {
    setAutomaticStart(true);
    navigate(getRedirectUri(listenControls.show));
  };

  // Listen styling
  const headerStyle = {
    margin: 0,
  };

  useEffect(() => {
    (async () => {
      if (code) {
        try {
          const clip = await readClip(code);
          const recording = await readRecording(
            clip.recording.url,
            clip.recording.mime
          );

          setShow(clip.recording.mime.startsWith('audio') ? 'audio' : 'video');
          setCookedUrl(URL.createObjectURL(recording));
          setCookedMedia(recording);
        } catch {
          setNotFound(<NotFound withCode />);
        }
      }
    })();
  }, [code]);
  useEffect(() => {
    if (!listenControls.fromPublish) setListenControls({});
    else setListenControls({ show: listenControls.show });
  }, [code]);

  return (
    <>
      <Container>
        {errorText && (
          <>
            <Announcement>{errorText}</Announcement>
            <Break />
          </>
        )}
        <Header style={headerStyle}>
          Click{' '}
          <Link to={show === 'video' ? uris.film : uris.record}>here</Link> to
          record something!
        </Header>
        <Divider />
        <Subheader>Send your friends clips with mastered audio</Subheader>
      </Container>
      <Break />
      <Break />
      {notFound}
      {cookedUrl && (
        <>
          <div>
            {show === 'audio' && <AudioPlayer url={cookedUrl} />}
            {show === 'video' && <VideoPlayer url={cookedUrl} />}
            <Gap />
            {cookedMedia && (
              <ActionPanel
                cookedUrl={cookedUrl}
                cookedMedia={cookedMedia}
                setCookedMedia={setCookedMedia}
                setErrorText={setErrorText}
                code={code}
                show={listenControls.show}
              />
            )}
          </div>
          {listenControls.show && (
            <>
              <Break />
              {(listenControls.show === 'audio' ||
                listenControls.show === 'video') && (
                <MediaControls deviceState="done" onStart={onStart} />
              )}
              {listenControls.show === 'file' && (
                <FileControls deviceState="done" onStart={onStart} />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default Listen;
