import React, { ReactElement, CSSProperties } from 'react';

import { FONT_SIZES } from '~/constants/typography';
import Gap from '~/components/Gap';
import Link from '~/components/Link';

interface TeamMemberProps {
  src: string;
  name: string;
  link?: string;
  width?: number;
  style?: CSSProperties;
}

function TeamMember({
  src,
  name,
  link,
  width = 100,
  style,
}: TeamMemberProps): ReactElement {
  // Team member styling
  const teamMemberStyle = {
    display: 'inline-block',
    textAlign: 'center' as const,
    ...style,
  };
  const imgStyle = {
    borderRadius: '50%',
  };
  const nameStyle = {
    fontSize: FONT_SIZES.action,
  };

  // Reduce repetition from `link` conditioning
  const Guts = () => (
    <>
      <img src={src} alt={name} width={width} style={imgStyle} />
      <Gap />
      <span style={nameStyle}>{name}</span>
    </>
  );

  return (
    <div style={teamMemberStyle}>
      {link ? (
        <Link href={link}>
          <Guts />
        </Link>
      ) : (
        <Guts />
      )}
    </div>
  );
}

export default TeamMember;
