import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import Triangle from '~/components/Triangle';

import { getPx } from '../../../utils';

interface CaretsProps {
  height: number;
  base: number;
  ratio: number;
  start?: number;
  end?: number;
  maxTiming: number;
}

function Carets({
  height,
  base,
  ratio,
  start,
  end,
  maxTiming,
}: CaretsProps): ReactElement {
  const fill = end ? COLORS.tundora : COLORS.gray;
  const highlightColor = COLORS.malibu15;

  // Styling
  const getTriangleStyle = (timing) => ({
    position: 'absolute' as const,
    left: `${(timing / maxTiming) * 100}%`,
    top: 0,
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  });
  const highlightStyle = {
    position: 'absolute' as const,
    ...(start && end && { width: `${((end - start) / maxTiming) * 100}%` }),
    height,
    ...(start && { left: `${(start / maxTiming) * 100}%` }),
    top: 0,
    backgroundColor: highlightColor,
    zIndex: 0,
  };

  return (
    <>
      {start && (
        <Triangle
          base={getPx(base * 2)}
          ratio={ratio}
          direction="down"
          fill={fill}
          style={getTriangleStyle(start)}
        />
      )}
      {end && (
        <Triangle
          base={getPx(base * 2)}
          ratio={ratio}
          direction="down"
          fill={fill}
          style={getTriangleStyle(end)}
        />
      )}
      {start && end && <div style={highlightStyle} />}
    </>
  );
}

export default Carets;
