import React, { ReactElement, CSSProperties } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FONT_WEIGHTS } from '~/constants/typography';
import { addParams, stopEventEffects } from '~/utils';
import Link from '~/components/Link';
import Code from '~/components/Code';
import { useThemeContext } from '~/providers/ThemeProvider';
import { DEFAULT_PAGE_SIZE } from '~/constants/defaults';

interface PaginatorProps {
  link: string;
  itemTotal?: number;
  pageSize?: number;
  displayRange?: number;
  style?: CSSProperties;
}

const getPageSlice = <T,>(
  list: T[] | undefined,
  page: number,
  pageSize = DEFAULT_PAGE_SIZE
): T[] | undefined => {
  const start = (page - 1) * pageSize;

  return list?.slice(start, start + pageSize);
};

function Paginator({
  link,
  itemTotal = 0,
  pageSize = DEFAULT_PAGE_SIZE,
  displayRange = 10,
  style,
}: PaginatorProps): ReactElement | null {
  const { theme } = useThemeContext().state;
  const [searchParams] = useSearchParams();
  const pageCount = Math.ceil(itemTotal / pageSize);
  const currentPage = Number(searchParams.get('page')) || 1;
  const startPage = Math.max(currentPage - Math.ceil(displayRange / 2), 1);
  const upperBound = startPage + displayRange;
  const pageNumbers = [...Array(displayRange).keys()]
    .map((n) => n + startPage)
    .filter((n) => n <= pageCount);
  const getPageLink = (n) => addParams(link, { page: n });
  const getKey = (idx) => `PaginatorPageNumber${idx}`;

  // Paginator styling
  const paginatorStyle = {
    width: '100%',
    margin: '0.5em 0',
    textAlign: 'center' as const,
    ...style,
  };
  const pageLinkStyle = {
    margin: '0 0.3em',
  };
  const boundLinkStyle = {
    ...pageLinkStyle,
    fontWeight: FONT_WEIGHTS.thin,
  };

  // Highlight on hover
  const onMouseEnter = (e) => {
    stopEventEffects(e);
    (e.target as HTMLElement).style.color = String(theme.neutral.active?.color);
  };
  const onMouseLeave = (e) => {
    stopEventEffects(e);
    (e.target as HTMLElement).style.color = String(theme.neutral.hover?.color);
  };
  const HoverLink = (props) => (
    <Link onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} {...props} />
  );

  return pageCount > 1 ? (
    <div style={paginatorStyle}>
      {currentPage > 1 && (
        <HoverLink href={getPageLink(currentPage - 1)} style={pageLinkStyle}>
          &lt;
        </HoverLink>
      )}
      {startPage > 1 && (
        <HoverLink href={getPageLink(1)} style={boundLinkStyle}>
          first
        </HoverLink>
      )}
      {pageNumbers.map((n) =>
        n === currentPage ? (
          <Code key={getKey(n)} style={pageLinkStyle}>
            {n}
          </Code>
        ) : (
          <HoverLink
            key={getKey(n)}
            href={getPageLink(n)}
            style={pageLinkStyle}
          >
            {n}
          </HoverLink>
        )
      )}
      {upperBound <= pageCount && (
        <HoverLink href={getPageLink(pageCount)} style={boundLinkStyle}>
          last
        </HoverLink>
      )}
      {currentPage < pageCount && (
        <HoverLink href={getPageLink(currentPage + 1)} style={pageLinkStyle}>
          &gt;
        </HoverLink>
      )}
    </div>
  ) : null;
}

export default Paginator;
export { getPageSlice };
