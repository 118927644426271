import React, { ReactElement } from 'react';

import { FONT_SIZES } from '~/constants/typography';

interface YouTubePlayerProps {
  link: string;
}

function YouTubePlayer({ link }: YouTubePlayerProps): ReactElement {
  const id = 'beatboxdb-contentcard-youtube-iframe';
  const title = 'BeatboxDbContentCardYouTubePlayer';
  const relativeWidth =
    document.body.clientWidth - 0.7 * parseInt(FONT_SIZES.text, 10) * 3;
  const linkRegex =
    /(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;
  const videoId = link.match(linkRegex)?.[7] ?? 'BBJa32lCaaY';
  const src = `https://www.youtube.com/embed/${videoId}?fs=0&iv_load_policy=3&modestbranding=1&playsinline=1&enablejsapi=1`;

  // YouTube player styling
  const youtubePlayerStyle = {
    borderRadius: '0.5em',
  };

  return (
    <iframe
      id={id}
      title={title}
      width={Math.min(relativeWidth, 480)}
      height={Math.min((relativeWidth * 315) / 560, 270)}
      src={src}
      frameBorder="0"
      allow="fullscreen; encrypted-media"
      style={youtubePlayerStyle}
    />
  );
}

export default YouTubePlayer;
