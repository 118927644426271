import React, { ReactElement, MutableRefObject, useEffect } from 'react';
import { GameEngine } from 'react-game-engine';

import COLORS from '~/constants/colors';
import { stopEventEffects } from '~/utils';

import { RenderProps } from '../../models';
import { Tracks } from './models';
import { getPx, playAudio } from '../../utils';
import { rotatePos, getWidth, getHeight, getLeft, getTop } from '../../align';
import { IS_MOBILE } from '../../constants';
import events from './events';
import { getYFromTrack } from '../Note';

interface KeyboardProps {
  gameEngineRef: MutableRefObject<GameEngine>;
  tracks: Tracks;
}

function Keyboard({
  entity,
  gameDims,
}: RenderProps<KeyboardProps>): ReactElement {
  const pos = entity.getPos();
  const getKey = (idx) => `BeatbloxReadGameKeyboardTrack${idx}`;

  // Handlers
  const handleKeyDown = (event) => {
    if (!event.repeat) {
      const track = Object.values(entity.tracks).find(
        (t) => t.key === event.code
      );

      if (track) {
        stopEventEffects(event);
        playAudio(track.node.sample.recording.url);
        entity.gameEngineRef.current?.dispatch(events.onKeyDown(event.code));
      }
    }
  };

  // Keyboard styling
  const width = getWidth(gameDims, entity.dims.w, entity.dims.h);
  const height = getHeight(gameDims, entity.dims.h, entity.dims.w);
  const keyboardStyle = {
    position: 'absolute' as const,
    width,
    height,
    left: getLeft(gameDims, pos.x - entity.dims.w / 2, pos.y, width),
    top: getTop(gameDims, pos.y - entity.dims.h / 2, pos.x, height),
    color: COLORS.black50,
    zIndex: 3,
  };
  const getHelpStyle = (number) => {
    const y = getYFromTrack(entity.dims, number);

    return {
      position: 'absolute' as const,
      ...(IS_MOBILE
        ? {
            left: rotatePos(y, gameDims.h, gameDims.w),
            transform: 'translate(-50%, 100%)',
          }
        : { top: getPx(y - entity.dims.w / 2) }),
    };
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    // window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      // window.removeEventListener('keyup', handleKeyUp);
    };
  });

  return (
    <div style={keyboardStyle}>
      {Object.values(entity.tracks).map((t, idx) => (
        <div key={getKey(idx)} style={getHelpStyle(t.number)}>
          Press <b>{t.key[3]}</b> key
        </div>
      ))}
    </div>
  );
}

export default Keyboard;
export type { KeyboardProps };
