import React, { ReactElement, PropsWithChildren, CSSProperties } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import socialUrls from '~/urls/social';
import whiteClouds from '~/assets/white-clouds.svg';
import solitudeClouds from '~/assets/solitude-clouds.svg';
import stars from '~/assets/stars.svg';
import mountains from '~/assets/mountains.svg';
import Link from '~/components/Link';

interface DawnHorizonProps {
  style?: CSSProperties;
}

function DawnHorizon({
  style,
  children,
}: PropsWithChildren<DawnHorizonProps>): ReactElement {
  const dawnHorizonStyle = {
    display: 'table',
    width: '100%',
    height: '100vh',
    backgroundImage: `linear-gradient(${COLORS.luckyPoint} 0%, ${COLORS.mandy} 45%, ${COLORS.roseBud} 70%, ${COLORS.tropicalBlue} 85%)`,
    ...style,
  };
  const containerStyle = {
    display: 'table-row',
    height: 1,
    verticalAlign: 'bottom',
    fontSize: FONT_SIZES.subtext,
    textAlign: 'right' as const,
  };
  const starsStyle = {
    height: 150,
    backgroundImage: `url(${stars})`,
    backgroundRepeat: 'repeat-x',
    backgroundPosition: '0 13px',
    marginBottom: -150,
  };
  const creditStyle = {
    color: COLORS.cornflower,
    marginBottom: '1.5em',
    marginRight: '1.5em',
  };
  const whiteCloudsStyle = {
    height: 126,
    marginBottom: '-3.6em',
    backgroundImage: `url(${whiteClouds})`,
    backgroundRepeat: 'repeat-x',
  };
  const cloudCoverStyle = {
    width: '100%',
    height: 61,
    marginBottom: -126,
    backgroundColor: COLORS.white,
  };
  const mountainsStyle = {
    ...whiteCloudsStyle,
    height: 239,
    marginBottom: -61,
    backgroundImage: `url(${mountains})`,
    backgroundPosition: '2em',
  };
  const solitudeCloudsStyle = {
    ...whiteCloudsStyle,
    height: 183,
    marginBottom: -239,
    backgroundImage: `url(${solitudeClouds})`,
    backgroundPosition: '1.5em',
  };

  return (
    <div style={dawnHorizonStyle} id="dawn-horizon">
      <div style={containerStyle}>
        <div style={starsStyle} />
      </div>
      {children}
      <div style={containerStyle}>
        <div style={solitudeCloudsStyle} />
        <div style={mountainsStyle} />
        <div style={cloudCoverStyle} />
        <div style={whiteCloudsStyle} />
        <p style={creditStyle}>
          Designed by <Link href={socialUrls.richardLinkedIn}>Richard Hsu</Link>{' '}
          and Mansfield Mark
        </p>
      </div>
    </div>
  );
}

export default DawnHorizon;
