import React, { ReactElement } from 'react';
import { motion } from 'framer-motion';

import COLORS from '~/constants/colors';

import { RenderProps } from '../../models';
import { getPx } from '../../utils';
import { getLeft, getTop } from '../../align';
import { NOTE_MODES } from './constants';

type PoofFrames = [null, string, string];

interface NoteProps {
  key: string;
  timing: number;
  attemptable: boolean;
  garbageTime: number;
  label: string;
  mode?: string;
}

function Note({ entity, gameDims }: RenderProps<NoteProps>): ReactElement {
  const pos = entity.getPos();

  // Note styling
  const width = getPx(entity.dims.w);
  const height = getPx(entity.dims.h);
  const noteStyle = {
    position: 'absolute' as const,
    width,
    height,
    left: getLeft(gameDims, pos.x - entity.dims.w / 2, pos.y, width),
    top: getTop(gameDims, pos.y - entity.dims.h / 2, pos.x, height),
    textAlign: 'center' as const,
    lineHeight: height,
    borderRadius: getPx(3 * (entity.dims.b ?? 0)),
    backgroundColor: COLORS.malibu,
    overflow: 'hidden',
    zIndex: 4,
  };

  // Animation
  const noteVariants = {
    reset: {
      backgroundColor: noteStyle.backgroundColor,
      opacity: 1,
    },
    miss: {
      backgroundColor: COLORS.platinum,
      transition: { duration: 0.03 },
    },
    wrong: {
      backgroundColor: COLORS.mandy,
      transition: { duration: 0.03 },
    },
    right: {
      backgroundColor: [null, COLORS.malibu, COLORS.malibu] as PoofFrames,
      opacity: [1, 1, 0],
      transition: {
        duration: 0.27,
        times: [0, 0.22, 1],
        ease: ['easeOut', 'linear'],
      },
    },
    perfect: {
      backgroundColor: [
        null,
        COLORS.pastelGreen,
        COLORS.pastelGreen,
      ] as PoofFrames,
      opacity: [1, 1, 0],
      transition: {
        duration: 0.27,
        times: [0, 0.22, 1],
        ease: ['easeOut', 'linear'],
      },
    },
  };
  const animate = entity.mode ?? NOTE_MODES.reset;

  return (
    <motion.div
      initial={false}
      style={noteStyle}
      animate={animate ?? NOTE_MODES.reset}
      variants={noteVariants}
    >
      {entity.label}
    </motion.div>
  );
}

export default Note;
export type { NoteProps };
