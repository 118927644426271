import React, { ReactElement } from 'react';

import Header from '~/components/Header';
import Divider from '~/components/Divider';

function EmptyFace(): ReactElement {
  // Empty face styling
  const headerStyle = {
    marginTop: '0.25em',
  };

  return (
    <>
      <Header style={headerStyle}>404</Header>
      <Divider />
      This is not the card you are looking for.
    </>
  );
}

export default EmptyFace;
