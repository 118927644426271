export const STATUS_CODES = {
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  tooManyRequests: 429,
};

// Journal
export const JOURNAL_OFFSETS = {
  device: Math.min(-1 * new Date().getTimezoneOffset(), 12 * 60),
  ohio: -5 * 60,
  frankfurt: 2 * 60,
}; // For some reason JavaScript uses minutes

export const JOURNAL_REGIONS = {
  ohio: '',
  frankfurt: '-eu',
};

export const JOURNAL_URL = process.env.REACT_APP_JOURNAL_URL;
export const JOURNAL_SUBDOMAIN = process.env.REACT_APP_JOURNAL_SUBDOMAIN;

// Google Analytics
export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
