import React, { ReactElement, CSSProperties } from 'react';

import COLORS from '~/constants/colors';
import Triangle from '~/components/Triangle';
import { stopEventEffects } from '~/utils';

import { DeviceState } from '../models';

interface MediaControlsProps {
  deviceState: DeviceState;
  onStop?: () => void | Promise<void>;
  onStart?: () => void | Promise<void>;
  onPause?: () => void | Promise<void>;
  onResume?: () => void | Promise<void>;
  loading?: boolean;
}

function MediaControls({
  deviceState,
  onStop,
  onStart,
  onPause,
  onResume,
  loading,
}: MediaControlsProps): ReactElement {
  const startWidth = '5em';
  const stopWidth = `calc(${startWidth} / ${Math.sqrt(1.9)})`;
  const pauseHeight = `calc(${stopWidth} / 2)`;
  const pauseBarWidth = `calc(${stopWidth} / 6)`;
  const pauseBarGap = `calc(${pauseBarWidth} / 5)`;
  const gutterWidth = '0.46em';
  const smallGutterWidth = `calc(${gutterWidth} / 3)`;
  const shellWidth = `calc(${startWidth} + 2 * ${gutterWidth})`;
  const smallShellWidth = `calc(${shellWidth} / 2)`;
  const wingWidth = `calc(${smallShellWidth} + ${gutterWidth})`;

  // Handlers
  const handleStart = async (event) => {
    stopEventEffects(event);
    await onStart?.();
  };
  const handlePause = async (event) => {
    stopEventEffects(event);
    await onPause?.();
  };
  const handleResume = async (event) => {
    stopEventEffects(event);
    await onResume?.();
  };
  const handleStop = async (event) => {
    stopEventEffects(event);
    await onStop?.();
  };

  // Media controls styling
  const mediaControlsStyle = {
    display: 'inline-block',
  };
  const shellStyle = {
    position: 'relative' as const,
    width: shellWidth,
    height: shellWidth,
    borderRadius: shellWidth,
    border: `1px solid ${COLORS.doveGray}`,
    boxShadow: `-0.13em 0.13em ${COLORS.black25}`,
    cursor: 'pointer',
  };
  const smallShellStyle = {
    ...shellStyle,
    width: smallShellWidth,
    height: smallShellWidth,
    borderRadius: smallShellWidth,
  };
  const startStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: startWidth,
    height: startWidth,
    borderRadius: startWidth,
    backgroundColor: COLORS.mandy,
  };
  const stopStyle = {
    ...startStyle,
    width: stopWidth,
    height: stopWidth,
    borderRadius: '0.3em',
  };
  const pauseStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: pauseHeight,
    height: pauseHeight,
  };
  const pauseBarStyle = {
    display: 'inline-block',
    width: pauseBarWidth,
    height: '100%',
    margin: `0 ${pauseBarGap}`,
    borderRadius: smallGutterWidth,
    backgroundColor: COLORS.ghost,
  };
  const resumeStyle = {
    ...pauseStyle,
    transform: 'translate(-46%, -50%)',
  };
  const redoStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -53%)',
    fontSize: '4em',
    color: COLORS.cinderella,
  };
  const dotsStyle = {
    ...redoStyle,
    transform: 'translate(-50%, -75%)',
    fontSize: '4.5em',
  };
  const wingStyle = {
    width: wingWidth,
  };

  // Rendering
  const smallControlTitle =
    deviceState === 'running' ? 'Pause recording' : 'Resume recording';
  const smallControlHandler =
    deviceState === 'running' ? handlePause : handleResume;
  const smallControlStyle =
    deviceState === 'running' ? pauseStyle : resumeStyle;
  let controlTitle = 'Finish recording';
  let controlHandler = handleStop;
  let controlStyle: CSSProperties = stopStyle;

  if (deviceState === 'closed' || deviceState === 'done') {
    controlTitle =
      deviceState === 'closed' ? 'Start recording' : 'Record again';
    controlHandler = handleStart;
    controlStyle = startStyle;
  }

  return (
    <table style={mediaControlsStyle}>
      <tbody>
        <tr>
          {(deviceState === 'running' || deviceState === 'suspended') && (
            <td style={wingStyle}>
              <div
                role="none"
                onMouseUp={smallControlHandler}
                onTouchEnd={smallControlHandler}
                style={smallShellStyle}
              >
                <div title={smallControlTitle} style={smallControlStyle}>
                  {deviceState === 'running' ? (
                    <>
                      <div style={pauseBarStyle} />
                      <div style={pauseBarStyle} />
                    </>
                  ) : (
                    <Triangle
                      base={pauseHeight}
                      ratio={0.75}
                      direction="right"
                      fill={COLORS.ghost}
                      style={resumeStyle}
                    />
                  )}
                </div>
              </div>
            </td>
          )}
          <td>
            <div
              role="none"
              onMouseUp={controlHandler}
              onTouchEnd={controlHandler}
              style={shellStyle}
            >
              <div title={controlTitle} style={controlStyle}>
                {!loading && deviceState === 'done' && (
                  <b style={redoStyle}>↺</b>
                )}
                {loading && <b style={dotsStyle}>...</b>}
              </div>
            </div>
          </td>
          {(deviceState === 'running' || deviceState === 'suspended') && (
            <td style={wingStyle} />
          )}
        </tr>
      </tbody>
    </table>
  );
}

export default MediaControls;
