import React, { ReactElement } from 'react';

import { FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';
import { useThemeContext } from '~/providers/ThemeProvider';

import { SwitchProps } from '../models';

function TextSwitch({
  label,
  active = false,
  callback,
  style,
  activeStyle,
  ...props
}: SwitchProps): ReactElement {
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    callback?.(event);
  };

  // Text switch styling
  const { theme } = useThemeContext().state;
  const textSwitchStyle = {
    display: 'inline-block',
    margin: '0 0.75em',
    lineHeight: '1.3em',
    fontSize: FONT_SIZES.action,
    color: theme.neutral.default.color,
    fontWeight: FONT_WEIGHTS.light,
    textAlign: 'center' as const,
    outline: 'none',
    cursor: 'pointer',
    ...style,
  };
  const textSwitchActiveStyle = {
    ...textSwitchStyle,
    color: theme.neutral.active?.borderColor,
    ...activeStyle,
  };
  const textSwitchLabelStyle = {
    pointerEvents: 'none' as const,
  };

  return (
    <div
      role="none"
      onMouseDown={handleClick}
      onTouchStart={handleClick}
      style={active ? textSwitchActiveStyle : textSwitchStyle}
      {...props}
    >
      <span style={textSwitchLabelStyle}>{label}</span>
    </div>
  );
}

export default TextSwitch;
