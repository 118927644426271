import React, { ReactElement, useState, CSSProperties } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import { useAuthContext } from '~/providers/AuthProvider';
import { CONDITIONS } from '~/providers/ThemeProvider';
import {
  stopEventEffects,
  getRelativeUrl,
  getJournalFormErrorHandler,
} from '~/utils';
import Button from '~/components/Button';
import PATHS from '~/constants/paths';
import Annotate, { Orientation } from '~/components/Annotate';
import Link from '~/components/Link';

import { DEFAULT_RECORD_INFO } from '../constants/defaults';
import { STATES } from '../constants/content';
import { useRecordContext } from '../providers/RecordProvider';
import { checkIdFormat } from '../utils';
import uris from '../uris';

interface LearnButtonProps {
  techniqueId?: number;
  orientation?: Orientation;
  style?: CSSProperties;
}

function LearnButton({
  techniqueId,
  orientation = 'right',
  style,
}: LearnButtonProps): ReactElement {
  const [showAnnotate, setShowAnnotate] = useState(false);
  const [fail, setFail] = useState<string>();
  const { state: authState } = useAuthContext();
  const { state: recordState, addRecord, deleteRecord } = useRecordContext();
  const navState = { from: getRelativeUrl() };
  const annotateCallback = () => setShowAnnotate(false);
  const record = recordState.records?.find(
    (r) => r.technique.id === techniqueId
  );

  // Form
  const defaultValues = DEFAULT_RECORD_INFO;
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues });
  const stateOpts = {
    required: true,
    validate: (value) => Object.values(STATES).includes(value),
  };
  const techniqueOpts = {
    required: true,
    validate: (value) => checkIdFormat(value),
  };

  register('state', stateOpts);
  register('technique', techniqueOpts);

  // Handlers
  const navigate = useNavigate();
  const onSubmit = (recordInfo) => {
    return new Promise<void>((resolve) => {
      if (authState.isAuthed)
        addRecord(recordInfo)
          .then(() => {
            if (!recordState.records?.length) setShowAnnotate(true);
            resolve();
          })
          .catch(
            getJournalFormErrorHandler(
              DEFAULT_RECORD_INFO,
              setError,
              setFail,
              resolve
            )
          );
      else navigate(PATHS.login, { state: navState });
    });
  };
  const handleValue = (event) => {
    stopEventEffects(event);
    if (record)
      deleteRecord(record.id).catch(
        getJournalFormErrorHandler(DEFAULT_RECORD_INFO, setError, setFail)
      );
    else {
      setValue('state', STATES.learning, { shouldValidate: true });
      setValue('technique', techniqueId, { shouldValidate: true });
      handleSubmit(onSubmit)();
    }
  };

  // Rendering
  const buttonLabel = record ? 'Saved' : 'Learn';
  const buttonCondition = record ? CONDITIONS.secondary : CONDITIONS.primary;

  // Learn button styling
  const learnButtonStyle = {
    display: 'inline-block',
    position: 'relative' as const,
    ...style,
  };
  const buttonStyle = {
    width: '3.75em',
    lineHeight: 'calc(1em - 2px)',
    padding: '0.3em',
    borderRadius: '0.3em',
    fontSize: FONT_SIZES.text,
    boxShadow: undefined,
  };
  const contentStyle = {
    color: COLORS.white,
  };

  // Annotate props
  const annotateProps = {
    callback: annotateCallback,
    callbackBoundary: 'container',
    orientation,
    skew: 0.5,
    containerWidth: '7.5em',
    containerHeight: '4.5em',
    containerBackgroundColor: COLORS.luckyPoint,
    contentStyle,
  };
  const errorAnnotateProps = {
    ...annotateProps,
    containerWidth: '6em',
    containerHeight: '3.5em',
    containerBackgroundColor: COLORS.mandy,
  };

  return (
    <div style={learnButtonStyle}>
      <Button
        label={buttonLabel}
        loadingLabel={buttonLabel}
        condition={buttonCondition}
        loading={isSubmitting}
        onMouseUp={handleValue}
        onTouchEnd={handleValue}
        style={buttonStyle}
      />
      <Annotate visible={showAnnotate} {...annotateProps}>
        Manage saved techniques in <Link href={uris.records}>Records</Link>
      </Annotate>
      <Annotate visible={!!(fail || errors.state)} {...errorAnnotateProps}>
        {fail || errors.state?.message || 'Please try again later'}
      </Annotate>
    </div>
  );
}

export default LearnButton;
