import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
  CSSProperties,
} from 'react';

import { useThemeContext } from '~/providers/ThemeProvider';
import COLORS from '~/constants/colors';

interface CodeProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  highlight?: string;
  style?: CSSProperties;
}

function Code({
  highlight = COLORS.alto,
  style,
  children,
  ...props
}: PropsWithChildren<CodeProps>): ReactElement {
  const { theme } = useThemeContext().state;
  const codeStyle = {
    padding: '0.3em',
    fontSize: '0.9em',
    borderRadius: '0.3em',
    color: theme.neutral.default.color,
    backgroundColor: `${highlight}88`,
    ...style,
  };

  return (
    <code style={codeStyle} {...props}>
      {children}
    </code>
  );
}

export default Code;
