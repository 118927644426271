import React, {
  ReactElement,
  DetailedHTMLProps,
  HTMLAttributes,
  CSSProperties,
} from 'react';

import CircleSwitch from './components/CircleSwitch';
import { SwitchProps } from './models';

interface SwitchBoardProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  Switch?: (switchProps: SwitchProps) => ReactElement;
  labels: string[];
  choices: boolean[];
  callbacks?: ((event: Event) => void)[];
  style?: CSSProperties;
  switchStyle?: CSSProperties;
  switchActiveStyle?: CSSProperties;
}

function SwitchBoard({
  Switch = CircleSwitch,
  labels,
  choices,
  callbacks,
  style,
  switchStyle,
  switchActiveStyle,
  ...props
}: SwitchBoardProps): ReactElement {
  const getKey = (idx) => `SwitchBoardCircleSwitch${idx}`;

  // Switch board styling
  const switchBoardStyle = {
    display: 'inline-block',
    ...style,
  };

  return (
    <div style={switchBoardStyle} {...props}>
      {labels.map((label, idx) => (
        <Switch
          key={getKey(idx)}
          label={label}
          active={choices[idx]}
          callback={callbacks?.[idx]}
          style={switchStyle}
          activeStyle={switchActiveStyle}
        />
      ))}
    </div>
  );
}

export default SwitchBoard;
