import ROLES from '~/constants/roles';

import CONTENT from './content';
import BEATBOXDB_ROLES from './roles';

const orderedContent = [
  CONTENT.profiles,
  CONTENT.subjects,
  CONTENT.categories,
  CONTENT.techniques,
  CONTENT.demos,
  CONTENT.tutorials,
  CONTENT.records,
];

export default {
  [ROLES.public]: [],
  [ROLES.authed]: [orderedContent[6]],
  [BEATBOXDB_ROLES.beginner]: [orderedContent[4], orderedContent[6]],
  [BEATBOXDB_ROLES.experienced]: orderedContent.slice(4),
  [BEATBOXDB_ROLES.expert]: orderedContent.slice(2),
  [BEATBOXDB_ROLES.administrator]: orderedContent.slice(1),
};
