import journalUrls from '~/urls/journal';

export default {
  profiles: `${journalUrls.api}/profiles`,
  profile: (profileId) => `${journalUrls.api}/profiles/${profileId}`,

  // External
  discordInvite: 'https://discord.gg/AamYsuaJ3w',
  richardLinkedIn: 'https://www.linkedin.com/in/swrichard-hsu',
  kindredYouTube: 'https://www.youtube.com/@kindreon',
  dismayInstagram: 'https://www.instagram.com/dismayy0_0/',
  kalapuikkoInstagram: 'https://www.instagram.com/nollabbx/',
  justinInstagram: 'https://www.instagram.com/justintansc',
  trickyTelegram: 'https://t.me/victorpapou',
};
