import React, {
  ReactElement,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  CSSProperties,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { FONT_SIZES } from '~/constants/typography';
import { STATUS_CODES } from '~/constants/api';
import { CONDITIONS } from '~/providers/ThemeProvider';
import Link from '~/components/Link';
import Button from '~/components/Button';
import Divider from '~/components/Divider';
import Announcement from '~/components/Announcement';
import Break from '~/components/Break';

import { createClip, muxVideo } from '../../services';
import uris from '../../uris';
import { cookOffline } from '../../utils';
import { PRESETS, PRESET_CONFIGS } from '../../constants/mastering';
import { Show, ListenControls } from '../../models';
import PresetButtons from './components/PresetButtons';
import DeleteModal from './components/DeleteModal';
import ShareUrl from './components/ShareUrl';
import CustomModal from './components/CustomModal';

interface ActionPanelProps {
  cookedUrl: string;
  cookedMedia: Blob;
  setCookedMedia: Dispatch<SetStateAction<Blob | undefined>>;
  setErrorText: Dispatch<SetStateAction<string>>;
  config?: Record<string, any>;
  rawAudioBuffer?: AudioBuffer;
  code?: string;
  show?: Show;
  setListenControls?: Dispatch<SetStateAction<ListenControls>>;
  style?: CSSProperties;
}

function ActionPanel({
  cookedUrl,
  cookedMedia,
  setCookedMedia,
  setErrorText,
  config,
  rawAudioBuffer,
  code,
  show,
  setListenControls,
  style,
}: ActionPanelProps): ReactElement {
  const [showDownload, setShowDownload] = useState(false);
  const [cookingPreset, setCookingPreset] = useState('');
  const [mastering, setMastering] = useState(config?.mastering ?? PRESETS.raw);
  const [isLoading, setIsLoading] = useState(false);
  const [showPublishWarning, setShowPublishWarning] = useState(false);
  const [showMasterWarning, setShowMasterWarning] = useState(false);
  const cookedPresetsRef = useRef<Record<string, Blob>>({
    [mastering]: cookedMedia,
  });
  const navigate = useNavigate();

  // Format download name
  const downloadName = `beatbox-share_${
    new Date().toISOString().split('T')[0]
  }`;

  // Kitchen utensils
  const prepare = (preset) => {
    setCookingPreset(preset);
    if (cookedMedia.type.startsWith('video')) setShowMasterWarning(true);
  };
  const cleanup = () => {
    setCookingPreset('');
    if (cookedMedia.type.startsWith('video')) setShowMasterWarning(false);
  };
  const success = (preset, media) => {
    cookedPresetsRef.current[preset] = media;
    setCookedMedia(media);
    setMastering(preset);
    setErrorText('');
    cleanup();
  };
  const failure = (preset, error) => {
    delete cookedPresetsRef.current[preset];
    if (error.response?.status === STATUS_CODES.tooManyRequests)
      setErrorText('Server is tired, please try again in 30 seconds');
    else setErrorText('Offline cooking failed, please try again');
    cleanup();
  };
  const getCookedMedia = async (preset) => {
    if (rawAudioBuffer) {
      const newConfig = { preset: PRESET_CONFIGS[preset] };
      const newCookedAudio = await cookOffline(rawAudioBuffer, newConfig);

      if (cookedMedia.type.startsWith('video')) {
        const cookedVideo = await muxVideo(cookedMedia, newCookedAudio);

        return cookedVideo;
      }

      return newCookedAudio;
    }

    throw new Error('Raw audio not available');
  };

  // Handlers
  const handleCooking = (preset) => async () => {
    if (preset !== mastering) {
      prepare(preset);
      if (cookedPresetsRef.current[preset])
        success(preset, cookedPresetsRef.current[preset]);
      else if (!cookedPresetsRef.current[preset]) {
        try {
          const newCookedMedia = await getCookedMedia(preset);

          success(preset, newCookedMedia);
        } catch (error) {
          failure(preset, error);
        }
      }
    }
  };
  const handlePublish = async () => {
    if (!code) {
      try {
        setIsLoading(true);
        if (cookedMedia.type.startsWith('video')) setShowPublishWarning(true);

        // Make request
        const clip = await createClip(cookedMedia);

        setListenControls?.({ show, fromPublish: true });
        navigate(uris.listen(clip.code));
      } catch {
        setIsLoading(false);
        setShowPublishWarning(false);
        setShowDownload(true);
        setErrorText(
          'Upload failed, please try again. Download your recording to not lose the data.'
        );
      }
    }
  };

  // Action panel styling
  const actionPanelStyle = {
    textAlign: 'center' as const,
    ...style,
  };
  const actionStyle = {
    width: '6.3em',
    margin: '0 0.5em',
    fontSize: FONT_SIZES.text,
  };
  const publishWarningStyle = {
    width: '19em',
  };

  useEffect(() => {
    const newMastering = config?.mastering ?? PRESETS.raw;

    setMastering(newMastering);
    cookedPresetsRef.current = { [newMastering]: cookedMedia }; // Notice this depends on `rawAudioBuffer` being set after `cookedMedia` when original media changes
  }, [rawAudioBuffer]);

  return (
    <div style={actionPanelStyle}>
      {!code && (
        <PresetButtons
          mastering={mastering}
          cookingPreset={cookingPreset}
          getCallback={handleCooking}
        />
      )}
      {showMasterWarning && (
        <>
          <Break />
          <Announcement
            condition={CONDITIONS.warning}
            style={publishWarningStyle}
          >
            Longer videos may take a while to master
          </Announcement>
        </>
      )}
      {code && <ShareUrl code={code} />}
      <Divider />
      {showPublishWarning && (
        <>
          <Announcement
            condition={CONDITIONS.warning}
            style={publishWarningStyle}
          >
            Longer videos may take a while to publish
          </Announcement>
          <Break />
        </>
      )}
      {!showDownload && !code && rawAudioBuffer && (
        <CustomModal
          cookedMedia={cookedMedia}
          rawAudioBuffer={rawAudioBuffer}
          setCookedMedia={setCookedMedia}
          setMastering={setMastering}
          setErrorText={setErrorText}
        />
      )}
      {(showDownload || code) && (
        <Link download={downloadName} href={cookedUrl}>
          <Button
            condition={CONDITIONS.secondary}
            label="Download"
            style={actionStyle}
          />
        </Link>
      )}
      {!code ? (
        <Button
          loading={isLoading}
          callback={handlePublish}
          label="Publish"
          style={actionStyle}
        />
      ) : (
        <DeleteModal code={code} setErrorText={setErrorText} show={show} />
      )}
    </div>
  );
}

export default ActionPanel;
