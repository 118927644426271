// Local storage
export const getStorageItem = (key: string): any | null => {
  const item = localStorage.getItem(key);

  return item && JSON.parse(item);
};

export const setStorageItem = (key: string, value: any): void =>
  localStorage.setItem(key, JSON.stringify(value));

export const removeStorageItem = (key: string): void =>
  localStorage.removeItem(key);
