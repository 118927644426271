import { chainMiddlewares } from '~/utils';
import { Reducer } from '~/models';

import { ThemeState } from './models';
import { ACTIONS } from './actions';
import { THEMES } from './constants';

const INITIAL_STATE: ThemeState = { theme: THEMES.light };

const reducer: Reducer<ThemeState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.switchTheme:
      return { ...state, theme: THEMES[action.payload] };
    default:
      return state;
  }
};

export default chainMiddlewares(reducer);
export { INITIAL_STATE };
