import { Profile } from '~/models';

// Social
export const DEFAULT_PROFILE: Profile = {
  id: 0,
  handle: 'ball', // TODO: replace math joke with something proper
  claimed: true,
};

// Pagination
export const DEFAULT_PAGE_SIZE = 15;
