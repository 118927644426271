import React, {
  ReactElement,
  useLayoutEffect,
  forwardRef,
  ForwardedRef,
  useState,
  useImperativeHandle,
} from 'react';

import Modal from '~/components/Modal';
import Button from '~/components/Button';
import { CONDITIONS } from '~/providers/ThemeProvider';
import Gap from '~/components/Gap';

import CONTENT from '../../constants/content';
import { useBeatboxContext } from '../../providers/BeatboxProvider';
import { checkIdFormat } from '../../utils';
import { ContentCardRef } from './models';
import TechniqueFace from './components/TechniqueFace';
import DemoFace from './components/DemoFace';
import TutorialFace from './components/TutorialFace';

interface ContentCardProps {
  ref: ForwardedRef<ContentCardRef>;
}

function ContentCard({ ref }: ContentCardProps): ReactElement {
  const { state: beatboxState, setDemo, setTutorial } = useBeatboxContext();
  const [contentType, setContentType] = useState<string>();
  const [contentId, setContentId] = useState<number>();
  const [showCard, setShowCard] = useState(!!(contentType && contentId));
  const checkContentId = (id, content?) =>
    id && checkIdFormat(id) && (!content || Number(id) !== content.id);
  const getSafeId = (id) => (checkContentId(id) ? Number(id) : undefined);
  const showContent = (type) => (id) => {
    setContentType(type);
    setContentId(getSafeId(id));
    setShowCard(true);
  };

  useImperativeHandle(ref, () => ({
    showTechnique: showContent(CONTENT.techniques),
    showDemo: showContent(CONTENT.demos),
    showTutorial: showContent(CONTENT.tutorials),
  }));

  // Content card styling
  const contentCardStyle = {
    minWidth: 'min(90vw, 480px)',
  };
  const faceStyle = {
    maxWidth: '480px',
    overflow: 'hidden',
  };

  // Load card info
  useLayoutEffect(() => {
    (async () => {
      if (
        contentType === CONTENT.demos &&
        checkContentId(contentId, beatboxState.demo)
      )
        await setDemo(contentId as number);
      else if (
        contentType === CONTENT.tutorials &&
        checkContentId(contentId, beatboxState.tutorial)
      )
        await setTutorial(contentId as number);
    })();
  }, [contentType, contentId]);

  return (
    <Modal
      visible={showCard}
      callback={() => setShowCard(false)}
      style={contentCardStyle}
    >
      <div style={faceStyle}>
        {contentType === CONTENT.techniques && (
          <TechniqueFace techniqueId={contentId} />
        )}
        {contentType === CONTENT.demos && <DemoFace />}
        {contentType === CONTENT.tutorials && <TutorialFace />}
      </div>
      <Gap />
      <Gap />
      <Button
        label="Close"
        width="100%"
        condition={CONDITIONS.warning}
        callback={() => setShowCard(false)}
      />
    </Modal>
  );
}

export default forwardRef((props, ref: ForwardedRef<ContentCardRef>) =>
  ContentCard({ ref })
);
