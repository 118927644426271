import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import { FONT_WEIGHTS } from '~/constants/typography';

import { RendererProps } from './models';
import { ExitPredicate } from '../predicates/models';

function Exit({ predicate }: RendererProps<ExitPredicate>): ReactElement {
  const pos = predicate.getPos();

  // Exit styling
  const getPx = (v) => `${v}px`;
  const exitB = predicate.dims.b ?? 0;
  const exitM = predicate.dims.m ?? 0;
  const exitStyle = {
    position: 'absolute' as const,
    width: getPx(predicate.dims.w),
    height: getPx(predicate.dims.h),
    lineHeight: getPx(predicate.dims.h),
    top: getPx(pos.y + exitM),
    left: getPx(pos.x - predicate.dims.w - 1.3 * exitM),
    backgroundColor: COLORS.dreamlessSleep,
    borderRadius: getPx(exitB),
    borderStyle: 'solid',
    borderWidth: getPx(exitB / 20),
    borderColor: COLORS.mineShaft,
    cursor: 'pointer',
    ...predicate.style,
  };
  const xStyle = {
    display: 'inline-block',
    position: 'relative' as const,
    marginTop: getPx(-predicate.dims.h / 5),
    lineHeight: 'normal',
    verticalAlign: 'middle',
    color: COLORS.white50,
    fontSize: getPx(predicate.dims.w / 1.2),
    fontWeight: FONT_WEIGHTS.thin,
  };

  return (
    <div role="none" style={exitStyle} onClick={predicate.callback}>
      <div style={xStyle}>×</div>
    </div>
  );
}

export default Exit;
