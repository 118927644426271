import React, {
  ReactElement,
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useEffect,
} from 'react';
import { isMobile } from 'react-device-detect';

import InputPanel, {
  InputPanelMeas,
  DEFAULT_INPUT_PANEL_MEAS,
} from './InputPanel';
import { LABELS, KEYS, INDEX_MAP } from '../constants/kana';
import { INPUT_MODES } from '../constants/layout';
import PHASES from '../constants/phases';

interface FullPadProps {
  callback: (string) => void;
  panelMeas?: InputPanelMeas;
  inputMode?: string;
  whichPadLabels?: string;
  targetLabel?: string;
  guidance?: boolean;
  showAllHints?: boolean;
  phase?: string;
  style?: CSSProperties;
  ref?: ForwardedRef<HTMLDivElement>;
}

function FullPad({
  callback,
  panelMeas = DEFAULT_INPUT_PANEL_MEAS,
  inputMode = INPUT_MODES.gym,
  whichPadLabels = 'hiragana',
  targetLabel = '',
  guidance = false,
  showAllHints = false,
  phase,
  style,
  ref,
}: FullPadProps): ReactElement {
  const labelsList = LABELS[whichPadLabels];
  const targetIdxs = INDEX_MAP[targetLabel];
  const answerIdx =
    inputMode !== INPUT_MODES.key && guidance ? targetIdxs[1] : undefined;
  const getKey = (idx) => `FlowGoFullPadInputPanel${idx}`;

  // Full pad styling
  const calcLen = (dim, m = 1) => `calc(${m} * (${dim} + ${panelMeas.space}))`;
  const jogging = inputMode === INPUT_MODES.jog;
  const fullPadWidth = calcLen(panelMeas.width, jogging ? 1 : 3);
  const fullPadStyle = {
    display: inputMode === INPUT_MODES.key ? 'none' : 'block',
    position: 'absolute' as const,
    width: fullPadWidth,
    height: calcLen(panelMeas.height, jogging ? 1 : 4),
    left: '50%',
    bottom: calcLen(panelMeas.height),
    marginLeft: `calc(-1 * ${fullPadWidth} / 2)`,
    touchAction: 'none',
    zIndex: 100,
    ...style,
  };

  // Handle address bar scroll layout issue on mobile
  useEffect(() => {
    if (isMobile) {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.width = '100%';
      document.body.style.height = '100%';
      document.body.style.position = 'fixed' as const;
      document.body.style.overflowY = 'scroll' as const;
      document.body.style['-webkit-overflow-scrolling'] = 'touch';
    }
  });

  // Handle play phase key mode inputs
  useEffect(() => {
    if (inputMode === INPUT_MODES.key && phase === PHASES.play)
      window.addEventListener('keyup', callback);

    return () => window.removeEventListener('keyup', callback);
  }, [inputMode, phase]);

  return (
    <div style={fullPadStyle} ref={ref}>
      {inputMode === INPUT_MODES.jog && (
        <InputPanel
          meas={panelMeas}
          labels={labelsList[targetIdxs[0]]}
          centerKeyCode="KeyS"
          callback={callback}
          answerIdx={answerIdx}
          showAllHints={showAllHints}
          phase={phase}
          ringDelay={0}
        />
      )}
      {inputMode === INPUT_MODES.gym &&
        labelsList.map((labels, idx) => (
          <InputPanel
            key={getKey(idx)}
            meas={panelMeas}
            labels={labels}
            centerKeyCode={KEYS[idx]}
            callback={callback}
            answerIdx={idx === targetIdxs?.[0] ? answerIdx : undefined}
            showAllHints={showAllHints}
            phase={phase}
          />
        ))}
    </div>
  );
}

export default forwardRef(
  (props: FullPadProps, ref: ForwardedRef<HTMLDivElement>) =>
    FullPad({ ...props, ref })
);
