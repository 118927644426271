import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { useNavigate, useLocation, To } from 'react-router-dom';

import { LocationState } from '~/models';
import { CONDITIONS } from '~/providers/ThemeProvider';
import PATHS from '~/constants/paths';
import COLORS from '~/constants/colors';
import { useAuthContext } from '~/providers/AuthProvider';
import FramedView from '~/components/FramedView';
import TextInput from '~/components/TextInput';
import Subtext from '~/components/Subtext';
import Button from '~/components/Button';
import Gap from '~/components/Gap';
import { getJournalFormErrorHandler } from '~/utils';
import Link from '~/components/Link';
import Announcement from '~/components/Announcement';

import { DEFAULT_SIGNUP } from '../constants';

function SignupForm(): ReactElement {
  const [fail, setFail] = useState<string | undefined>();
  const navigate = useNavigate();
  const { state } = useLocation() as { state: LocationState };
  const { signUp } = useAuthContext();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: DEFAULT_SIGNUP });
  const emailOpts = {
    required: true,
    validate: (email) => validator.isEmail(email),
  };
  const passwordOpts = { required: true, minLength: 6 };
  const handleOpts = {
    required: true,
    validate: (handle) => validator.isAlphanumeric(handle),
  };
  const signupButtonText = 'Sign Up';
  const signupButtonLoadingText = 'Registering...';
  const cancelButtonText = 'Cancel';

  // Handlers
  const onSignUp = (signupInfo) => {
    return new Promise<void>((resolve) => {
      setFail(undefined);
      signUp(signupInfo)
        .then(() => navigate(state?.from || PATHS.root))
        .catch(
          getJournalFormErrorHandler(DEFAULT_SIGNUP, setError, setFail, resolve)
        );
    });
  };
  const handleCancel = () => navigate((state?.from || -1) as To);

  // Signup form styling
  const textInputStyle = {
    width: 'min(320px, 75vw)',
  };
  const captionStyle = {
    textAlign: 'center' as const,
  };
  const buttonHolderStyle = {
    textAlign: 'center' as const,
  };
  const signupButtonStyle = {
    width: '63%',
    marginRight: '3%',
  };
  const cancelButtonStyle = {
    width: '33%',
  };
  const announcementStyle = {
    width: 'min(calc(320px - 1.5em), calc(75vw - 1.5em))',
  };

  return (
    <FramedView accent>
      <Gap />
      <div style={captionStyle}>
        Already have an account?{' '}
        <Link to={PATHS.login} state={state}>
          Log in
        </Link>
        !
      </div>
      <br />
      {fail && (
        <>
          <Announcement style={announcementStyle}>{fail}</Announcement>
          <Gap />
        </>
      )}
      <form>
        <div>
          <TextInput
            {...register('email', emailOpts)}
            type="email"
            placeholder="Email"
            style={textInputStyle}
          />
          {errors.email && (
            <Subtext color={COLORS.mandy}>
              {errors.email.message || 'Valid email required'}
            </Subtext>
          )}
        </div>
        <Gap />
        <div>
          <TextInput
            {...register('password', passwordOpts)}
            type="password"
            placeholder="Password"
            style={textInputStyle}
          />
          {errors.password && (
            <Subtext color={COLORS.mandy}>
              {errors.password.message ||
                'Password with at least 6 characters required'}
            </Subtext>
          )}
        </div>
        <Gap />
        <div>
          <TextInput
            {...register('handle', handleOpts)}
            placeholder="Handle"
            style={textInputStyle}
          />
          {errors.handle && (
            <Subtext color={COLORS.mandy}>
              {errors.handle.message ||
                'Handle with only letters and numbers required'}
            </Subtext>
          )}
        </div>
        <Gap />
        <div style={buttonHolderStyle}>
          <Button
            label={signupButtonText}
            loadingLabel={signupButtonLoadingText}
            loading={isSubmitting}
            onClick={handleSubmit(onSignUp)}
            onTouchEnd={handleSubmit(onSignUp)}
            style={signupButtonStyle}
          />
          <Button
            label={cancelButtonText}
            condition={CONDITIONS.secondary}
            onClick={handleCancel}
            onTouchEnd={handleCancel}
            style={cancelButtonStyle}
          />
        </div>
      </form>
    </FramedView>
  );
}

export default SignupForm;
