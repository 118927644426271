import { MutableRefObject } from 'react';
import GameEngine from 'react-game-engine';

import { Dim, RecordPadPredicate } from './models';
import { PANEL_DIMS } from '../constants/layout';

const recordPadPredicate = (
  windowDims: Dim,
  gameEngineRef: MutableRefObject<GameEngine>
): RecordPadPredicate => {
  const panelDims = PANEL_DIMS(windowDims).jog;
  const panelS = 2 * panelDims.b + 2 * panelDims.m;
  const panelWidth = panelDims.w + panelS;
  const panelHeight = panelDims.h + panelS;

  // Record pad styling
  const recordPadStyle = {
    zIndex: 10,
  };

  return {
    dims: { w: panelWidth, h: panelHeight },
    getPos: () => ({
      x: windowDims.w / 2,
      y: windowDims.h - (3 * panelHeight) / 2,
    }),
    panelDims,
    gameEngineRef,
    style: recordPadStyle,
  };
};

export default recordPadPredicate;
