import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  CSSProperties,
  ReactElement,
  useState,
  useEffect,
  forwardRef,
  ForwardedRef,
  FocusEvent,
  MouseEvent,
} from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import { useThemeContext, CONDITIONS } from '~/providers/ThemeProvider';

interface TextInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  condition?: string;
  onMouseOver?: (event: MouseEvent) => void;
  onFocus?: (event: FocusEvent) => void;
  onBlur?: (event: FocusEvent) => void;
  disabled?: boolean;
  spacer?: ReactElement;
  style?: CSSProperties;
}

function TextInput({
  condition = CONDITIONS.neutral,
  onMouseOver,
  onFocus,
  onBlur,
  disabled = false,
  spacer,
  style,
  ...props
}: TextInputProps): ReactElement {
  // Text input styling
  const { theme } = useThemeContext().state;
  const textInputState = disabled
    ? theme.disabled.default
    : theme[condition].default;
  const disabledProps = disabled && {
    disabled,
    placeholder: props.placeholder ?? 'Input disabled',
  };
  const baseState = disabled ? theme.disabled.default : theme.neutral.default;
  const outerStyle = {
    display: spacer ? 'inline-flex' : 'inline-block',
    padding: '0.4em 0.4em',
    borderRadius: '0.3em',
    borderStyle: 'solid',
    borderWidth: 'thin',
    backgroundClip: 'padding-box',
    // From theme
    backgroundColor: baseState.backgroundColor,
    borderColor: baseState.borderColor,
  };
  const outerPropsStyle = Object.keys(style ?? {}).reduce(
    (acc, k) => (outerStyle[k] ? { ...acc, [k]: style?.[k] } : acc),
    {}
  );
  const innerPropsStyle = Object.keys(style ?? {}).reduce(
    (acc, k) => (!outerStyle[k] ? { ...acc, [k]: style?.[k] } : acc),
    {}
  );
  const innerStyle = {
    fontSize: FONT_SIZES.text,
    borderRadius: style?.borderRadius ?? outerStyle.borderRadius,
    borderStyle: 'none',
    outline: 'none',
    backgroundColor: COLORS.transparent,
    // From theme
    color: style?.color ?? baseState.color,
    ...(spacer && { flexGrow: 2, ...innerPropsStyle }),
  };
  const getStyle = (state) => ({
    ...(!spacer && innerStyle),
    ...outerStyle,
    ...(state.borderColor && { borderColor: state.borderColor }),
    ...(spacer ? { flexDirection: 'row' as const, ...outerPropsStyle } : style),
  });
  const [textInputStyle, setTextInputStyle] = useState(
    getStyle(textInputState)
  );

  // Event handlers
  const [focused, setFocused] = useState(false);
  const handleMouseOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!disabled) {
      onMouseOver?.(event);
      if (!focused && theme[condition].hover)
        setTextInputStyle(getStyle(theme[condition].hover));
    }
  };
  const handleMouseLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!disabled && !focused)
      setTextInputStyle(getStyle(theme[condition].default));
  };
  const handleFocus = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!disabled) {
      setFocused(true);
      onFocus?.(event);
      if (theme[condition].active)
        setTextInputStyle(getStyle(theme[condition].active));
    }
  };
  const handleBlur = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!disabled) {
      setFocused(false);
      onBlur?.(event);
      setTextInputStyle(getStyle(theme[condition].default));
    }
  };

  useEffect(
    () => setTextInputStyle(getStyle(textInputState)),
    [theme, condition, disabled]
  );

  return spacer ? (
    <div
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={textInputStyle}
    >
      <input style={innerStyle} {...disabledProps} {...props} />
      {spacer}
    </div>
  ) : (
    <input
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={textInputStyle}
      {...disabledProps}
      {...props}
    />
  );
}

export default forwardRef(
  (props: TextInputProps, ref: ForwardedRef<HTMLInputElement>) =>
    TextInput({ ...props, ref })
);
