import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';

function Ponder(): ReactElement {
  // Ponder styling
  const ponderStyle = {
    width: '100%',
    height: '100vh', // TODO: delete this property once you figure out proper background
    backgroundColor: COLORS.white, // TODO: delete this property once you figure out proper background
    fontSize: FONT_SIZES.text,
    textAlign: 'center' as const,
  };

  return <div style={ponderStyle}>Under construction... 🧰🙏</div>;
}

export default Ponder;
