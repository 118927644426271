import { Orientation } from './models';
import { Direction } from '../Triangle';

export const getTriangleDirection = (orientation: Orientation): Direction => {
  if (orientation === 'above') return 'down';
  else if (orientation === 'right') return 'left';
  else if (orientation === 'below') return 'up';

  return 'right';
};

export const getOrientationStyles = (
  skew: number,
  triangleAltitude: string
): Record<string, any> => ({
  above: {
    container: {
      top: 0,
      left: '50%',
      transform: `translate(-50%, calc(-100% - 1.5 * ${triangleAltitude}))`,
    },
    triangle: {
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%, 90%)',
    },
    content: {
      transform: `translateX(calc(${skew} * 50%))`,
    },
  },
  right: {
    container: {
      top: '50%',
      left: '100%',
      transform: `translate(calc(1.5 * ${triangleAltitude}), -50%)`,
    },
    triangle: {
      top: '50%',
      left: 0,
      transform: 'translate(-90%, -50%)',
    },
    content: {
      transform: `translateY(calc(${skew} * 50%))`,
    },
  },
  below: {
    container: {
      bottom: 0,
      left: '50%',
      transform: `translate(-50%, calc(100% + 1.5 * ${triangleAltitude}))`,
    },
    triangle: {
      top: 0,
      left: '50%',
      transform: 'translate(-50%, -90%)',
    },
    content: {
      transform: `translateX(calc(${skew} * 50%))`,
    },
  },
  left: {
    container: {
      top: '50%',
      left: 0,
      transform: `translate(calc(-100% - 1.5 * ${triangleAltitude}), -50%)`,
    },
    triangle: {
      top: '50%',
      right: 0,
      transform: 'translate(90%, -50%)',
    },
    content: {
      transform: `translateY(calc(${skew} * 50%))`,
    },
  },
});
