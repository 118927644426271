import React, {
  useReducer,
  useMemo,
  PropsWithChildren,
  ReactElement,
  useLayoutEffect,
} from 'react';

import { createSafeContext } from '~/utils';

import CONTENT from '../../constants/content';
import { BeatboxContext, BeatboxState, Technique } from './models';
import {
  setBeatboxerThunk,
  listProfilesThunk,
  setSubjectThunk,
  getAddThunk,
  getGetThunk,
  getSetThunk,
  getListThunk,
  getListWantedThunk,
  listMainSearchTechniquesThunk,
  getEditThunk,
  getDeleteThunk,
} from './actions';
import reducer, { INITIAL_STATE } from './reducer';

const [useBeatboxContext, BeatboxContextProvider] =
  createSafeContext<BeatboxContext>();

type BeatboxProviderProps = Record<never, never>;

function BeatboxProvider({
  children,
}: PropsWithChildren<BeatboxProviderProps>): ReactElement {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  // Connect thunks
  const setBeatboxer = setBeatboxerThunk(dispatch);
  const listProfiles = listProfilesThunk(dispatch);
  const setSubject = setSubjectThunk(dispatch);
  const addCategory = getAddThunk(CONTENT.categories)(dispatch, state);
  const setCategory = getSetThunk(CONTENT.categories)(dispatch);
  const listCategories = getListThunk(CONTENT.categories)(dispatch, state);
  const editCategory = getEditThunk(CONTENT.categories)(dispatch);
  const deleteCategory = getDeleteThunk(CONTENT.categories)(dispatch);
  const addTechnique = getAddThunk(CONTENT.techniques)(dispatch, state);
  const getTechnique = getGetThunk<Technique>(CONTENT.techniques);
  const setTechnique = getSetThunk(CONTENT.techniques)(dispatch);
  const listTechniques = getListThunk(CONTENT.techniques)(dispatch, state);
  const listWantedTechniques = getListWantedThunk(CONTENT.techniques)(
    dispatch,
    state
  );
  const listMainSearchTechniques = listMainSearchTechniquesThunk(
    dispatch,
    state
  );
  const editTechnique = getEditThunk(CONTENT.techniques)(dispatch);
  const deleteTechnique = getDeleteThunk(CONTENT.techniques)(dispatch);
  const addDemo = getAddThunk(CONTENT.demos)(dispatch, state);
  const setDemo = getSetThunk(CONTENT.demos)(dispatch);
  const listDemos = getListThunk(CONTENT.demos)(dispatch, state);
  const listWantedDemos = getListWantedThunk(CONTENT.demos)(dispatch, state);
  const editDemo = getEditThunk(CONTENT.demos)(dispatch);
  const deleteDemo = getDeleteThunk(CONTENT.demos)(dispatch);
  const addTutorial = getAddThunk(CONTENT.tutorials)(dispatch, state);
  const setTutorial = getSetThunk(CONTENT.tutorials)(dispatch);
  const listTutorials = getListThunk(CONTENT.tutorials)(dispatch, state);
  const listWantedTutorials = getListWantedThunk(CONTENT.tutorials)(
    dispatch,
    state
  );
  const editTutorial = getEditThunk(CONTENT.tutorials)(dispatch);
  const deleteTutorial = getDeleteThunk(CONTENT.tutorials)(dispatch);

  // Set up value
  const value = useMemo(() => {
    return {
      state: state as BeatboxState,
      setBeatboxer,
      listProfiles,
      addCategory,
      setCategory,
      listCategories,
      editCategory,
      deleteCategory,
      addTechnique,
      getTechnique,
      setTechnique,
      listTechniques,
      listWantedTechniques,
      listMainSearchTechniques,
      editTechnique,
      deleteTechnique,
      addDemo,
      setDemo,
      listDemos,
      listWantedDemos,
      editDemo,
      deleteDemo,
      addTutorial,
      setTutorial,
      listTutorials,
      listWantedTutorials,
      editTutorial,
      deleteTutorial,
    };
  }, [state]);

  // Set subject
  useLayoutEffect(() => {
    (async () => {
      if (!state.subject) await setSubject();
    })();
  }, []);

  return (
    <BeatboxContextProvider value={value}>{children}</BeatboxContextProvider>
  );
}

export default BeatboxProvider;
export { useBeatboxContext };
