import React, { ReactElement, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { useNavigate } from 'react-router-dom';

import { CONDITIONS } from '~/providers/ThemeProvider';
import PATHS from '~/constants/paths';
import COLORS from '~/constants/colors';
import { useAuthContext } from '~/providers/AuthProvider';
import FramedView from '~/components/FramedView';
import TextInput from '~/components/TextInput';
import Subtext from '~/components/Subtext';
import Button from '~/components/Button';
import Gap from '~/components/Gap';
import { getJournalFormErrorHandler } from '~/utils';
import Announcement from '~/components/Announcement';
import Prompt from '~/components/Prompt';
import Divider from '~/components/Divider';
import Modal from '~/components/Modal';

import { DEFAULT_SETTINGS_INFO, DEFAULT_PASSWORD_INFO } from '../constants';

type SettingsKeys = 'email' | 'handle';

// TODO: add profile picture field
function SettingsForms(): ReactElement {
  const navigate = useNavigate();
  const {
    state: authState,
    updateSettings,
    changePassword,
    deleteAccount,
  } = useAuthContext();

  // General settings form
  const [settingsFail, setSettingsFail] = useState<string | undefined>();
  const [showSettingsSuccess, setShowSettingsSuccess] = useState(false);
  const settingsInfoKeys = Object.keys(DEFAULT_SETTINGS_INFO);
  const defaultSettingsInfo = settingsInfoKeys.reduce(
    (acc, k) => ({
      ...acc,
      ...(authState.user && { [k]: authState.user[k] }),
    }),
    DEFAULT_SETTINGS_INFO
  );
  const {
    register: settingsRegister,
    handleSubmit: handleSettingsSubmit,
    setError: setSettingsError,
    getValues: getSettingsValues,
    reset: settingsReset,
    formState: { errors: settingsErrors, isSubmitting: isSubmittingSettings },
  } = useForm({ defaultValues: defaultSettingsInfo });
  const emailOpts = {
    required: true,
    validate: (email) => validator.isEmail(email),
  };
  const handleOpts = {
    required: true,
    validate: (handle) => validator.isAlphanumeric(handle),
  };
  const settingsButtonText = 'Update';
  const settingsButtonLoadingText = 'Changing...';
  const resetButtonText = 'Reset';

  // Change password form
  const [passwordFail, setPasswordFail] = useState<string | undefined>();
  const [showPasswordSuccess, setShowPasswordSuccess] = useState(false);
  const {
    register: passwordRegister,
    handleSubmit: handlePasswordSubmit,
    setError: setPasswordError,
    getValues: getPasswordValues,
    reset: passwordReset,
    formState: { errors: passwordErrors, isSubmitting: isSubmittingPassword },
  } = useForm({ defaultValues: DEFAULT_PASSWORD_INFO });
  const currentPasswordOpts = { required: true, minLength: 6 };
  const passwordOpts = {
    ...currentPasswordOpts,
    validate: (newPassword) =>
      newPassword !== getPasswordValues('currentPassword'),
  };
  const passwordButtonText = 'Confirm';
  const passwordButtonLoadingText = 'Revising...';

  // Delete account form
  const [showDeleter, setShowDeleter] = useState(false);
  const {
    handleSubmit: handleDeleteSubmit,
    formState: { isSubmitting: isSubmittingDelete },
  } = useForm();
  const deleteButtonText = 'Delete';
  const deleteButtonLoadingText = 'Removing...';

  // Handlers
  const onSettingsUpdate = (settingsInfo) => {
    return new Promise<void>((resolve) => {
      const nothingChanged = settingsInfoKeys.reduce(
        (acc, k) =>
          acc &&
          defaultSettingsInfo[k] === getSettingsValues(k as SettingsKeys),
        true
      );

      if (nothingChanged) {
        setSettingsFail('Please make a change to update');
      } else {
        setSettingsFail(undefined);
        setShowSettingsSuccess(false);
        updateSettings(settingsInfo)
          .then(() => {
            setShowSettingsSuccess(true);
          })
          .catch(
            getJournalFormErrorHandler(
              DEFAULT_SETTINGS_INFO,
              setSettingsError,
              setSettingsFail
            )
          );
      }
      resolve();
    });
  };
  const handleSettingsReset = () => {
    setSettingsFail(undefined);
    setShowSettingsSuccess(false);
    settingsReset();
  };
  const onPasswordConfirm = (passwordInfo) => {
    return new Promise<void>((resolve) => {
      setPasswordFail(undefined);
      setShowPasswordSuccess(false);
      changePassword(passwordInfo)
        .then(() => {
          passwordReset(DEFAULT_PASSWORD_INFO);
          setShowPasswordSuccess(true);
        })
        .catch(
          getJournalFormErrorHandler(
            DEFAULT_PASSWORD_INFO,
            setPasswordError,
            setPasswordFail
          )
        );
      resolve();
    });
  };
  const handleShowDeleter = () => setShowDeleter(true);
  const onDelete = () => {
    return new Promise<void>(() => {
      deleteAccount().then(() => {
        setShowDeleter(false);
        navigate(PATHS.root);
      });
    });
  };

  // Settings forms styling
  const textInputStyle = {
    width: 'min(320px, 75vw)',
  };
  const buttonHolderStyle = {
    textAlign: 'center' as const,
  };
  const primaryButtonStyle = {
    width: '63%',
    marginRight: '3%',
  };
  const resetButtonStyle = {
    width: '33%',
  };
  const announcementStyle = {
    width: 'min(calc(320px - 1.5em), calc(75vw - 1.5em))',
  };
  const deleteButtonStyle = {
    marginLeft: '2.5%',
  };
  const showDeleteStyle = {
    color: COLORS.mandy,
    cursor: 'pointer',
  };
  const deleterContainerStyle = {
    padding: '0em 0.5em',
  };
  const showDeleteContainerStyle = {
    textAlign: 'right' as const,
  };

  useEffect(() => settingsReset(defaultSettingsInfo), [authState.user]);

  return (
    <FramedView accent>
      {showSettingsSuccess && (
        <>
          <Announcement
            condition={CONDITIONS.success}
            style={announcementStyle}
          >
            Settings successfully updated!
          </Announcement>
          <Gap />
        </>
      )}
      {settingsFail && (
        <>
          <Announcement style={announcementStyle}>{settingsFail}</Announcement>
          <Gap />
        </>
      )}
      <form>
        <Prompt>What&apos;s your email?</Prompt>
        <Gap />
        <div>
          <TextInput
            {...settingsRegister('email', emailOpts)}
            placeholder="Email"
            style={textInputStyle}
          />
          {settingsErrors.email && (
            <Subtext color={COLORS.mandy}>
              {settingsErrors.email.message || 'Valid email required'}
            </Subtext>
          )}
        </div>
        <Gap />
        <Gap />
        <Prompt>How would you like to be known?</Prompt>
        <Gap />
        <div>
          <TextInput
            {...settingsRegister('handle', handleOpts)}
            placeholder="Handle"
            style={textInputStyle}
          />
          {settingsErrors.handle && (
            <Subtext color={COLORS.mandy}>
              {settingsErrors.handle.message ||
                'Handle with only letters and numbers required'}
            </Subtext>
          )}
        </div>
        <Gap />
        <div style={buttonHolderStyle}>
          <Button
            label={settingsButtonText}
            loadingLabel={settingsButtonLoadingText}
            loading={isSubmittingSettings}
            onClick={handleSettingsSubmit(onSettingsUpdate)}
            onTouchEnd={handleSettingsSubmit(onSettingsUpdate)}
            style={primaryButtonStyle}
          />
          <Button
            label={resetButtonText}
            condition={CONDITIONS.secondary}
            onClick={handleSettingsReset}
            onTouchEnd={handleSettingsReset}
            style={resetButtonStyle}
          />
        </div>
      </form>
      <Divider />
      {showPasswordSuccess && (
        <>
          <Announcement
            condition={CONDITIONS.success}
            style={announcementStyle}
          >
            Password successfully updated!
          </Announcement>
          <Gap />
        </>
      )}
      {passwordFail && (
        <>
          <Announcement style={announcementStyle}>{passwordFail}</Announcement>
          <Gap />
        </>
      )}
      <form>
        <Prompt>Change your password?</Prompt>
        <Gap />
        <div>
          <TextInput
            {...passwordRegister('currentPassword', currentPasswordOpts)}
            type="password"
            placeholder="Current password"
            style={textInputStyle}
          />
          {passwordErrors.currentPassword && (
            <Subtext color={COLORS.mandy}>
              {passwordErrors.currentPassword.message ||
                'Correct current password required'}
            </Subtext>
          )}
          <Gap />
          <TextInput
            {...passwordRegister('password', passwordOpts)}
            type="password"
            placeholder="New password"
            style={textInputStyle}
          />
          {passwordErrors.password && (
            <Subtext color={COLORS.mandy}>
              {passwordErrors.password.message ||
                'New password with at least 6 characters required'}
            </Subtext>
          )}
        </div>
        <Gap />
        <Button
          label={passwordButtonText}
          loadingLabel={passwordButtonLoadingText}
          loading={isSubmittingPassword}
          onClick={handlePasswordSubmit(onPasswordConfirm)}
          onTouchEnd={handlePasswordSubmit(onPasswordConfirm)}
          style={primaryButtonStyle}
        />
      </form>
      <Divider />
      <div style={showDeleteContainerStyle}>
        Actually, I&apos;d like to{' '}
        <b
          role="none"
          onMouseUp={handleShowDeleter}
          onTouchEnd={handleShowDeleter}
          style={showDeleteStyle}
        >
          delete
        </b>{' '}
        my account
      </div>
      <Modal
        visible={showDeleter}
        header="Are you sure?"
        callback={() => setShowDeleter(false)}
      >
        <div style={deleterContainerStyle}>
          Deleting your account is permanent. Please be certain before
          proceeding.
        </div>
        <br />
        <Button
          label="Cancel"
          callback={() => setShowDeleter(false)}
          width="48.75%"
          condition={CONDITIONS.secondary}
        />
        <Button
          label={deleteButtonText}
          loadingLabel={deleteButtonLoadingText}
          loading={isSubmittingDelete}
          onMouseUp={handleDeleteSubmit(onDelete)}
          onTouchEnd={handleDeleteSubmit(onDelete)}
          width="48.75%"
          condition={CONDITIONS.fail}
          style={deleteButtonStyle}
        />
      </Modal>
    </FramedView>
  );
}

export default SettingsForms;
