import React, {
  useReducer,
  useMemo,
  PropsWithChildren,
  ReactElement,
} from 'react';

import { createSafeContext } from '~/utils';

import { Context, State } from './models';
import { ACTIONS, setGenericThunk } from './actions';
import reducer, { INITIAL_STATE } from './reducer';

const [useContext, Provider] = createSafeContext<Context>();

type ContentProps = Record<never, never>;

function Content({ children }: PropsWithChildren<ContentProps>): ReactElement {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  // Connect thunks
  const setNodes = setGenericThunk(dispatch, ACTIONS.setNodes);
  const setPatterns = setGenericThunk(dispatch, ACTIONS.setPatterns);
  const setGroups = setGenericThunk(dispatch, ACTIONS.setGroups);
  const setSentences = setGenericThunk(dispatch, ACTIONS.setSentences);
  const setMuted = setGenericThunk(dispatch, ACTIONS.setMuted);
  const setDisabled = setGenericThunk(dispatch, ACTIONS.setDisabled);

  // Set up value
  const value = useMemo(() => {
    return {
      state: state as State,
      setNodes,
      setPatterns,
      setGroups,
      setSentences,
      setMuted,
      setDisabled,
    };
  }, [state]);

  return <Provider value={value}>{children}</Provider>;
}

export default Content;
export { useContext };
