import React, {
  ReactElement,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { isSafari } from 'react-device-detect';

import COLORS from '~/constants/colors';
import Announcement from '~/components/Announcement';
import Header from '~/components/Header';
import Subheader from '~/components/Subheader';
import Divider from '~/components/Divider';
import TextButton from '~/components/TextButton';
import Gap from '~/components/Gap';
import { CONDITIONS } from '~/providers/ThemeProvider';
import Break from '~/components/Break';

import Settings from '../components/Settings';
import Webcam, { WebcamRef } from '../devices/Webcam';
import Container from '../components/Container';
import VideoPlayer from '../components/VideoPlayer';
import MediaControls from '../components/MediaControls';
import ActionPanel from '../components/ActionPanel';
import TimerBar from '../components/TimerBar';
import { DeviceState, ListenControls } from '../models';
import { getAudioConstraints } from '../utils';
import { AUDIO_TIME_LIMIT } from '../constants/media';

interface FilmProps {
  setListenControls: Dispatch<SetStateAction<ListenControls>>;
  automaticStart: boolean;
  setAutomaticStart: Dispatch<SetStateAction<boolean>>;
}

function Film({
  setListenControls,
  automaticStart,
  setAutomaticStart,
}: FilmProps): ReactElement {
  const [webcamState, setWebcamState] = useState<DeviceState>('closed');
  const [showSettings, setShowSettings] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [cookedUrl, setCookedUrl] = useState('');
  const [cookedVideo, setCookedVideo] = useState<Blob>();
  const [preview, setPreview] = useState<MediaStream>();
  const [loading, setLoading] = useState(false);
  const [rawAudioBuffer, setRawAudioBuffer] = useState<AudioBuffer>();
  const hasMediaRecorder = 'MediaRecorder' in window;
  const webcamRef = useRef<WebcamRef>(null);

  // Handlers
  const handleShowSettings = () => setShowSettings(true);
  const cookedCallback = (blob) => {
    setCookedUrl(URL.createObjectURL(blob));
    setCookedVideo(blob);
  };
  const rawCallback = (buffer) => setRawAudioBuffer(buffer);

  // Set up timer
  const [duration, setDuration] = useState(0);
  const [lastRunningTime, setLastRunningTime] = useState<number>();
  const [timer, setTimer] = useState<ReturnType<typeof setInterval>>();
  const durationRef = useRef(duration);
  const lastRunningTimeRef = useRef(lastRunningTime);
  const timerRef = useRef(timer);

  durationRef.current = duration;
  lastRunningTimeRef.current = lastRunningTime;
  timerRef.current = timer;

  // Controls
  const startFilming = () => {
    setLoading(true);
    setShowSettings(false);
    setErrorText('');
    setDuration(0);
    try {
      // Check webcam is ready
      if (!hasMediaRecorder)
        throw new Error('Filming is not supported by your browser');
      if (
        (webcamState !== 'closed' && webcamState !== 'done') ||
        !webcamRef.current
      )
        throw new Error('Please wait, webcam is not ready');

      // Start webcam
      webcamRef.current.start();

      // Clear old recordings after start to prevent showing normal start button after clicking redo
      setCookedUrl('');
      setCookedVideo(undefined);
      setRawAudioBuffer(undefined);
    } catch (error) {
      setErrorText((error as Error).message);
      setLoading(false);
    }
  };
  const pauseFilming = () => {
    if (webcamState === 'running' && webcamRef.current)
      webcamRef.current.pause();
  };
  const resumeFilming = () => {
    if (webcamState === 'suspended' && webcamRef.current)
      webcamRef.current.resume();
  };
  const stopFilming = () => {
    if (
      (webcamState === 'running' || webcamState === 'suspended') &&
      webcamRef.current
    )
      webcamRef.current.stop();
  };

  // Keep duration
  const getTime = () => {
    const current = Date.now();
    const change = lastRunningTimeRef.current
      ? current - lastRunningTimeRef.current
      : 0;

    return { current, change };
  };
  const clearTimer = () => {
    clearInterval(timerRef.current);
    setTimer(undefined);
  };
  const setNewTimer = () => {
    clearInterval(timerRef.current);

    // Update timer
    const newTimer = setInterval(() => {
      const time = getTime();
      const newDuration = durationRef.current + time.change;

      if (newDuration > AUDIO_TIME_LIMIT) stopFilming();
      else {
        setLastRunningTime(time.current);
        setDuration(newDuration);
      }
    }, 100);
    setTimer(newTimer);
  };

  // Webcam config
  // TODO: fix typing, currently set to `any` for convenience
  const [config, setConfig] = useState<any>({
    setState: setWebcamState,
    setError: setErrorText,
    setLoading,
    audio: getAudioConstraints(),
    cookedCallback,
    rawCallback,
  });

  // Film styling
  const headerStyle = {
    margin: 0,
  };
  const timeLimitStyle = {
    color: COLORS.yellowGreen,
  };

  useEffect(() => {
    if (cookedVideo) setCookedUrl(URL.createObjectURL(cookedVideo));
  }, [cookedVideo]);
  useLayoutEffect(() => {
    if (automaticStart) {
      setAutomaticStart(false);
      startFilming();
    }
  }, [automaticStart]);
  useEffect(() => {
    if (webcamState === 'suspended') {
      const time = getTime();
      const newDuration = durationRef.current + time.change;

      setDuration(newDuration);
      clearTimer();
    } else if (webcamState === 'running') {
      setLastRunningTime(Date.now());
      setNewTimer();
    } else if (webcamState === 'done') clearTimer();
  }, [webcamState]);
  useEffect(() => {
    if (!preview && webcamRef.current?.preview?.active) {
      setLoading(false);
      setPreview(webcamRef.current?.preview);
    } else if (preview && !webcamRef.current?.preview?.active) {
      setLoading(false);
      setPreview(undefined);
    }
  }, [webcamRef.current?.preview]);

  // Unmount
  useEffect(() => () => clearInterval(timerRef.current), []);

  return (
    <>
      <Container>
        {!isSafari && errorText && (
          <>
            <Announcement>{errorText}</Announcement>
            <Break />
          </>
        )}
        {isSafari && (
          <>
            {errorText && (
              <>
                <Announcement>{errorText}</Announcement>
                <Gap />
              </>
            )}
            <Announcement condition={CONDITIONS.warning}>
              Video recording behavior may be unstable on Safari
            </Announcement>
            <Break />
          </>
        )}
        <Header style={headerStyle}>
          You&apos;ve got up to <b style={timeLimitStyle}>3 minutes</b>!
        </Header>
        <Divider />
        {(webcamState === 'running' || webcamState === 'suspended') && (
          <TimerBar duration={duration} maxDuration={AUDIO_TIME_LIMIT} />
        )}
        {(webcamState === 'closed' || webcamState === 'done') && (
          <Subheader>
            Click <TextButton callback={handleShowSettings}>here</TextButton> to
            customize your video settings
          </Subheader>
        )}
      </Container>
      <Break />
      <Break />
      {hasMediaRecorder && (
        <Webcam state={webcamState} config={config} ref={webcamRef} />
      )}
      {cookedUrl && cookedVideo && rawAudioBuffer && (
        <div>
          <VideoPlayer url={cookedUrl} />
          <Gap />
          <ActionPanel
            cookedUrl={cookedUrl}
            cookedMedia={cookedVideo}
            setCookedMedia={setCookedVideo}
            setErrorText={setErrorText}
            config={config}
            rawAudioBuffer={rawAudioBuffer}
            show="video"
            setListenControls={setListenControls}
          />
          <Break />
        </div>
      )}
      {preview && !cookedUrl && (
        <>
          <VideoPlayer preview={preview} />
          <Break />
        </>
      )}
      <MediaControls
        deviceState={webcamState}
        onStop={stopFilming}
        onStart={startFilming}
        onPause={pauseFilming}
        onResume={resumeFilming}
        loading={loading}
      />
      <Settings
        visible={showSettings}
        setVisible={setShowSettings}
        config={config}
        setConfig={setConfig}
      />
    </>
  );
}

export default Film;
