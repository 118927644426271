import React, { ReactElement } from 'react';

import Button from '~/components/Button';
import COLORS from '~/constants/colors';
import { FONT_WEIGHTS } from '~/constants/typography';

interface StartButtonProps {
  label: string;
  disabled?: boolean;
  callback?: (event: Event) => void;
}

function StartButton({
  label,
  disabled = false,
  callback,
}: StartButtonProps): ReactElement {
  const startButtonStyle = {
    padding: '0.75em 0',
    backgroundImage: `linear-gradient(135deg, ${COLORS.mandy} 18%, ${COLORS.jaffa} 18%, ${COLORS.jaffa} 34%, ${COLORS.kournikova} 34%, ${COLORS.kournikova} 50%, ${COLORS.wildWillow} 50%, ${COLORS.wildWillow} 66%, ${COLORS.malibu} 66%, ${COLORS.malibu} 82%, ${COLORS.lilacBush} 82%)`,
    borderColor: COLORS.bayOfMany,
    borderWidth: '0.13em',
    boxShadow: `-0.13em 0.13em ${COLORS.black25}`,
  };
  const disabledStyle = {
    borderColor: COLORS.gray,
    backgroundImage: `linear-gradient(135deg, ${COLORS.weatheredStone} 18%, ${COLORS.alto} 18%, ${COLORS.alto} 34%, ${COLORS.gallery} 34%, ${COLORS.gallery} 50%, ${COLORS.alto} 50%, ${COLORS.alto} 66%, ${COLORS.silver} 66%, ${COLORS.silver} 82%, ${COLORS.platinum} 82%)`,
  };
  const labelStyle = {
    fontSize: '1.25em',
    fontWeight: FONT_WEIGHTS.heavy,
    color: COLORS.bayOfMany,
  };
  const disabledLabelStyle = {
    color: COLORS.gray,
  };

  return (
    <Button
      label={label}
      width="min(60%, 15em)"
      disabled={disabled}
      callback={callback}
      style={startButtonStyle}
      disabledStyle={disabledStyle}
      labelStyle={labelStyle}
      disabledLabelStyle={disabledLabelStyle}
    />
  );
}

export default StartButton;
