import React, {
  ReactElement,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';

import { CONDITIONS } from '~/providers/ThemeProvider';
import { useAuthContext } from '~/providers/AuthProvider';
import COLORS from '~/constants/colors';
import TextInput from '~/components/TextInput';
import Subtext from '~/components/Subtext';
import Button from '~/components/Button';
import Gap from '~/components/Gap';
import { getJournalFormErrorHandler } from '~/utils';
import Announcement from '~/components/Announcement';
import Header from '~/components/Header';
import Divider from '~/components/Divider';
import Link from '~/components/Link';
import Code from '~/components/Code';
import Modal from '~/components/Modal';
import TextArea from '~/components/TextArea';
import { FONT_SIZES } from '~/constants/typography';

import { DEFAULT_TECHNIQUE_INFO } from '../constants/defaults';
import CONTENT, { KINDS /* , DIFFICULTIES */ } from '../constants/content';
import { useBeatboxContext } from '../providers/BeatboxProvider';
import uris from '../uris';
import {
  hasPermission,
  infoFromContent,
  // getRelationOptions,
  // capitalizeFirstLetter,
} from '../utils';
// import OptionSelect from './OptionSelect';
import CurrentList from './CurrentList';

// TODO: add autofill to technique name so people can get a sense if they're adding something already created
function TechniqueForm(): ReactElement {
  const [fail, setFail] = useState<string>();
  const [showDeleter, setShowDeleter] = useState(false);
  const { state: authState } = useAuthContext();
  const {
    state: beatboxState,
    listProfiles,
    listCategories,
    addTechnique,
    setTechnique,
    listTechniques,
    editTechnique,
    deleteTechnique,
  } = useBeatboxContext();
  const techniqueId = Number(useParams().techniqueId);
  // const difficultyOptions = Object.values(DIFFICULTIES).map((d) => ({
  //   value: d,
  //   label: capitalizeFirstLetter(d),
  // }));

  // Form
  const defaultValues =
    (techniqueId && infoFromContent(beatboxState.technique)) ||
    DEFAULT_TECHNIQUE_INFO;
  const {
    register,
    // control,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues });
  const nameOpts = {
    required: true,
    minLength: 1,
  };
  const radioOpts = {
    required: true,
  };
  // const difficultyRules = {
  //   required: true,
  //   validate: (difficulty) => Object.values(DIFFICULTIES).includes(difficulty),
  // };
  // const relationRules = {
  //   validate: (list) =>
  //     Array.isArray(list) &&
  //     list.map((e) => Number.isInteger(e)).reduce((acc, e) => acc && e, true),
  // };

  // Rendering
  const primaryButtonText = techniqueId ? 'Update' : 'Submit';
  const primaryButtonLoadingText = techniqueId ? 'Changing...' : 'Creating...';
  const deleteButtonText = 'Delete';
  const deleteButtonLoadingText = 'Removing...';
  const resetButtonText = techniqueId ? 'Reset' : 'Clear';

  // Handlers
  const navigate = useNavigate();
  const onSubmit = (techniqueInfo) => {
    return new Promise<void>((resolve) => {
      setFail(undefined);
      (techniqueId
        ? editTechnique(techniqueId, techniqueInfo)
        : addTechnique(techniqueInfo)
      )
        .then(async (res) => {
          navigate(uris.exploreTechnique(techniqueId || res));
        })
        .catch(
          getJournalFormErrorHandler(
            DEFAULT_TECHNIQUE_INFO,
            setError,
            setFail,
            resolve
          )
        );
    });
  };
  const onDelete = () => {
    return new Promise<void>(() => {
      setFail(undefined);
      deleteTechnique(techniqueId).then(() => {
        setShowDeleter(false);
        navigate(uris.manageTechniques);
      });
    });
  };
  const handleReset = () => reset();
  const handleShowDeleter = () => setShowDeleter(true);

  // Technique form styling
  const textInputStyle = {
    width: 'min(320px, 75vw)',
  };
  const textAreaStyle = {
    width: 'min(320px, 75vw)',
    height: '5em',
  };
  // const selectStyle = {
  //   width: 'min(335px, 80vw)',
  // };
  const primaryButtonStyle = {
    width: '9em',
    margin: '0.13em 0.5em 0.13em 0',
  };
  const resetButtonStyle = {
    ...primaryButtonStyle,
    margin: '0.13em 0 0.13em 0',
  };
  const deleteButtonStyle = {
    marginLeft: '2.5%',
  };
  const showDeleteStyle = {
    color: COLORS.mandy,
    cursor: 'pointer',
  };
  const deleterContainerStyle = {
    padding: '0em 0.5em',
  };
  const subheaderStyle = {
    fontSize: FONT_SIZES.action,
  };
  const radioInputStyle = {
    margin: '1px 0 0 0.5em',
    verticalAlign: 'middle',
    cursor: 'pointer',
  };
  const radioSpanStyle = {
    verticalAlign: 'middle',
  };

  // Set technique for edit
  useLayoutEffect(() => {
    (async () => {
      if (
        hasPermission(authState, CONTENT.techniques) &&
        techniqueId &&
        techniqueId !== beatboxState.technique?.id
      )
        await setTechnique(techniqueId);
    })();
  }, [techniqueId]);
  useEffect(() => reset(defaultValues), [beatboxState.technique, techniqueId]);

  // List profiles
  useLayoutEffect(() => {
    (async () => {
      if (
        hasPermission(authState, CONTENT.techniques) &&
        !beatboxState.profiles
      )
        await listProfiles();
    })();
  }, []);

  // List techniques
  useEffect(() => {
    (async () => {
      if (
        hasPermission(authState, CONTENT.techniques) &&
        (!beatboxState.techniques || !beatboxState.technique)
      )
        await listTechniques();
    })();
  }, [beatboxState.subject, beatboxState.technique]);

  // List categories
  useEffect(() => {
    (async () => {
      if (
        hasPermission(authState, CONTENT.techniques) &&
        !beatboxState.categories
      )
        await listCategories();
    })();
  }, [beatboxState.subject]);

  return (
    <>
      <Announcement header="Note" condition={CONDITIONS.warning}>
        Techniques in Explore are organized by their first category then
        required techniques so order matters for both inputs below
      </Announcement>
      {!!techniqueId && (
        <>
          <Gap />
          <Gap />
          Go back to <Link href={uris.manageTechniques}>Add Technique</Link> or
          view in <Link href={uris.exploreTechnique(techniqueId)}>Explore</Link>
        </>
      )}
      <Header>
        {techniqueId ? 'Edit' : 'Add'} Technique
        {!!techniqueId && (
          <>
            {' '}
            <Code>{techniqueId}</Code>
          </>
        )}
      </Header>
      {fail && (
        <>
          <Announcement>{fail}</Announcement>
          <Gap />
        </>
      )}
      <form>
        <div>
          <TextInput
            {...register('name', nameOpts)}
            placeholder="Name"
            style={textInputStyle}
          />
          {errors.name && (
            <Subtext color={COLORS.mandy}>
              {errors.name.message || 'Please name your technique'}
            </Subtext>
          )}
        </div>
        <Gap />
        <div>
          <TextArea
            {...register('description')}
            placeholder="Description"
            style={textAreaStyle}
          />
          {errors.description && (
            <Subtext color={COLORS.mandy}>{errors.description.message}</Subtext>
          )}
        </div>
        <Gap />
        <Gap />
        <span style={subheaderStyle}>What kind of technique is this?</span>
        <Gap />
        <div>
          <label htmlFor="beatboxdb-techniqueform-kind-sound">
            <input
              {...register('kind', radioOpts)}
              type="radio"
              value={KINDS.sound}
              style={radioInputStyle}
            />{' '}
            <span style={radioSpanStyle}>Sound</span>
          </label>
          <Gap />
          <label htmlFor="beatboxdb-techniqueform-kind-combo">
            <input
              {...register('kind')}
              type="radio"
              value={KINDS.combo}
              style={radioInputStyle}
            />{' '}
            <span style={radioSpanStyle}>Combo</span>
          </label>
          {errors.kind && (
            <>
              <Gap />
              <Subtext color={COLORS.mandy}>
                {errors.kind.message || 'Kind selection required'}
              </Subtext>
            </>
          )}
        </div>
        <Gap />
        <Gap />
        <span style={subheaderStyle}>
          Where does this technique fall in terms of difficulty?
        </span>
        <Gap />
        <div>
          {/*
          <OptionSelect
            name="difficulty"
            control={control}
            defaultValue={defaultValues.difficulty}
            rules={difficultyRules}
            isMulti={false}
            placeholder="Recommended level for learning"
            options={difficultyOptions}
            style={selectStyle}
          />
          */}
          {errors.difficulty && (
            <>
              <Gap />
              <Subtext color={COLORS.mandy}>
                {errors.difficulty.message || 'Difficulty selection required'}
              </Subtext>
            </>
          )}
        </div>
        <Gap />
        <Gap />
        <span style={subheaderStyle}>
          Who developed or is known for this technique?
        </span>
        <Gap />
        <div>
          {/*
          <OptionSelect
            name="credited_up_users"
            control={control}
            defaultValue={defaultValues.credited_up_users}
            rules={relationRules}
            placeholder="Pioneers or influential users"
            options={getRelationOptions(beatboxState.profiles, 'handle')}
            style={selectStyle}
          />
          */}
          {errors.credited_up_users && (
            <Subtext color={COLORS.mandy}>
              {errors.credited_up_users.message ||
                'Only existing users from selection allowed'}
            </Subtext>
          )}
        </div>
        <Gap />
        <Gap />
        <span style={subheaderStyle}>
          What categories does this technique belong in?
        </span>
        <Gap />
        <div>
          {/*
          <OptionSelect
            name="categories"
            control={control}
            defaultValue={defaultValues.categories}
            rules={relationRules}
            placeholder="Classifications like bass or drums"
            options={getRelationOptions(beatboxState.categories)}
            style={selectStyle}
          />
          */}
          {errors.categories && (
            <Subtext color={COLORS.mandy}>
              {errors.categories.message ||
                'Only existing categories from selection allowed'}
            </Subtext>
          )}
        </div>
        <Gap />
        <Gap />
        <span style={subheaderStyle}>
          What do you need to know to learn it?
        </span>
        <Gap />
        <div>
          {/*
          <OptionSelect
            name="parent_techniques"
            control={control}
            defaultValue={defaultValues.parent_techniques}
            rules={relationRules}
            placeholder="Required techniques"
            options={getRelationOptions(beatboxState.techniques, 'name')}
            style={selectStyle}
          />
          */}
          {errors.parent_techniques && (
            <Subtext color={COLORS.mandy}>
              {errors.parent_techniques.message ||
                'Only existing techniques from selection allowed'}
            </Subtext>
          )}
        </div>
        <Gap />
        <Button
          label={primaryButtonText}
          loadingLabel={primaryButtonLoadingText}
          loading={isSubmitting}
          onMouseUp={handleSubmit(onSubmit)}
          onTouchEnd={handleSubmit(onSubmit)}
          style={primaryButtonStyle}
        />
        <Button
          label={resetButtonText}
          condition={CONDITIONS.secondary}
          onMouseUp={handleReset}
          onTouchEnd={handleReset}
          style={resetButtonStyle}
        />
        {!!techniqueId && (
          <>
            <Gap />
            <Gap />
            Actually, I&apos;d like to{' '}
            <b
              role="none"
              onMouseUp={handleShowDeleter}
              onTouchEnd={handleShowDeleter}
              style={showDeleteStyle}
            >
              delete
            </b>{' '}
            this technique
          </>
        )}
      </form>
      {!!techniqueId && (
        <Modal
          visible={showDeleter}
          header="Are you sure?"
          callback={() => setShowDeleter(false)}
        >
          <div style={deleterContainerStyle}>
            Deleting technique <Code>{techniqueId}</Code> is permanent. Please
            check before proceeding.
          </div>
          <br />
          <Button
            label="Cancel"
            callback={() => setShowDeleter(false)}
            width="48.75%"
            condition={CONDITIONS.secondary}
          />
          <Button
            label={deleteButtonText}
            loadingLabel={deleteButtonLoadingText}
            loading={isSubmitting}
            onMouseUp={handleSubmit(onDelete)}
            onTouchEnd={handleSubmit(onDelete)}
            width="48.75%"
            condition={CONDITIONS.fail}
            style={deleteButtonStyle}
          />
        </Modal>
      )}
      <Divider />
      <CurrentList plural={CONTENT.techniques} />
    </>
  );
}

export default TechniqueForm;
