import React, { ReactElement, CSSProperties } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import PATHS from '~/constants/paths';
import socialUrls from '~/urls/social';
import { getHostUrl } from '~/utils';

interface BrandProps {
  topic: string;
  name?: string;
  link?: string;
  topicStyle?: CSSProperties;
}

function Brand({
  topic,
  name,
  link = getHostUrl(PATHS.root),
  topicStyle,
}: BrandProps): ReactElement {
  const brandStyle = {
    fontSize: `min(12vw, ${FONT_SIZES.appName})`,
    width: '100vw',
    margin: 'max(2%, 0.5em) 0 max(1.8%, 0.4em) 0',
  };
  const nameStyle = {
    color: COLORS.cornflower,
    textDecoration: 'none',
  };
  const linkStyle = {
    all: 'inherit' as const,
    cursor: 'pointer',
  };
  const finalTopicStyle = {
    color: COLORS.white,
    fontFamily: 'Oleo Script Swash Caps Regular',
    textShadow: `-0.047em 0.047em 0 ${COLORS.black25}`,
    ...topicStyle,
  };

  return (
    <div style={brandStyle}>
      <b style={nameStyle}>
        <a href={link} style={linkStyle}>
          {name ?? 'pon'}
        </a>
        {!name && (
          <>
            <a href={socialUrls.discordInvite} style={linkStyle}>
              d
            </a>
            <a href={link} style={linkStyle}>
              er
            </a>
          </>
        )}
      </b>{' '}
      <b style={finalTopicStyle}>{topic}</b>
    </div>
  );
}

export default Brand;
