import React, { ReactElement, CSSProperties } from 'react';

import Button from '~/components/Button';
import COLORS from '~/constants/colors';

interface SmallButtonProps {
  label: string;
  active?: boolean;
  callback?: (event: Event) => void;
  style?: CSSProperties;
}

function SmallButton({
  label,
  active = false,
  callback,
  style,
}: SmallButtonProps): ReactElement {
  const smallButtonStyle = {
    fontSize: '1em',
    padding: '0.4em',
    borderStyle: 'none',
    color: COLORS.white,
    backgroundColor: active ? COLORS.dodgerBlue : COLORS.waikawaGray,
    boxShadow: `-0.13em 0.13em ${COLORS.black25}`,
    ...style,
  };

  return <Button label={label} callback={callback} style={smallButtonStyle} />;
}

export default SmallButton;
