import React, { ReactElement } from 'react';
import { motion } from 'framer-motion';

import { FONT_WEIGHTS } from '~/constants/typography';

import { NotePredicate } from '../predicates/models';
import { RendererProps } from './models';
import { NOTE_THEMES, NOTE_KEYS } from '../constants/layout';

function Note({ predicate }: RendererProps<NotePredicate>): ReactElement {
  const pos = predicate.getPos();
  const noteThemes = NOTE_THEMES(predicate.windowDims);

  // Note styling
  const getPx = (v) => `${v}px`;
  const noteB = predicate.dims.b ?? 0;
  const noteHeight = getPx(predicate.dims.h);
  const noteTheme = noteThemes[predicate.theme];
  const noteBoxShadow = noteTheme.boxShadow;
  const noteStyle = {
    position: 'absolute' as const,
    width: getPx(predicate.dims.w),
    height: noteHeight,
    left: getPx(pos.x - predicate.dims.w / 2),
    top: getPx(pos.y - predicate.dims.h / 2),
    textAlign: 'center' as const,
    lineHeight: noteHeight,
    borderRadius: getPx(3 * noteB),
    backgroundColor: noteTheme.backgroundColor,
    boxShadow: noteBoxShadow,
    overflow: 'hidden',
    ...predicate.style,
  };
  const backdropStyle = {
    position: 'absolute' as const,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderRadius: 'inherit',
    backgroundImage: noteTheme.backgroundImage,
    WebkitBackfaceVisibility: 'hidden' as const,
    zIndex: 0,
  };
  const labelStyle = {
    display: 'inline-block',
    position: 'relative' as const,
    lineHeight: 'normal',
    verticalAlign: 'middle',
    marginTop: `calc(-${noteHeight} / 8)`,
    fontSize: getPx((predicate.dims.h * 3) / 7),
    fontWeight: FONT_WEIGHTS.medium,
    color: noteTheme.color,
    zIndex: 1,
  };

  // Animation
  const stateTheme = noteThemes[predicate.state ?? NOTE_KEYS.miss];
  const stateBoxShadow = stateTheme.boxShadow;
  type PoofFrames = [null, string, string];
  const noteVariants = {
    reset: {
      backgroundColor: noteTheme.backgroundColor,
      boxShadow: noteBoxShadow,
      opacity: 1,
    },
    miss: {
      backgroundColor: stateTheme.backgroundColor,
      transition: { duration: 0.03 },
    },
    poof: {
      backgroundColor: [
        null,
        stateTheme.backgroundColor,
        stateTheme.backgroundColor,
      ] as PoofFrames,
      boxShadow: [null, stateBoxShadow, stateBoxShadow] as PoofFrames,
      opacity: [1, 1, 0],
      transition: {
        duration: 0.27,
        times: [0, 0.22, 1],
        ease: ['easeOut', 'linear'],
      },
    },
  };
  const backdropVariants = {
    reset: {
      opacity: 1,
    },
    miss: {
      opacity: 0,
      transition: { duration: 0.03 },
    },
    poof: {
      opacity: 0,
      transition: { duration: 0.0594, ease: 'easeOut' },
    },
  };
  const labelVariatns = {
    reset: {
      color: noteTheme.color,
    },
    miss: {
      color: stateTheme.color,
      transition: { duration: 0.03 },
    },
    poof: {
      color: stateTheme.color,
      transition: { duration: 0.0594, ease: 'easeOut' },
    },
  };
  const animate =
    predicate.state && predicate.state !== NOTE_KEYS.miss
      ? 'poof'
      : predicate.state;

  return (
    <motion.div
      initial={false}
      style={noteStyle}
      animate={animate ?? 'reset'}
      variants={noteVariants}
    >
      <motion.div
        style={backdropStyle}
        animate={animate}
        variants={backdropVariants}
      />
      <motion.div style={labelStyle} animate={animate} variants={labelVariatns}>
        {predicate.prompt}
      </motion.div>
    </motion.div>
  );
}

export default Note;
