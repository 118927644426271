import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  ReactElement,
  useState,
  useEffect,
  CSSProperties,
} from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import { useThemeContext, CONDITIONS } from '~/providers/ThemeProvider';
import { stopEventEffects } from '~/utils';

interface ButtonProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  label: string;
  loadingLabel?: string;
  condition?: string;
  width?: string;
  callback?: (event: Event) => void | Promise<void>;
  loading?: boolean;
  disabled?: boolean;
  ignoreDisabledStyle?: boolean;
  style?: CSSProperties;
  disabledStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  disabledLabelStyle?: CSSProperties;
}

function Button({
  label,
  loadingLabel = '...',
  condition = CONDITIONS.primary,
  width,
  callback,
  loading = false,
  disabled = false,
  ignoreDisabledStyle = false,
  style,
  disabledStyle,
  labelStyle,
  disabledLabelStyle,
  ...props
}: ButtonProps): ReactElement {
  const { theme } = useThemeContext().state;
  const useDisabledStyle = disabled && !ignoreDisabledStyle;
  const buttonState = useDisabledStyle
    ? theme.disabled.default
    : theme[condition].default;

  // Button styling
  const totalDisabledStyle = {
    backgroundColor: theme.disabled.default.backgroundColor,
    borderColor: theme.disabled.default.backgroundColor,
    ...disabledStyle,
  };
  const totalDisabledLabelStyle = {
    color: theme.disabled.default.color,
    ...disabledLabelStyle,
  };
  const getStyle = (state) => ({
    display: 'inline-block',
    width,
    padding: '0.6em',
    fontSize: FONT_SIZES.action,
    borderRadius: '0.5em',
    borderStyle: 'solid',
    borderWidth: 'thin',
    cursor: loading || disabled ? 'default' : 'pointer',
    boxShadow: `-0.13em 0.13em ${COLORS.black25}`,

    // From theme
    color: state.color ?? theme.neutral.default.color,
    backgroundColor:
      state.backgroundColor ?? theme.neutral.default.backgroundColor,
    borderColor: state.borderColor ?? theme.neutral.default.borderColor,
    ...style,
    ...(useDisabledStyle && totalDisabledStyle),
  });
  const [buttonStyle, setButtonStyle] = useState(getStyle(buttonState));
  const buttonLabelStyle = {
    ...labelStyle,
    ...(loading &&
      theme[condition].loading?.color && {
        color: theme[condition].loading.color,
      }),
    ...(useDisabledStyle && totalDisabledLabelStyle),
  };

  // Event handlers
  const handleMouseEnter = async (event) => {
    stopEventEffects(event);
    if (!loading && !disabled && theme[condition].hover)
      setButtonStyle(getStyle(theme[condition].hover));
  };
  const handleMouseLeave = async (event) => {
    stopEventEffects(event);
    if (!loading && !disabled)
      setButtonStyle(getStyle(theme[condition].default));
  };
  const handleMouseUp = async (event) => {
    stopEventEffects(event);
    if (!loading && !disabled) {
      await callback?.(event);
      setButtonStyle(
        getStyle(theme[condition].hover ?? theme[condition].default)
      );
    }
  };
  const handleTouchEnd = async (event) => {
    stopEventEffects(event);
    if (!loading && !disabled) {
      await callback?.(event);
      setButtonStyle(getStyle(theme[condition].default));
    }
  };
  const handleActive = async () => {
    if (!loading && !disabled && theme[condition].active)
      setButtonStyle(getStyle(theme[condition].active));
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted)
      setButtonStyle(
        getStyle((loading && theme[condition].loading) || buttonState)
      );

    return () => {
      isMounted = false;
    };
  }, [theme, condition, loading, disabled, style]);

  return (
    <button
      type="button"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleActive}
      onMouseUp={handleMouseUp}
      onTouchStart={handleActive}
      onTouchEnd={handleTouchEnd}
      disabled={disabled}
      style={buttonStyle}
      {...props}
    >
      <b style={buttonLabelStyle}>{(loading && loadingLabel) || label}</b>
    </button>
  );
}

export default Button;
