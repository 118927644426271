import React, { ReactElement, useLayoutEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import COLORS from '~/constants/colors';
import FramedView from '~/components/FramedView';
import Title from '~/components/Title';
import Divider from '~/components/Divider';
import { CONDITIONS } from '~/providers/ThemeProvider';
import Announcement from '~/components/Announcement';
import Header from '~/components/Header';
import Link from '~/components/Link';
import socialUrls from '~/urls/social';
import { stopEventEffects } from '~/utils';
import Gap from '~/components/Gap';
import { FONT_WEIGHTS } from '~/constants/typography';
import beatboxUrls from '~/urls/beatbox';

import { useBeatboxContext } from '../providers/BeatboxProvider';
import { Technique } from '../providers/RecordProvider';
import ContentCard, { ContentCardRef } from '../components/ContentCard';
import NoContentItems from '../components/NoContentItems';
import CONTENT from '../constants/content';
import NotFound from './NotFound';

// TODO: may also want to show exchange techniques here at some point
function Profile(): ReactElement {
  const { handle } = useParams();
  const {
    state: { beatboxer },
    setBeatboxer,
  } = useBeatboxContext();
  const contentCardRef = useRef<ContentCardRef>(null);
  const getCallback = (techniqueId) => (event) => {
    stopEventEffects(event);
    contentCardRef.current?.showTechnique(techniqueId);
  };
  const getKey = (idx) => `BeatboxDbProfileCallbackLink${idx}`;

  // Processing
  const recordsDict = beatboxer?.records.reduce((acc, r) => {
    const list = acc[r.state]
      ? acc[r.state].concat(r.technique)
      : [r.technique];

    return { ...acc, [r.state]: list };
  }, {} as Record<string, Technique[]>);
  const hasCreditedTechniques =
    (beatboxer?.credited_techniques.length ?? 0) > 0;
  const hasLearningRecords = (recordsDict?.learning?.length ?? 0) > 0;
  const hasGrindingRecords = (recordsDict?.grinding?.length ?? 0) > 0;
  const hasSatisfiedRecords = (recordsDict?.satisfied?.length ?? 0) > 0;
  const hasRecords =
    hasLearningRecords || hasGrindingRecords || hasSatisfiedRecords;

  // Profile styling
  const profileStyle = {
    width: 'min(45em, 82.5vw)',
  };
  const frameColors: [string, string] = [
    COLORS.waikawaGray,
    COLORS.waikawaGray,
  ];
  const atStyle = {
    fontWeight: FONT_WEIGHTS.light,
  };
  const callbackNameStyle = {
    display: 'inline-block',
    color: COLORS.chetwodeBlue,
    cursor: 'pointer',
  };
  const callbackLinkListStyle = {
    marginLeft: '0.38em',
  };

  useLayoutEffect(() => {
    (async () => {
      if (handle !== beatboxer?.profile.handle) await setBeatboxer(handle);
    })();
  }, [handle]);

  // Handle showing technique cards
  const CallbackLink = ({ techniqueId, name }) => (
    <span
      role="none"
      onMouseUp={getCallback(techniqueId)}
      onTouchEnd={getCallback(techniqueId)}
      style={callbackNameStyle}
    >
      {name}
    </span>
  );
  const CallbackLinkList = ({ techniques }) => (
    <div style={callbackLinkListStyle}>
      {techniques.map((t, idx, { length }) => (
        <span key={getKey(idx)}>
          <CallbackLink techniqueId={t.id} name={t.name} />
          {idx < length - 1 && ', '}
        </span>
      ))}
    </div>
  );

  return (
    <>
      {beatboxer ? (
        <FramedView accent colors={frameColors} style={profileStyle}>
          {!beatboxer.profile.claimed && (
            <Announcement condition={CONDITIONS.warning}>
              This account has not been claimed. Please message an admin on our{' '}
              <Link href={socialUrls.discordInvite}>Discord</Link> with proof of
              identity if you are this beatboxer and would like to claim it.
            </Announcement>
          )}
          <Title>
            <span style={atStyle}>@</span>
            {beatboxer.profile.handle}
          </Title>
          <Divider />
          {hasCreditedTechniques && (
            <>
              <Header>📖 Credited Techniques</Header>
              <CallbackLinkList techniques={beatboxer.credited_techniques} />
              <Gap />
            </>
          )}
          {hasLearningRecords && (
            <>
              <Header>🧠 Learning List</Header>
              <CallbackLinkList techniques={recordsDict?.learning} />
              <Gap />
            </>
          )}
          {hasGrindingRecords && (
            <>
              <Header>💪 Grinding Group</Header>
              <CallbackLinkList techniques={recordsDict?.grinding} />
              <Gap />
            </>
          )}
          {hasSatisfiedRecords && (
            <>
              <Header>💯 Satisfied Set</Header>
              <CallbackLinkList techniques={recordsDict?.satisfied} />
              <Gap />
            </>
          )}
          {!(hasCreditedTechniques || hasRecords) && (
            <>
              <Header>🧰 Under Construction</Header>
              Please come back later. For now, stay cool and{' '}
              <Link href={beatboxUrls.breathe}>breathe</Link>.
              <Gap />
              <NoContentItems plural={CONTENT.records} />
            </>
          )}
        </FramedView>
      ) : (
        <NotFound />
      )}
      <ContentCard ref={contentCardRef} />
    </>
  );
}

export default Profile;
