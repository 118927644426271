import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import Heart from '~/components/Heart';

import { maxLives } from '../constants/game';

interface LivesProps {
  lives: number;
}

function Lives({ lives }: LivesProps): ReactElement {
  const heartContainers = [...Array(maxLives).keys()];
  const getKey = (index) => `KanaMatchLives${index}`;

  // Lives styling
  const heartStyle = {
    padding: '0.1em 0.15em',
  };

  return (
    <div>
      {heartContainers.map((element, index) => (
        <Heart
          key={getKey(index)}
          width="20px"
          fill={lives > element ? COLORS.mandy : COLORS.alabaster}
          style={heartStyle}
        />
      ))}
    </div>
  );
}

export default Lives;
