import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import Flipper from '~/components/Flipper';
import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';

import uris from '../../../uris';
import { MODES } from '../constants';
import { capitalizeFirstLetter } from '../../../utils';

interface ModeFlipperProps {
  path?: string;
}

function ModeFlipper({ path }: ModeFlipperProps): ReactElement {
  const orderedModes = [MODES.map, MODES.list, MODES.study];
  const idx = orderedModes.reduce(
    (acc, m, mIdx) => (path === m || path?.startsWith(`${m}/`) ? mIdx : acc),
    0
  );
  const navigate = useNavigate();
  const labels = orderedModes.map(capitalizeFirstLetter);
  const callbacks = [
    () => navigate(uris.exploreList),
    () => navigate(uris.exploreStudy),
    () => navigate(uris.exploreMap),
  ];

  // Mode flipper styling
  const modeFlipperStyle = {
    marginRight: '0.5em',
    width: '3.25em',
    padding: '0.3em',
    borderRadius: '0.3em',
    fontFamily: 'source-code-pro, Menlo, monospace',
    fontSize: FONT_SIZES.action,
    verticalAlign: 'bottom',
    boxShadow: `-0.1em 0.1em ${COLORS.black25}`,
  };
  const caseStyles = [
    { color: COLORS.bayOfMany, backgroundColor: COLORS.cornflower },
    { color: COLORS.bayOfMany, backgroundColor: COLORS.cornflower },
    { color: COLORS.bayOfMany, backgroundColor: COLORS.cornflower },
  ];

  return (
    <Flipper
      labels={labels}
      idx={idx}
      auto
      callbacks={callbacks}
      shadow={0}
      style={modeFlipperStyle}
      caseStyles={caseStyles}
    />
  );
}

export default ModeFlipper;
