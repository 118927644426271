import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import { stopEventEffects } from '~/utils';

import { DeviceState } from '../models';

interface FileControlsProps {
  deviceState: DeviceState;
  onStart?: () => void | Promise<void>;
}

function FileControls({
  deviceState,
  onStart,
}: FileControlsProps): ReactElement {
  const startWidth = '5em';
  const gutterWidth = '0.46em';
  const shellWidth = `calc(${startWidth} + 2 * ${gutterWidth})`;

  // Handlers
  const handleStart = async (event) => {
    stopEventEffects(event);
    await onStart?.();
  };

  // File controls styling
  const fileControlsStyle = {
    display: 'inline-block',
  };
  const shellStyle = {
    position: 'relative' as const,
    width: shellWidth,
    height: shellWidth,
    borderRadius: shellWidth,
    border: `1px solid ${COLORS.doveGray}`,
    boxShadow: `-0.13em 0.13em ${COLORS.black25}`,
    cursor:
      deviceState === 'suspended' || deviceState === 'running'
        ? 'default'
        : 'pointer',
  };
  const startStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: startWidth,
    height: startWidth,
    borderRadius: startWidth,
    backgroundColor: COLORS.tundora,
  };
  const redoStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -53%)',
    fontSize: '4em',
    color: COLORS.cornflower,
  };
  const arrowStyle = {
    ...redoStyle,
    transform: 'translate(-50%, -56%)',
    fontSize: '4.1em',
  };
  const dotsStyle = {
    ...redoStyle,
    transform: 'translate(-50%, -75%)',
    fontSize: '4.5em',
  };

  // Rendering
  let controlTitle = 'Choose an audio or video file';

  if (deviceState === 'suspended') controlTitle = 'File selection in progress';
  else if (deviceState === 'running') controlTitle = 'Processing selected file';
  else if (deviceState === 'done') controlTitle = 'Choose another file';

  return (
    <table style={fileControlsStyle}>
      <tbody>
        <tr>
          <td>
            <div
              role="none"
              onMouseUp={handleStart}
              onTouchEnd={handleStart}
              style={shellStyle}
            >
              <div title={controlTitle} style={startStyle}>
                {deviceState === 'closed' && <b style={arrowStyle}>⇪</b>}
                {(deviceState === 'suspended' || deviceState === 'running') && (
                  <b style={dotsStyle}>...</b>
                )}
                {deviceState === 'done' && <b style={redoStyle}>↺</b>}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default FileControls;
