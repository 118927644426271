import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import COLORS from '~/constants/colors';
import Gap from '~/components/Gap';
import { useAuthContext } from '~/providers/AuthProvider';
import {
  stopEventEffects,
  shuffleArray,
  getJournalFormErrorHandler,
} from '~/utils';
import Triangle from '~/components/Triangle';
import Button from '~/components/Button';
import Subtext from '~/components/Subtext';
import Announcement from '~/components/Announcement';

import professorOak from '../assets/professor-oak.gif';
import { DEFAULT_SETTINGS_INFO } from '../constants/defaults';
import { DIFFICULTIES } from '../constants/content';
import { MODES } from './Explore';
import uris from '../uris';

function Welcome(): ReactElement {
  const [fail, setFail] = useState<string>();
  const [shuffledDifficulties] = useState(
    shuffleArray(Object.keys(DIFFICULTIES))
  );
  const { updateSettings } = useAuthContext();
  const optionsDict = {
    fundamentals: 'How do I start beatboxing?',
    beginner: "I'm learning routines to show my friends",
    intermediate: 'I can counter pros',
    advanced: "I'm working on technicality or musicality",
    expert: 'Structure and consistency finally make sense',
    unique: 'How do I start making my own music?',
  };
  const getKey = (idx) => `BeatboxDbWelcomeOption${idx}`;

  // Form
  const defaultValues = DEFAULT_SETTINGS_INFO;
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues });
  const onboardingOpts = {
    required: true,
    validate: (value) => Object.values(DIFFICULTIES).includes(value),
  };

  register('onboarding', onboardingOpts);

  // Handlers
  const navigate = useNavigate();
  const onSubmit = (settingsInfo) => {
    return new Promise<void>((resolve) => {
      updateSettings(settingsInfo)
        .then(() => {
          navigate(uris.exploreModeField(MODES.study, settingsInfo.onboarding));
        })
        .catch(
          getJournalFormErrorHandler(
            DEFAULT_SETTINGS_INFO,
            setError,
            setFail,
            resolve
          )
        );
    });
  };
  const handleValue = (v) => (event) => {
    stopEventEffects(event);
    setValue('onboarding', v, { shouldValidate: true });
    handleSubmit(onSubmit)();
  };

  // Welcome styling
  const welcomeStyle = {
    width: 'min(45em, 82.5vw)',
    color: COLORS.white,
  };
  const frameStyle = {
    position: 'relative' as const,
    height: 'min(23em, calc((432 / 480) * 65.4vw))',
  };
  const professorOakStyle = {
    position: 'absolute' as const,
    left: 'calc(50% - min(32em, 82.5vw) / 2)',
    bottom: 0,
    width: 'min(32em, 82.5vw)',
  };
  const optionsStyle = {
    display: 'inline-block',
  };
  const optionStyle = {
    margin: '0 0 1em',
    whiteSpace: 'nowrap' as const,
  };
  const triangleStyle = {
    marginRight: '0.6em',
  };
  const buttonStyle = {
    width: 'min(23em, 69vw)',
    textAlign: 'left' as const,
    whiteSpace: 'normal' as const,
  };
  const errorStyle = {
    margin: '-0.4em 0 0 2em',
  };

  return (
    <div style={welcomeStyle}>
      <div style={frameStyle}>
        <img src={professorOak} alt="Welcome" style={professorOakStyle} />
      </div>
      <br />
      <br />
      Your very own beatboxing legend is about to unfold. To get started,
      we&apos;d like to recommend some techniques for you to learn. Please pick
      the option that best represents your current skill level. Don&apos;t worry
      if some of the choices don&apos;t make sense, just pick whatever resonates
      most.
      <Gap />
      <br />
      <div style={optionsStyle}>
        {fail && (
          <>
            <Announcement>{fail}</Announcement>
            <Gap />
          </>
        )}
        {shuffledDifficulties.map((d, idx) => (
          <div key={getKey(idx)} style={optionStyle}>
            <Triangle
              base="0.75em"
              ratio={0.75}
              direction="right"
              style={triangleStyle}
            />
            <Button
              label={optionsDict[d]}
              loadingLabel={optionsDict[d]}
              loading={isSubmitting}
              onMouseUp={handleValue(d)}
              onTouchEnd={handleValue(d)}
              style={buttonStyle}
            />
          </div>
        ))}
        {errors.onboarding && (
          <Subtext color={COLORS.mandy} style={errorStyle}>
            {errors.onboarding.message ||
              'Please select a valid onboarding option'}
          </Subtext>
        )}
      </div>
    </div>
  );
}

export default Welcome;
