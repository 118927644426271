import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import Link from '~/components/Link';

interface VerticalListProps {
  labels: string[];
  links?: string[];
}

function VerticalList({ labels, links }: VerticalListProps): ReactElement {
  const getKey = (idx) => `BeatboxDbContentCardVerticalListItem${idx}`;

  // Vertical list styling
  const verticalListStyle = {
    ...(labels.length === 0 && { display: 'none' }),
    marginTop: '0.25em',
  };
  const itemStyle = {
    borderTop: '1px dashed',
    borderColor: COLORS.gallery,
  };
  const liStyle = {
    width: '100%',
    marginLeft: '0.25em',
    paddingLeft: '0.25em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap' as const,
  };
  const labelStyle = {
    display: 'inline-block',
    margin: '0.5em 0',
    color: COLORS.doveGray,
  };
  const lastLabelStyle = {
    ...labelStyle,
    margin: '0.5em 0 0',
  };

  return (
    <div style={verticalListStyle}>
      {labels.map((label, idx, { length }) => (
        <div key={getKey(idx)} style={itemStyle}>
          <li style={liStyle}>
            <span style={idx < length - 1 ? labelStyle : lastLabelStyle}>
              {!links && label}
              {links && <Link href={links[idx]}>{label}</Link>}
            </span>
          </li>
        </div>
      ))}
    </div>
  );
}

export default VerticalList;
