import React, { Dispatch, SetStateAction, ReactElement } from 'react';

import COLORS from '~/constants/colors';
import Flipper from '~/components/Flipper';

interface FlipProps {
  flip: number;
  setFlip: Dispatch<SetStateAction<number>>;
}

function Flip({ flip, setFlip }: FlipProps): ReactElement {
  const track50Style = {
    width: '85%',
    height: '1.5em',
    color: COLORS.black,
    backgroundColor: COLORS.jaffa,
    borderRadius: '0.3em',
    overflow: 'hidden',
    marginBottom: '0.17em',
    boxShadow: `-0.13em 0.13em ${COLORS.black25}`,
  };
  const track0Style = {
    ...track50Style,
    backgroundColor: COLORS.mandy,
  };
  const track100Style = {
    ...track50Style,
    backgroundColor: COLORS.kournikova,
  };

  // Flipper props
  const labels = ['あ ⟶ A', 'あ ⟷ A', 'あ ⟵ A'];
  const idx = flip * 2;
  const callback = () => setFlip((((flip + 0.5) / 0.5) % 3) * 0.5);
  const trackStyles = [track0Style, track50Style, track100Style];

  return (
    <Flipper
      labels={labels}
      idx={idx}
      callback={callback}
      caseStyles={trackStyles}
    />
  );
}

export default Flip;
