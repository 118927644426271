import { getCurrentPathGetter } from '~/utils';

import PATHS, { BEATBOXSHARE_PATH } from './constants/paths';
import { CONSTRAINTS, MIME_TYPES } from './constants/media';
import Encoder from './devices/Encoder';
import Transformer from './devices/Transformer';
import { Show } from './models';
import uris from './uris';

export const getResetReducer =
  (options?: Record<string, any>) =>
  (acc: Record<string, boolean>, k: string): Record<string, boolean> => ({
    ...acc,
    [k]: options?.[k] ?? false,
  });

export const getIsSupported =
  (supported: Record<string, any>) =>
  (constraint: string): boolean =>
    Object.prototype.hasOwnProperty.call(supported, constraint) &&
    supported[constraint];

export const filterConstraints = (
  constraints: Record<string, any>
): Record<string, any> =>
  Object.keys(constraints)
    .filter(getIsSupported(navigator.mediaDevices.getSupportedConstraints()))
    .reduce((acc, k) => ({ ...acc, [k]: constraints[k] }), {});

export const getAudioConstraints = (
  options?: Record<string, any>
): Record<string, boolean> =>
  Object.values(CONSTRAINTS)
    .filter(getIsSupported(navigator.mediaDevices.getSupportedConstraints()))
    .reduce(getResetReducer(options), {});

export const getRedirectUri = (show?: Show): string => {
  if (show === 'audio') return uris.record;
  else if (show === 'video') return uris.film;
  else if (show === 'file') return uris.upload;

  return BEATBOXSHARE_PATH;
};

export const getCurrentPath: () => string = getCurrentPathGetter(
  PATHS,
  BEATBOXSHARE_PATH,
  PATHS.record
);

export const getAudioBufferFromArrayBuffer = async (
  arrayBuffer: ArrayBuffer
): Promise<AudioBuffer> => {
  const context = new AudioContext();
  const audioBuffer = await context.decodeAudioData(arrayBuffer);

  if (context.state !== 'closed') await context.close();

  return audioBuffer;
};

export const cookOffline = async (
  buffer: AudioBuffer,
  config?: Record<string, any>
): Promise<Blob> => {
  const offlineContext = new OfflineAudioContext({
    numberOfChannels: buffer.numberOfChannels,
    length: buffer.length,
    sampleRate: buffer.sampleRate,
  });
  const finalConfig: Record<string, any> = {
    ...config,
    sampleRate: offlineContext.sampleRate,
  };

  // Routing
  const nodes = {
    source: offlineContext.createBufferSource(),
    transformer: new Transformer(offlineContext, finalConfig.preset),
  };

  nodes.source.buffer = buffer;
  nodes.source.connect(nodes.transformer.nodes.input);
  nodes.transformer.connect(offlineContext.destination);
  nodes.source.start();

  // Encode output
  const encoder = new Encoder(finalConfig);
  const renderedBuffer = await offlineContext.startRendering();

  encoder.encode(renderedBuffer.getChannelData(0));

  // Construct blob and clean up
  const cookedBuffer = encoder.finish();
  const cookedAudio = new Blob(cookedBuffer, { type: MIME_TYPES.mp3 });

  Object.values(nodes).forEach((node) => node.disconnect());
  encoder.clearBuffer();

  return cookedAudio;
};
