import React, {
  Dispatch,
  SetStateAction,
  ReactElement,
  useEffect,
  MutableRefObject,
} from 'react';
import { GameEngine } from 'react-game-engine';
import Matter from 'matter-js';
import type YouTubePlayer from 'youtube-player';
import { useAnimation } from 'framer-motion';

import COLORS from '~/constants/colors';

import {
  PlaySettings,
  StudioSettings,
  AnySettings,
  ReviewEntities,
  PlayEntities,
  RecordEntities,
  AnyResults,
  AnyEntities,
} from '../models';
import { getWindowDims, recursiveRenderer, isGamePhase } from '../utils';
import {
  DEFAULT_REVIEW_STATE,
  DEFAULT_PLAY_STATE,
  DEFAULT_RECORD_STATE,
  DEFAULT_PLAY_RESULTS,
  DEFAULT_RECORD_RESULTS,
} from '../constants/defaults';
import PHASES from '../constants/phases';
import playTempoSystem from '../systems/playTempoSystem';
import recordTempoSystem from '../systems/recordTempoSystem';
import reviewTempoSystem from '../systems/reviewTempoSystem';
import playHighwaySystem from '../systems/playHighwaySystem';
import recordHighwaySystem from '../systems/recordHighwaySystem';
import reviewHighwaySystem from '../systems/reviewHighwaySystem';
import playInputSystem from '../systems/playInputSystem';
import recordInputSystem from '../systems/recordInputSystem';
import reviewInputSystem from '../systems/reviewInputSystem';
import youTubeSystem from '../systems/youTubeSystem';
import { AnySystem } from '../systems/models';
import inputPadEntity from '../entities/inputPadEntity';
import recordPadEntity from '../entities/recordPadEntity';
import feedbackEntity from '../entities/feedbackEntity';
import stopwatchEntity from '../entities/stopwatchEntity';
import metricsEntity from '../entities/metricsEntity';
import { TYPES, EVENTS } from '../constants/events';
import { POINTS } from '../constants/mechanics';
import receptorEntity from '../entities/receptorEntity';
import { PANEL_DIMS, INPUT_MODES, RECEPTOR_DIMS } from '../constants/layout';
import exitEntity from '../entities/exitEntity';

interface GameForegroundProps {
  phase: string;
  setPhase: Dispatch<SetStateAction<string>>;
  setPrevPhase: Dispatch<SetStateAction<string>>;
  settings: AnySettings;
  videoPlayer: YouTubePlayer;
  setResults: Dispatch<SetStateAction<AnyResults>>;
  gameEngineRef: MutableRefObject<GameEngine>;
}

function GameForeground({
  phase,
  setPhase,
  setPrevPhase,
  settings,
  videoPlayer,
  setResults,
  gameEngineRef,
}: GameForegroundProps): ReactElement {
  const engine = Matter.Engine.create();
  const windowDims = getWindowDims();
  const running = isGamePhase(phase);
  const handleDispatch = (event) => {
    if (event.type === TYPES.stopGame) gameEngineRef.current?.stop();
    else if (event.type === TYPES.swapEntities)
      gameEngineRef.current?.swap(event.detail.entities);
  };

  // Possible system lists
  const baseSystems = [youTubeSystem];
  const reviewSystems = [
    ...baseSystems,
    reviewTempoSystem,
    reviewHighwaySystem,
    reviewInputSystem,
  ];
  const playSystems = [
    ...baseSystems,
    playTempoSystem,
    playHighwaySystem,
    playInputSystem,
  ];
  const recordSystems = [
    ...baseSystems,
    recordTempoSystem,
    recordHighwaySystem,
    recordInputSystem,
  ];

  // Calculations
  const receptorDims = RECEPTOR_DIMS(windowDims);
  const getReceptorY = (mode) => {
    const safeMode = !mode ? INPUT_MODES.key : mode;
    const panelDims = PANEL_DIMS(windowDims);
    const panelS = 2 * panelDims[safeMode].b + 2 * panelDims[safeMode].m;
    const spacedHeight = panelDims[safeMode].h + panelS;
    const multipliers = { key: 2, jog: 3, gym: 6 };
    const padHeight = multipliers[safeMode] * spacedHeight;

    return windowDims.h - padHeight - receptorDims.h / 2;
  };

  // Entity constants
  const receptorAnimates = {
    0: useAnimation(),
    1: useAnimation(),
    2: useAnimation(),
  };
  const correctFeedbackAnimate = useAnimation();
  const perfectFeedbackAnimate = useAnimation();
  const metricsPos = { x: 0, y: windowDims.h };
  const exitPos = { x: windowDims.w, y: 0 };

  // Play entities
  const baseInitEntities = () => ({
    physics: { engine, windowDims },
    bars: [],
    notes: [],
    setPhase,
    videoPlayer,
  });
  const initPlayEntities = (): PlayEntities => {
    const playSettings = settings as PlaySettings;
    const total = (playSettings.rhythm?.timings.length ?? 0) * POINTS.perfect;
    const receptorY = getReceptorY(playSettings.inputMode);
    const receptorB = receptorDims.b ?? 0;
    const receptorEdge = receptorDims.w / 2 + 2 * receptorB;
    const correctFeedbackPos = {
      x: Math.max(
        windowDims.w / 4 - receptorEdge / 2,
        windowDims.w / 2 - 1.07 * receptorDims.w
      ),
      y: receptorY - 2.5 * receptorDims.h,
    };
    const perfectFeedbackPos = {
      ...correctFeedbackPos,
      x: Math.min(
        (windowDims.w * 3) / 4 + receptorEdge / 2,
        windowDims.w / 2 + 1.07 * receptorDims.w
      ),
    };
    const exitCallback = () => setPhase(PHASES.endingExit);

    return {
      ...baseInitEntities(),
      receptor: receptorEntity(
        windowDims,
        receptorY,
        receptorAnimates,
        playSettings.inputMode
      ),
      bridges: [],
      metrics: metricsEntity(windowDims, metricsPos, total),
      exit: exitEntity(windowDims, exitPos, exitCallback),
      correctFeedback: feedbackEntity(
        windowDims,
        correctFeedbackPos,
        'CORRECT',
        correctFeedbackAnimate,
        COLORS.pastelGreen
      ),
      perfectFeedback: feedbackEntity(
        windowDims,
        perfectFeedbackPos,
        'PERFECT',
        perfectFeedbackAnimate
      ),
      inputPad: inputPadEntity(windowDims, playSettings, gameEngineRef, phase),
      settings: playSettings,
      state: DEFAULT_PLAY_STATE(),
    };
  };

  // Record entities
  const initRecordEntities = (): RecordEntities => {
    const studioSettings = settings as StudioSettings;
    const jogReceptorY = getReceptorY(INPUT_MODES.jog);
    const stopwatchPos = {
      x: windowDims.w / 2,
      y: jogReceptorY + receptorDims.h,
    };
    const exitCallback = () => setPhase(PHASES.production);

    return {
      ...baseInitEntities(),
      receptor: receptorEntity(windowDims, jogReceptorY, receptorAnimates),
      stopwatch: stopwatchEntity(
        windowDims,
        stopwatchPos,
        studioSettings.videoStartTime
      ),
      recordPad: recordPadEntity(windowDims, gameEngineRef),
      exit: exitEntity(windowDims, exitPos, exitCallback),
      settings: studioSettings,
      state: DEFAULT_RECORD_STATE(),
    };
  };

  // Review entities
  const initReviewEntities = (): ReviewEntities => {
    const studioSettings = settings as StudioSettings;
    const keyReceptorY = getReceptorY(INPUT_MODES.key);
    const stopwatchPos = {
      x: windowDims.w / 2,
      y: keyReceptorY + receptorDims.h,
    };
    const exitCallback = () => {
      setPrevPhase(PHASES.review);
      setPhase(PHASES.studio);
    };

    return {
      ...baseInitEntities(),
      receptor: receptorEntity(windowDims, keyReceptorY, receptorAnimates),
      stopwatch: stopwatchEntity(
        windowDims,
        stopwatchPos,
        studioSettings.videoStartTime
      ),
      exit: exitEntity(windowDims, exitPos, exitCallback),
      setPrevPhase,
      settings: studioSettings,
      state: DEFAULT_REVIEW_STATE(),
    };
  };

  // Set systems and entities based on phase
  let systems: AnySystem[] = [];
  let initEntities: () => AnyEntities = initPlayEntities;

  if (phase === PHASES.play) systems = playSystems; // Needed for concurrency issue
  if (phase === PHASES.record) {
    systems = recordSystems;
    initEntities = initRecordEntities;
  } else if (phase === PHASES.review) {
    systems = reviewSystems;
    initEntities = initReviewEntities;
  }

  // Game foreground styling
  const gameForegroundStyle = {
    position: 'absolute' as const,
    top: 0,
    left: '50%',
    marginLeft: -windowDims.w / 2,
    // Dims need to match fixed YouTube query dims
    width: windowDims.w,
    height: windowDims.h,
    overflow: 'hidden', // Needed to hide where notes and bridges load
  };

  useEffect(() => {
    // Clear engine of finished game stuff
    if (phase === PHASES.menu || phase === PHASES.studio) {
      Matter.Composite.clear(engine.world, false, true);
      Matter.Engine.clear(engine);
    }

    // Make sure game is stopped when not supposed to run
    if (!running) gameEngineRef.current?.dispatch(EVENTS.stopGame());

    // Keep entities loaded proactively
    if (phase.slice(0, 6) !== PHASES.ending && phase !== PHASES.production)
      gameEngineRef.current?.dispatch(EVENTS.swapEntities(initEntities()));

    // Centralize results passing
    if (phase === PHASES.play) setResults(DEFAULT_PLAY_RESULTS());
    else if (phase === PHASES.record) setResults(DEFAULT_RECORD_RESULTS());
    else if (phase.slice(0, 6) === PHASES.ending || phase === PHASES.production)
      setResults({
        ...gameEngineRef.current.state.entities.state.results,
      });
  }, [phase]);

  return (
    <div style={gameForegroundStyle}>
      <GameEngine
        renderer={recursiveRenderer}
        entities={initEntities()}
        systems={systems}
        onEvent={handleDispatch}
        running={running}
        ref={gameEngineRef}
      />
    </div>
  );
}

export default GameForeground;
