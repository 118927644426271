import React, {
  useReducer,
  useMemo,
  PropsWithChildren,
  ReactElement,
} from 'react';

import { createSafeContext } from '~/utils';

import { ThemeContext, ThemeState } from './models';
import { switchThemeThunk } from './actions';
import reducer, { INITIAL_STATE } from './reducer';

const [useThemeContext, ThemeContextProvider] =
  createSafeContext<ThemeContext>();

type ThemeProviderProps = Record<never, never>;

function ThemeProvider({
  children,
}: PropsWithChildren<ThemeProviderProps>): ReactElement {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  // Connect thunks
  const switchTheme = switchThemeThunk(dispatch);

  // Set up value
  const value = useMemo(() => {
    return {
      state: state as ThemeState,
      switchTheme,
    };
  }, [state]);

  return <ThemeContextProvider value={value}>{children}</ThemeContextProvider>;
}

export default ThemeProvider;
export { useThemeContext };
