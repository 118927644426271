import React, { ReactElement, CSSProperties, HTMLAttributes } from 'react';

import COLORS from '~/constants/colors';

interface MagnifyingGlassProps extends HTMLAttributes<HTMLDivElement> {
  width: string;
  fill?: string;
  style?: CSSProperties;
}

function MagnifyingGlass({
  width,
  fill = COLORS.black,
  style,
  ...props
}: MagnifyingGlassProps): ReactElement {
  // Magnifying glass styling
  const magnifyingGlassStyle = {
    display: 'inline-block',
    width,
    height: width,
    overflow: 'hidden',
    ...style,
  };
  const svgStyle = {
    display: 'block',
    width: '100%',
    height: '100%',
  };

  return (
    <div style={magnifyingGlassStyle} {...props}>
      <svg viewBox="83 -13 540 591" style={svgStyle}>
        <g>
          <circle
            cx="400"
            cy="230"
            r="190"
            stroke={fill}
            strokeWidth="50"
            fill="none"
          />
          <path
            d="m123.76 535.92c-3.3594 0-7.2812-1.1211-10.078-3.9219l-10.082-10.078c-5.6016-5.6016-5.6016-14.559 0-19.602l131.6-131.6c2.8008-2.8008 6.1602-3.9219 10.078-3.9219 3.9219 0 7.2812 1.1211 10.078 3.9219l10.078 10.078c5.6016 5.6016 5.6016 14.559 0 19.602l-132.16 131.6c-2.8008 2.8008-6.1602 3.9219-9.5195 3.9219z"
            fill={fill}
          />
          <path
            d="m244.72 380.8 10.078 10.078-131.04 131.04-10.078-10.078 131.04-131.04m0-28c-7.2812 0-14.559 2.8008-19.602 8.3984l-131.6 131.04c-5.0391 5.0391-8.3984 12.32-8.3984 19.602s2.8008 14.559 8.3984 19.602l10.082 10.637c5.6016 5.6016 12.879 8.3984 19.602 8.3984 7.2812 0 14.559-2.8008 19.602-8.3984l131.6-131.6c11.199-11.199 11.199-28.559 0-39.762l-10.078-10.078c-5.0469-5.0352-12.328-7.8359-19.609-7.8359z"
            fill={fill}
          />
        </g>
      </svg>
    </div>
  );
}

export default MagnifyingGlass;
