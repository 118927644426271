import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import { CHANNEL_COUNT } from '../constants/media';

interface ReactMediaRecorderRenderProps {
  error: string;
  muteAudio: () => void;
  unMuteAudio: () => void;
  startRecording: () => void;
  pauseRecording: () => void;
  resumeRecording: () => void;
  stopRecording: () => void;
  mediaBlobUrl: undefined | string;
  status: StatusMessages;
  isAudioMuted: boolean;
  previewStream: MediaStream | null;
  previewAudioStream: MediaStream | null;
  clearBlobUrl: () => void;
  clean: () => void;
}

interface ReactMediaRecorderHookProps {
  audio?: boolean | MediaTrackConstraints;
  video?: boolean | MediaTrackConstraints;
  screen?: boolean;
  onStop?: (blobUrl: string, blob: Blob) => void;
  onStart?: () => void;
  blobPropertyBag?: BlobPropertyBag;
  mediaRecorderOptions?: MediaRecorderOptions | undefined;
  customMediaStream?: MediaStream | null;
  stopStreamsOnStop?: boolean;
  askPermissionOnMount?: boolean;
  channelCount?: number;
}

type ReactMediaRecorderProps = ReactMediaRecorderHookProps & {
  render: (props: ReactMediaRecorderRenderProps) => ReactElement;
};
type StatusMessages =
  | 'media_aborted'
  | 'permission_denied'
  | 'no_specified_media_found'
  | 'media_in_use'
  | 'invalid_media_constraints'
  | 'no_constraints'
  | 'recorder_error'
  | 'idle'
  | 'acquiring_media'
  | 'delayed_start'
  | 'recording'
  | 'stopping'
  | 'stopped'
  | 'paused';

enum RecorderErrors {
  AbortError = 'media_aborted',
  NotAllowedError = 'permission_denied',
  NotFoundError = 'no_specified_media_found',
  NotReadableError = 'media_in_use',
  OverconstrainedError = 'invalid_media_constraints',
  TypeError = 'no_constraints',
  NONE = '',
  NO_RECORDER = 'recorder_error',
}

const useReactMediaRecorder = ({
  audio = true,
  video = false,
  onStop = () => null,
  onStart = () => null,
  blobPropertyBag,
  screen = false,
  mediaRecorderOptions = undefined,
  customMediaStream = null,
  stopStreamsOnStop = true,
  askPermissionOnMount = false,
  channelCount = CHANNEL_COUNT,
}: ReactMediaRecorderHookProps): ReactMediaRecorderRenderProps => {
  const [status, setStatus] = useState<StatusMessages>('idle');
  const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false);
  const [mediaBlobUrl, setMediaBlobUrl] = useState<string | undefined>(
    undefined
  );
  const [error, setError] = useState<keyof typeof RecorderErrors>('NONE');
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const mediaChunksRef = useRef<Blob[]>([]);
  const mediaStreamRef = useRef<MediaStream | null>(null);

  // Handlers
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state !== 'inactive') {
        setStatus('stopping');
        mediaRecorderRef.current.stop();
        if (stopStreamsOnStop) {
          if (mediaStreamRef.current)
            mediaStreamRef.current.getTracks().forEach((track) => track.stop());
        }
        mediaChunksRef.current = [];
      }
    }
  };
  const onRecordingActive = ({ data }: BlobEvent) =>
    mediaChunksRef.current.push(data);
  const onRecordingStart = () => onStart();
  const onRecordingStop = () => {
    const [chunk] = mediaChunksRef.current;
    const blobProperty: BlobPropertyBag = {
      type: chunk.type,
      ...(blobPropertyBag ||
        (video ? { type: 'video/mp4' } : { type: 'audio/wav' })),
    };
    const blob = new Blob(mediaChunksRef.current, blobProperty);
    const url = URL.createObjectURL(blob);

    setStatus('stopped');
    setMediaBlobUrl(url);
    onStop(url, blob);
  };
  const muteAudio = (mute: boolean) => {
    setIsAudioMuted(mute);
    if (mediaStreamRef.current)
      mediaStreamRef.current.getAudioTracks().forEach((audioTrack) => {
        audioTrack.enabled = !mute; // eslint-disable-line no-param-reassign
      });
  };
  const pauseRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === 'recording'
    ) {
      setStatus('paused');
      mediaRecorderRef.current.pause();
    }
  };
  const resumeRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === 'paused'
    ) {
      setStatus('recording');
      mediaRecorderRef.current.resume();
    }
  };
  const clearBlobUrl = () => {
    if (mediaBlobUrl) URL.revokeObjectURL(mediaBlobUrl);
    setMediaBlobUrl(undefined);
    setStatus('idle');
  };
  const clean = () => {
    clearBlobUrl();
    mediaRecorderRef.current?.stop();
    mediaStreamRef.current?.getTracks().forEach((track) => track.stop());
    mediaChunksRef.current = [];
  };

  // Stream
  const getMediaStream = useCallback(async () => {
    setStatus('acquiring_media');

    // Get media
    const requiredMedia: MediaStreamConstraints = {
      audio: typeof audio === 'boolean' ? !!audio : audio,
      video: typeof video === 'boolean' ? !!video : video,
    };

    try {
      if (customMediaStream) mediaStreamRef.current = customMediaStream;
      else if (screen) {
        const stream = (await window.navigator.mediaDevices.getDisplayMedia({
          video: video || true,
        })) as MediaStream;

        stream.getVideoTracks()[0].addEventListener('ended', () => {
          stopRecording();
        });
        if (audio) {
          const audioStream = await window.navigator.mediaDevices.getUserMedia({
            audio,
          });

          audioStream
            .getAudioTracks()
            .slice(0, channelCount)
            .forEach((audioTrack) => {
              stream.addTrack(audioTrack);
            });
        }
        mediaStreamRef.current = stream;
      } else {
        const stream = await window.navigator.mediaDevices.getUserMedia(
          requiredMedia
        );

        mediaStreamRef.current = stream;
      }
      setStatus('idle');
    } catch (e: any) {
      setError(e.name);
      setStatus('idle');
    }
  }, [audio, video, screen]);

  // Start
  const startRecording = async () => {
    setError('NONE');
    if (!mediaStreamRef.current) await getMediaStream();
    if (mediaStreamRef.current) {
      const isStreamEnded = mediaStreamRef.current
        .getTracks()
        .some((track) => track.readyState === 'ended');
      if (isStreamEnded) {
        await getMediaStream();
      }

      // User blocked permission and `getMediaStream` errored out
      if (!mediaStreamRef.current.active) return;
      mediaRecorderRef.current = new MediaRecorder(
        mediaStreamRef.current,
        mediaRecorderOptions || undefined
      );
      mediaRecorderRef.current.ondataavailable = onRecordingActive;
      mediaRecorderRef.current.onstop = onRecordingStop;
      mediaRecorderRef.current.onstart = onRecordingStart;
      mediaRecorderRef.current.onerror = () => {
        setError('NO_RECORDER');
        setStatus('idle');
      };
      mediaRecorderRef.current.start();
      setStatus('recording');
    }
  };

  useEffect(() => {
    if (!window.MediaRecorder) throw new Error('Unsupported browser');
    if (screen) {
      if (!window.navigator.mediaDevices.getDisplayMedia)
        throw new Error("This browser doesn't support screen capturing");
    }

    // Check constraints
    const checkConstraints = (mediaType: MediaTrackConstraints) => {
      const supportedMediaConstraints =
        navigator.mediaDevices.getSupportedConstraints();
      const unsupportedConstraints = Object.keys(mediaType).filter(
        (constraint) =>
          !(supportedMediaConstraints as { [key: string]: any })[constraint]
      );

      if (unsupportedConstraints.length > 0) {
        const unsupportedString = unsupportedConstraints.join(', ');
        const constraintsErrorMessage = `The constraints ${unsupportedString} aren't supported on this browser. Please check your \`ReactMediaRecorder\` component.`;

        throw new Error(constraintsErrorMessage);
      }
    };

    if (typeof audio === 'object') checkConstraints(audio);
    if (typeof video === 'object') checkConstraints(video);
    if (mediaRecorderOptions && mediaRecorderOptions.mimeType) {
      if (!MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
        const typeErrorMessage =
          "The supplied MIME type for `MediaRecorder` isn't supported on this browser";

        throw new Error(typeErrorMessage);
      }
    }
    if (!mediaStreamRef.current && askPermissionOnMount) getMediaStream();

    return () => {
      if (mediaStreamRef.current) {
        const tracks = mediaStreamRef.current.getTracks();

        tracks.forEach((track) => track.clone().stop());
      }
    };
  }, [
    audio,
    screen,
    video,
    getMediaStream,
    mediaRecorderOptions,
    askPermissionOnMount,
  ]);

  return {
    error: RecorderErrors[error],
    muteAudio: () => muteAudio(true),
    unMuteAudio: () => muteAudio(false),
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    mediaBlobUrl,
    status,
    isAudioMuted,
    previewStream: mediaStreamRef.current
      ? new MediaStream(mediaStreamRef.current.getVideoTracks())
      : null,
    previewAudioStream: mediaStreamRef.current
      ? new MediaStream(mediaStreamRef.current.getAudioTracks())
      : null,
    clearBlobUrl,
    clean,
  };
};

function ReactMediaRecorder(props: ReactMediaRecorderProps): ReactElement {
  return props.render(useReactMediaRecorder(props));
}

export default ReactMediaRecorder;
export { useReactMediaRecorder, RecorderErrors };
export type {
  ReactMediaRecorderRenderProps,
  ReactMediaRecorderHookProps,
  ReactMediaRecorderProps,
  StatusMessages,
};
