import React, { ReactElement } from 'react';
import { motion } from 'framer-motion';

import COLORS from '~/constants/colors';

import { ReceptorPredicate } from '../predicates/models';
import { RendererProps } from './models';
import { INPUT_MODES } from '../constants/layout';

function Receptor({
  predicate,
}: RendererProps<ReceptorPredicate>): ReactElement {
  const pos = predicate.getPos();
  const jogging = predicate.inputMode === INPUT_MODES.jog;

  // Receptor styling
  const getPx = (v) => `${v}px`;
  const noteB = predicate.noteDims.b ?? 0;
  const receptorStyle = {
    position: 'absolute' as const,
    width: getPx(predicate.dims.w),
    height: getPx(predicate.dims.h),
    left: getPx(pos.x - predicate.dims.w / 2),
    top: getPx(pos.y - predicate.dims.h / 2),
    textAlign: 'center' as const,
    ...predicate.style,
  };
  const getRGBA = (c) => {
    const matches = c.match(/\w\w/g) ?? [];
    const [r, g, b, a] = matches.map((v) => parseInt(v, 16));

    return `rgba(${r}, ${g}, ${b}, ${a ? a / 255 : 1})`;
  };
  const getFrame = (c, w = predicate.landingB, s = predicate.noteDims.w / 4) =>
    `0 0 0 ${getPx(w)} ${getRGBA(c)}, 0 0 ${getPx(s)} 0 ${getRGBA(
      COLORS.black50
    )}`;
  const resetFrame = getFrame(COLORS.white);
  const panelB = predicate.panelDims.b ?? 0;
  const panelM = predicate.panelDims.m ?? 0;
  const sideMargins =
    predicate.panelDims.w / 2 + panelB + panelM - predicate.noteDims.w / 2;
  const landingStyle = {
    display: 'inline-block',
    width: getPx(predicate.noteDims.w),
    height: getPx(predicate.noteDims.h),
    margin: `${getPx(predicate.landingB)} ${getPx(sideMargins)} 0`,
    borderRadius: getPx(3 * noteB),
    backgroundColor: COLORS.black50,
    boxShadow: resetFrame,
  };

  // Animation
  const variants = {
    reset: {
      x: 0,
      boxShadow: resetFrame,
    },
    wrong: {
      x: [0, -2.5, 2.5, 0],
      boxShadow: [getFrame(COLORS.mandy), resetFrame],
      transition: { duration: 0.3 },
    },
    combo: {
      boxShadow: [
        getFrame(COLORS.pastelGreen, 1.69 * predicate.landingB),
        resetFrame,
      ],
      transition: { duration: 0.3 },
    },
    special: {
      boxShadow: [
        getFrame(COLORS.mandy),
        getFrame(COLORS.jaffa, 1.23 * predicate.landingB),
        getFrame(COLORS.kournikova, 1.46 * predicate.landingB),
        getFrame(COLORS.wildWillow, 1.69 * predicate.landingB),
        getFrame(COLORS.malibu, 1.46 * predicate.landingB),
        getFrame(COLORS.lilacBush, 1.26 * predicate.landingB),
        getFrame(COLORS.mandy),
        resetFrame,
      ],
      transition: { duration: 0.6, ease: 'easeIn' },
    },
  };

  return (
    <div style={receptorStyle}>
      {!jogging && (
        <motion.div
          initial={false}
          animate={predicate.animates[0]}
          variants={variants}
          style={landingStyle}
        />
      )}
      <motion.div
        initial={false}
        animate={predicate.animates[1]}
        variants={variants}
        style={landingStyle}
      />
      {!jogging && (
        <motion.div
          initial={false}
          animate={predicate.animates[2]}
          variants={variants}
          style={landingStyle}
        />
      )}
    </div>
  );
}

export default Receptor;
