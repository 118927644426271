import axios from 'axios';

import beatboxUrls from '~/urls/beatbox';
import socialUrls from '~/urls/social';
import { addParams, flattenJournalData } from '~/utils';
import { Profile, JournalResponse } from '~/models';
import { DEFAULT_PAGE_SIZE } from '~/constants/defaults';

import { Subject, Beatboxer } from './models';

const config = { withCredentials: true };
const beatboxDbSubjectName = 'beatbox';
const pack = (data, subjectId?) => ({
  data: {
    ...data,
    ...(subjectId && { subject: subjectId }),
  },
});

// Profiles
export const readProfiles = async (): Promise<Profile[]> => {
  const pluralUrl = addParams(socialUrls.profiles, { sort: 'handle:asc' });
  const response = await axios.get(pluralUrl, config);

  return flattenJournalData(response.data.data);
};

export const readBeatboxer = async (handle: string): Promise<Beatboxer> => {
  const response = await axios.get(beatboxUrls.beatboxer(handle), config);

  return response.data.data.attributes;
};

// Subjects
export const readSubject = async (): Promise<Subject> => {
  const subjectsUrl = addParams(beatboxUrls.subjects, {
    'filters[name][$eq]': beatboxDbSubjectName,
  });
  const response = await axios.get(subjectsUrl, config);

  return flattenJournalData(response.data.data[0]);
};

// Generic
export const getCreateContent =
  <T, U>(pluralType: string, params?: Record<string, any>) =>
  async (info: T, subjectId: number): Promise<U> => {
    const pluralUrl = addParams(beatboxUrls[pluralType], params);
    const response = await axios.post(pluralUrl, pack(info, subjectId), config);

    return flattenJournalData(response.data.data);
  };

export const getReadContent =
  <T>(singularType: string, params?: Record<string, any>) =>
  async (id: number): Promise<T> => {
    const singularUrl = addParams(beatboxUrls[singularType](id), params);
    const response = await axios.get(singularUrl, config);

    return flattenJournalData(response.data.data);
  };

export const getReadContents =
  <T>(pluralType: string) =>
  async (
    subjectId: number,
    page?: number,
    params?: Record<string, any>
  ): Promise<T[] | JournalResponse<T>> => {
    const pluralUrl = addParams(beatboxUrls[pluralType], {
      'filters[subject][id][$eq]': subjectId,
      sort: 'id:desc',
      ...(page && {
        'pagination[page]': page,
        'pagination[pageSize]': DEFAULT_PAGE_SIZE,
        'pagination[withCount]': true,
      }),
      ...params,
    });
    const response = await axios.get(pluralUrl, config);
    const data = flattenJournalData(response.data.data);

    return page ? { ...response.data, data } : data;
  };

export const getUpdateContent =
  <T, U>(singularType: string, params?: Record<string, any>) =>
  async (id: number, info: T): Promise<U> => {
    const singularUrl = addParams(beatboxUrls[singularType](id), params);
    const response = await axios.put(singularUrl, pack(info), config);

    return flattenJournalData(response.data.data);
  };

export const getDeleteContent =
  (singularType: string) =>
  async (id: number): Promise<void> => {
    await axios.delete(beatboxUrls[singularType](id), config);
  };
