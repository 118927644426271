import axios from 'axios';

import beatboxUrls from '~/urls/beatbox';
import { flattenJournalData, isObject } from '~/utils';
import { getStorageItem, setStorageItem, removeStorageItem } from '~/services';

import { Clip } from './models';
import { MIME_TYPES } from './constants/media';
import { PRESET_CONFIGS } from './constants/mastering';
import { STORAGE_KEYS } from './constants/storage';
import {
  Preset,
  ACTIVE_COMP_ATTACK,
  ACTIVE_COMP_RELEASE,
} from './devices/Transformer';

export const createClip = async (recording: Blob): Promise<Clip> => {
  // Construct request with file
  const clipInfo = new FormData();

  clipInfo.append('recording', recording);

  // Get response from Journal
  const response = await axios.post(beatboxUrls.clips, clipInfo);

  return flattenJournalData(response.data.data);
};

export const readClip = async (code: string): Promise<Clip> => {
  const response = await axios.get(beatboxUrls.clip(code));
  const clip: Clip = flattenJournalData(response.data.data);

  clip.recording.url = beatboxUrls.clipRecording(clip.recording.url);

  return clip;
};

export const deleteClip = async (code: string): Promise<Clip> => {
  const response = await axios.delete(beatboxUrls.clip(code));
  const clip: Clip = flattenJournalData(response.data.data);

  clip.recording.url = beatboxUrls.clipRecording(clip.recording.url);

  return clip;
};

export const muxVideo = async (video: Blob, audio: Blob): Promise<Blob> => {
  const videoInfo = new FormData();

  videoInfo.append('video', video);
  videoInfo.append('audio', audio);

  // Get response from Journal
  const response = await axios.post(beatboxUrls.mux, videoInfo, {
    responseType: 'arraybuffer',
  });

  return new Blob([response.data], { type: MIME_TYPES.mp4 });
};

export const readRecording = async (
  url: string,
  type: string
): Promise<Blob> => {
  const response = await axios.get(url, { responseType: 'arraybuffer' });

  return new Blob([response.data], { type });
};

// Custom preset
export const clearCustomPreset = (): void =>
  removeStorageItem(STORAGE_KEYS.customPreset);

export const getCustomPreset = (): Preset => {
  const item = getStorageItem(STORAGE_KEYS.customPreset);
  const isPreset =
    isObject(item) &&
    Object.keys(PRESET_CONFIGS.raw).every(
      (k) =>
        Object.prototype.hasOwnProperty.call(item, k) &&
        typeof item[k] === typeof PRESET_CONFIGS.raw[k] // Rough but should be fine
    );

  if (isPreset) {
    if (item.distortionCurve)
      item.distortionCurve = new Float32Array(
        Object.values(item.distortionCurve)
      );
    if (
      item.compThresh === PRESET_CONFIGS.raw.compThresh &&
      item.compKnee === PRESET_CONFIGS.raw.compKnee &&
      item.compRatio === PRESET_CONFIGS.raw.compRatio
    ) {
      item.compAttack = PRESET_CONFIGS.raw.compAttack;
      item.compRelease = PRESET_CONFIGS.raw.compRelease;
    } else {
      item.compAttack = ACTIVE_COMP_ATTACK;
      item.compRelease = ACTIVE_COMP_RELEASE;
    }

    return item;
  }
  clearCustomPreset();

  return PRESET_CONFIGS.raw;
};

export const setCustomPreset = (preset: Preset): void =>
  setStorageItem(STORAGE_KEYS.customPreset, preset);

export const setCustomPresetOption = (
  option: string,
  value: number | Float32Array | null
): void => setCustomPreset({ ...getCustomPreset(), [option]: value });
