import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
  CSSProperties,
} from 'react';

import { useThemeContext, CONDITIONS } from '~/providers/ThemeProvider';
import { stopEventEffects } from '~/utils';

interface TextButtonProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  color?: string;
  condition?: string;
  callback?: (event: Event) => void | Promise<void>;
  loading?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
}

function TextButton({
  color,
  condition = CONDITIONS.neutral,
  callback,
  loading = false,
  disabled = false,
  style,
  children,
  ...props
}: PropsWithChildren<TextButtonProps>): ReactElement {
  const { theme } = useThemeContext().state;
  const textButtonStyle = {
    color: color || theme[condition].hover?.color,
    cursor: 'pointer',
    ...style,
  };

  // Event handlers
  const handleMouseUp = async (event) => {
    stopEventEffects(event);
    if (!loading && !disabled) await callback?.(event);
  };
  const handleTouchEnd = async (event) => {
    stopEventEffects(event);
    if (!loading && !disabled) await callback?.(event);
  };

  return (
    <b
      role="none"
      onMouseUp={handleMouseUp}
      onTouchEnd={handleTouchEnd}
      style={textButtonStyle}
      {...props}
    >
      {children}
    </b>
  );
}

export default TextButton;
